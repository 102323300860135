import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';

import {PageBannersService} from '../page-banners.service';
import {PageBanner, PageBannerContentProps} from '../page-banners.types';

@Component({
  selector: 'w-page-banners',
  templateUrl: './page-banners.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBannersComponent {
  banners$: Observable<Array<PageBanner<PageBannerContentProps>>>;

  constructor(private bannersService: PageBannersService) {
    this.banners$ = this.bannersService.banners$;
  }

  onDismiss(banner: PageBanner<PageBannerContentProps>) {
    banner.onDismiss ? banner.onDismiss(banner) : this.bannersService.remove(banner.id);
  }
}
