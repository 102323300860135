import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'w-team-switch-splash-screen',
  templateUrl: './team-switch-splash-screen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./team-switch-splash-screen.component.scss'],
})
export class TeamSwitchSplashScreenComponent {
  @Input({required: true}) teamName: string;
}
