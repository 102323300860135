<ng-container *ngIf="showTooltip; else withoutTooltipTmpl">
  <time
    class="date-time-label date-time-label_has-tooltip"
    [attr.datetime]="date | wDate: attrFormat : timezone"
    [wTooltip]="date | wDate: tooltipFormat : timezone"
    [wTooltipPlacement]="tooltipPlacement"
    ><ng-container *ngTemplateOutlet="formattedDateTmpl"
  /></time>
</ng-container>

<ng-template #withoutTooltipTmpl>
  <time class="date-time-label" [attr.datetime]="date | wDate: attrFormat : timezone"
    ><ng-container *ngTemplateOutlet="formattedDateTmpl"
  /></time>
</ng-template>

<ng-template #formattedDateTmpl>
  <ng-container *ngIf="isFromNow; else withDateTmpl">
    {{ fromNowDate }}
  </ng-container>
  <ng-template #withDateTmpl>{{ date | wDate: format : timezone }}</ng-template>
</ng-template>
