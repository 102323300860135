import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

export type SwitchControlSizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

@Component({
  selector: 'w-switch-control',
  templateUrl: './switch-control.component.html',
  styleUrls: ['./switch-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class SwitchControlComponent {
  @Input({required: true}) active: boolean;
  @Input() size: SwitchControlSizeType = 'medium';
  @Input() disabled?: boolean;
  @Input() name?: string;
  @Input() label?: string;
  @Input() showOnOff?: boolean;

  @Output() onSwitch = new EventEmitter<boolean>();

  @HostBinding('class.switch-control') hostClass = true;

  toggle(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.active = !this.active;
    this.onSwitch.emit(this.active);
  }
}
