import {Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';

import {WSimpleChanges} from '../types/angular';

@Directive({
  selector: '[wHover]',
  standalone: true,
})
export class HoverDirective implements OnInit, OnDestroy, OnChanges {
  @Input('wHoverDisabled') disabled = false;
  @Input('wHoverOutsideNg') outsideNgZone?: boolean;
  @Input('wHoverEnterDelay') enterDelay = 0;

  @Output('wHover') onHover = new EventEmitter<boolean>();

  private hovered = false;
  private delayTimerId?: number;

  constructor(
    private elemRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    if (!this.disabled) {
      this.init();
    }
  }

  ngOnChanges(changes: WSimpleChanges<HoverDirective>) {
    if (
      (changes.disabled && !changes.disabled.firstChange) ||
      (changes.outsideNgZone && !changes.outsideNgZone.firstChange)
    ) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.unbindEvents();
    this.handleHover(false);
  }

  private init() {
    this.unbindEvents();

    if (this.outsideNgZone) {
      this.ngZone.runOutsideAngular(() => this.bindEvents());
    } else {
      this.bindEvents();
    }
  }

  private bindEvents() {
    const elem = this.elemRef.nativeElement;

    elem.addEventListener('mouseenter', this.handleMouseEnter);
    elem.addEventListener('mouseleave', this.handleMouseLeave);
  }

  private unbindEvents() {
    const elem = this.elemRef.nativeElement;

    elem.removeEventListener('mouseenter', this.handleMouseEnter);
    elem.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  private handleHover(flag: boolean) {
    if (this.hovered === flag) {
      return;
    }

    this.hovered = flag;
    clearTimeout(this.delayTimerId);
    this.delayTimerId = setTimeout(
      () => {
        this.onHover.emit(flag);
      },
      flag ? this.enterDelay : 0,
    );
  }

  private handleMouseEnter = () => this.handleHover(true);
  private handleMouseLeave = () => this.handleHover(false);
}
