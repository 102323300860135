import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {AvailableTeam} from '../../../services/auth-user';

@Component({
  selector: 'w-teams-group',
  templateUrl: './teams-group.component.html',
  styleUrls: ['./teams-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamsGroupComponent {
  @Input({required: true}) teams: AvailableTeam[];
  @Input() label: string | null = null;
  @Input() searchQuery = '';

  @Output() onTeamSelect = new EventEmitter<AvailableTeam>();

  @HostBinding('class.teams-group') hostClass = true;
}
