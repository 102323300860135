<ng-container *ngIf="href; else routerLinkTmpl">
  <a
    class="header-link header-link_{{ type }}"
    [class.header-link_active]="isActive"
    [href]="href"
    [attr.target]="isExternal ? '_blank' : '_self'"
    rel="noopener"
    wMixpanel="Header navigation: {{ mixpanel }}"
    [wMixpanelOptions]="mixpanelOptions"
  >
    <ng-container *ngTemplateOutlet="contentTmpl" />
  </a>
</ng-container>

<ng-template #routerLinkTmpl>
  <a
    class="header-link header-link_{{ type }}"
    [class.header-link_active]="isActive"
    routerLinkActive
    [routerLink]="routerUrl!.path"
    [queryParams]="routerUrl!.query"
    [fragment]="routerUrl!.fragment"
    wMixpanel="Header navigation: {{ mixpanel }}"
    [wMixpanelOptions]="mixpanelOptions"
  >
    <ng-container *ngTemplateOutlet="contentTmpl" />
  </a>
</ng-template>

<ng-template #contentTmpl>
  <ng-content />
</ng-template>
