<button
  class="sidebar-user"
  [class.sidebar-user_active]="isTeamSwitcherOpened"
  [disabled]="!hasTeamSwitch"
  (click)="handleClick()"
>
  <span
    [ngClass]="avatarClasses"
    wTooltipPlacement="bottom"
    [wTooltip]="workspaceNameTooltip"
    [wTooltipDisabled]="!isTeamSwitcherOpened"
    [wTooltipArrow]="false"
  >
    <w-user-avatar [imgUrl]="workspaceAvatar" [userName]="workspaceName" [size]="36" />
    <div *ngIf="env" class="sidebar-user__avatar-env sidebar-user__avatar-env_{{ env.theme }}">
      {{ env.shortTypeName | uppercase }}
    </div>
  </span>

  <span class="sidebar-user__text" [class.sidebar-user__text_visible]="isSidebarOpened && !isTeamSwitcherOpened">
    <w-trimmed-text class="sidebar-user__name" [text]="workspaceName" />
    <span *ngIf="isTeamSession || hasTeamSwitch" class="sidebar-user__team">
      <w-trimmed-text *ngIf="isTeamSession; else meTmpl" [text]="fullLoggedUserName" />
      <ng-template #meTmpl> <ng-container *ngIf="hasTeamSwitch"> My workspace</ng-container></ng-template>
    </span>
  </span>
  <w-svg-icon
    *ngIf="hasTeamSwitch && !isTeamSwitcherOpened"
    class="sidebar-user__icon"
    [class.sidebar-user__icon_visible]="isSidebarOpened"
    [iconId]="iconId"
    [size]="20"
  />
</button>

<ng-container *ngIf="isTeamSwitcherOpened">
  <w-team-switcher (hideSidebar)="hideSidebar()" (close)="closeTeamSwitcher()" />
</ng-container>
