import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

import {scrollElementIntoView} from '@shared/utils/dom/scroll-element-into-view';

@Directive({
  selector: '[wScrollIntoView]',
})
export class ScrollIntoViewDirective implements AfterViewInit {
  @Input('wScrollIntoView') container?: HTMLElement;
  @Input('wScrollIntoViewMargin') margin = 0;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.scrollIntoView();
  }

  private scrollIntoView(): void {
    if (this.elementRef.nativeElement.scrollIntoView) {
      this.elementRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      scrollElementIntoView(this.elementRef.nativeElement, this.container, {
        animate: true,
        margin: this.margin,
      });
    }
  }
}
