import _ from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';

import {SortOrder} from '../types';

@Pipe({name: 'wSort'})
export class SortPipe implements PipeTransform {
  transform<T>(items: T[] = [], key?: keyof T | null, order: SortOrder = 'asc', keySelector?: (item: T) => any): T[] {
    const iteratee = keySelector || key;

    if (!iteratee) {
      return items;
    }

    return _.orderBy(items, iteratee, order);
  }
}
