import {Injectable, OnDestroy} from '@angular/core';
import {EMPTY, Observable, Subject, defer, merge, timer} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil} from 'rxjs/operators';
import _ from 'lodash';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import {HttpResource, getRequestAsObservable} from '@shared/services/http-resource';
import {AlertService} from '@shared/services/alert';

import {RouterHelpers} from '../../../../services/router-helpers.service';
import {AuthUser} from '../../../../services/auth-user';

import {KMSType, KMSUpdateStatus, Kms, KmsResponse, KmsUpdatePayload} from './key-management.types';
import {isKmsSwitchFailed, isKmsSwitchedSuccessfully} from './key-management.helpers';

const POLLING_TIMEOUT = 2000;
const KEY_MANAGEMENT_URL = '/members/settings/key_management';

@Injectable({
  providedIn: 'root',
})
export class KeyManagementDataService implements OnDestroy {
  initialData$: Observable<Kms> = defer(() => this.userKmsSettings.get<KmsResponse>()).pipe(
    map(kmsResponse => kmsResponse.kms),
    shareReplay(1),
  );

  pollingData$: Observable<Kms>;
  data$: Observable<Kms>;

  private userKmsSettings: HttpResource;
  private validateUserKmsSettings: HttpResource;

  private destroy$ = new Subject<void>();
  private init$ = new Subject<void>();
  private startPollingSubject = new Subject<void>();
  private stopPollingSubject = new Subject<void>();
  private startGlobalKmsSwitchResultListener = new Subject<Kms['cmk_type']>();

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private routerHelpers: RouterHelpers,
    private authUser: AuthUser,
  ) {
    this.userKmsSettings = new HttpResource(this.http, {
      url: '/web_api/user_kms_settings.json',
    });
    this.validateUserKmsSettings = new HttpResource(this.http, {
      url: '/web_api/user_kms_settings/awskms/validate.json',
    });

    this.pollingData$ = this.startPollingSubject.pipe(
      switchMap(() =>
        timer(0, POLLING_TIMEOUT).pipe(
          switchMap(() => getRequestAsObservable(this.userKmsSettings.get<KmsResponse>())),
          map(response => response.kms),
          catchError(() => EMPTY),
          takeUntil(this.stopPollingSubject),
        ),
      ),
      shareReplay({refCount: true, bufferSize: 1}),
    );

    this.data$ = merge(this.initialData$, this.pollingData$).pipe(distinctUntilChanged(_.isEqual), shareReplay(1));

    this.init$
      .pipe(
        take(1),
        filter(() => this.authUser.hasPrivilege('cmk_management.read')),
        switchMap(() => this.initialData$),
        catchError(() => EMPTY),
      )
      .subscribe(initialData => {
        if (initialData.status === KMSUpdateStatus.ROTATING) {
          this.addGlobalKmsSwitchResultListener(initialData.cmk_type);
          this.startPolling();
        }
      });

    this.startGlobalKmsSwitchResultListener
      .pipe(
        switchMap(expectedKmsType => this.pollingData$.pipe(map(data => [expectedKmsType, data] as const))),
        takeUntil(this.destroy$),
      )
      .subscribe(([expectedKmsType, data]) => {
        if (isKmsSwitchedSuccessfully(data, expectedKmsType)) {
          this.stopPolling();
          this.showEncryptionFinishedToast('success');
        } else if (isKmsSwitchFailed(data)) {
          this.stopPolling();
          this.showEncryptionFinishedToast('error');
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initialize() {
    this.init$.next();
    this.init$.complete();
  }

  startPolling() {
    this.startPollingSubject.next();
  }

  stopPolling() {
    this.stopPollingSubject.next();
  }

  addGlobalKmsSwitchResultListener(expectedKmsType: Kms['cmk_type']) {
    this.startGlobalKmsSwitchResultListener.next(expectedKmsType);
  }

  async validateAwsKmsKey(keyId: Kms['key_id']): Promise<boolean> {
    const result = await this.validateUserKmsSettings.put(
      {},
      {
        key_id: keyId,
      },
    );

    return result.status;
  }

  async updateAwsKmsKey(newKmsType: Kms['cmk_type'], keyId: Kms['key_id']): Promise<KmsResponse> {
    return this.userKmsSettings.put({}, this.getUpdateAwsKmsKeyPayload(newKmsType, keyId));
  }

  private getUpdateAwsKmsKeyPayload(newKmsType: Kms['cmk_type'], keyId: Kms['key_id']): KmsUpdatePayload {
    if (newKmsType === KMSType.AWS_KMS) {
      return {
        cmk_type: KMSType.AWS_KMS,
        key_id: keyId,
      };
    }

    if (newKmsType === KMSType.CUSTOM_KMS) {
      return {
        cmk_type: KMSType.CUSTOM_KMS,
        key_material: keyId,
      };
    }

    return {
      cmk_type: newKmsType,
    };
  }

  private showEncryptionFinishedToast(state: 'success' | 'error') {
    const isSettingsKeyManagementPageActive = this.routerHelpers.currentUrl === KEY_MANAGEMENT_URL;
    const toastOptions = isSettingsKeyManagementPageActive
      ? undefined
      : {
          action: () => {
            this.router.navigate([KEY_MANAGEMENT_URL], {replaceUrl: true});
          },
          actionTitle: 'View settings',
        };

    if (state === 'success') {
      this.alertService.success('Encryption key updated', '', toastOptions);
    } else {
      this.alertService.error('Unable to update encryption key', '', toastOptions);
    }
  }
}
