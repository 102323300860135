<div class="env-switcher">
  <button
    *ngFor="let env of environments"
    class="env-switcher__item"
    type="button"
    [disabled]="isCurrent(env)"
    (click)="handleClick(env)"
    wKeyboardNavigationItem
  >
    <div class="env-switcher__item-icon">
      <div class="env-switcher__item-icon-background env-switcher__item-icon-background_{{ env.theme }}">
        <w-svg-icon [iconId]="icons[env.theme]" [size]="13" />
      </div>
    </div>
    <div class="env-switcher__item-text">
      {{ env.name }}
      <span *ngIf="isCurrent(env)" class="env-switcher__current-env">(current)</span>
    </div>
  </button>
</div>
