import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'wPaging'})
export class PagingPipe implements PipeTransform {
  transform<T>(items: T[] = [], itemsPerPage = 10, currentPage = 1): T[] {
    const end = currentPage * itemsPerPage;
    const start = end - itemsPerPage;

    return items.slice(start, end);
  }
}
