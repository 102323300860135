import {Directive, Input, OnChanges} from '@angular/core';
import {Subject} from 'rxjs';

import {WSimpleChanges} from '../../types/angular';

import {KeyboardNavigationItemConfig} from './keyboard-navigation.types';

@Directive({
  selector: '[wKeyboardNavigationItemConfig]',
  standalone: true,
})
export class KeyboardNavigationItemConfigDirective implements OnChanges {
  @Input({alias: 'wKeyboardNavigationItemConfig', required: true}) config: KeyboardNavigationItemConfig;

  configChanged$ = new Subject<void>();

  ngOnChanges(changes: WSimpleChanges<KeyboardNavigationItemConfigDirective>) {
    if (changes.config && !changes.config.firstChange) {
      this.configChanged$.next();
    }
  }
}
