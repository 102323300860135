import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

import {SvgIconComponent} from '../svg-icon/svg-icon.component';

@Component({
  selector: 'w-sort-icon',
  templateUrl: './sort-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sort-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
})
export class SortIconComponent {
  @Input({required: true}) order: 'asc' | 'desc' | 1 | -1 | 0 | false | null | undefined;
  @Input() visible = true;

  get isDescDirection(): boolean {
    return this.order === 'desc' || this.order === -1;
  }
}
