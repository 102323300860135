import {Component} from '@angular/core';
import {Observable} from 'rxjs';

import {ComponentClass} from '@shared/types/angular';
import {PageSpinnerService} from '@shared/services/page-spinner.service';

import {AuthUser} from '../../services/auth-user';
import {RouterHelpers} from '../../services/router-helpers.service';
import {OemThemeCustomizationService} from '../oem-theme-customization/oem-theme-customization.service';
import {OemService} from '../../modules/oem/oem.service';
import {Environment} from '../../services/environment';

@Component({
  selector: 'w-app-layout',
  templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent {
  loaded$: Observable<boolean>;

  readonly oemCustomizationComponent$: Observable<ComponentClass | null>;

  constructor(
    private authUser: AuthUser,
    private pageSpinner: PageSpinnerService,
    private routerHelpers: RouterHelpers<{app_id: string}>,
    private oemService: OemService,
    private environment: Environment,
    private oemThemeCustomizationService: OemThemeCustomizationService,
  ) {
    this.loaded$ = this.pageSpinner.isHidden$;
    this.oemCustomizationComponent$ = this.oemThemeCustomizationService.customizationComponent$;
  }

  get integrationAppId(): string | undefined {
    return this.routerHelpers.query.app_id;
  }

  get integrationAppUrl(): string {
    return `/installed_integration_apps/${this.integrationAppId}`;
  }

  get isAuthenticated(): boolean {
    return this.authUser.authenticated;
  }

  get isFullyEmbedded(): boolean {
    return this.oemService.fullyEmbedded;
  }

  get hasFooter(): boolean {
    return !this.authUser.authenticated;
  }

  get hasCopyright(): boolean {
    return this.environment.isWidgetMode && this.oemService.footerBrandingVisible;
  }
}
