/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

var _ = require("lodash");

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleIndices = { Start: 0 },
      peg$startRuleIndex   = 0,

      peg$consts = [
        function(expression) { return expression; },
        peg$anyExpectation(),
        function(name) { return name; },
        peg$otherExpectation("identifier"),
        /^[a-z]/i,
        peg$classExpectation([["a", "z"]], false, true),
        "$",
        peg$literalExpectation("$", false),
        "_",
        peg$literalExpectation("_", false),
        /^[0-9]/,
        peg$classExpectation([["0", "9"]], false, false),
        "[",
        peg$literalExpectation("[", false),
        "]",
        peg$literalExpectation("]", false),
        function() { return "[]" },
        function(elements) { return "[" + elements + "]" },
        ",",
        peg$literalExpectation(",", false),
        function(head, tail) { return buildExpression(head, tail) },
        "{",
        peg$literalExpectation("{", false),
        "}",
        peg$literalExpectation("}", false),
        function() { return "{}" },
        function(properties) { return "{" + properties + "}" },
        ":",
        peg$literalExpectation(":", false),
        function(key, value) { return key + ': ' + value },
        peg$otherExpectation("number"),
        function(literal) {
              return literal;
            },
        ".",
        peg$literalExpectation(".", false),
        "0",
        peg$literalExpectation("0", false),
        /^[1-9]/,
        peg$classExpectation([["1", "9"]], false, false),
        peg$otherExpectation("string"),
        "\"",
        peg$literalExpectation("\"", false),
        "'",
        peg$literalExpectation("'", false),
        "\\",
        peg$literalExpectation("\\", false),
        function() { return text(); },
        function(sequence) { return sequence; },
        "b",
        peg$literalExpectation("b", false),
        function() { return "\b"; },
        "f",
        peg$literalExpectation("f", false),
        function() { return "\f"; },
        "n",
        peg$literalExpectation("n", false),
        function() { return "\n"; },
        "r",
        peg$literalExpectation("r", false),
        function() { return "\r"; },
        "t",
        peg$literalExpectation("t", false),
        function() { return "\t"; },
        "v",
        peg$literalExpectation("v", false),
        function() { return "\v"; },
        "break",
        peg$literalExpectation("break", false),
        "case",
        peg$literalExpectation("case", false),
        "catch",
        peg$literalExpectation("catch", false),
        "class",
        peg$literalExpectation("class", false),
        "const",
        peg$literalExpectation("const", false),
        "continue",
        peg$literalExpectation("continue", false),
        "debugger",
        peg$literalExpectation("debugger", false),
        "default",
        peg$literalExpectation("default", false),
        "delete",
        peg$literalExpectation("delete", false),
        "do",
        peg$literalExpectation("do", false),
        "else",
        peg$literalExpectation("else", false),
        "enum",
        peg$literalExpectation("enum", false),
        "export",
        peg$literalExpectation("export", false),
        "extends",
        peg$literalExpectation("extends", false),
        "false",
        peg$literalExpectation("false", false),
        "finally",
        peg$literalExpectation("finally", false),
        "for",
        peg$literalExpectation("for", false),
        "function",
        peg$literalExpectation("function", false),
        "if",
        peg$literalExpectation("if", false),
        "import",
        peg$literalExpectation("import", false),
        "instanceof",
        peg$literalExpectation("instanceof", false),
        "in",
        peg$literalExpectation("in", false),
        "new",
        peg$literalExpectation("new", false),
        "null",
        peg$literalExpectation("null", false),
        "return",
        peg$literalExpectation("return", false),
        "super",
        peg$literalExpectation("super", false),
        "switch",
        peg$literalExpectation("switch", false),
        "this",
        peg$literalExpectation("this", false),
        "throw",
        peg$literalExpectation("throw", false),
        "true",
        peg$literalExpectation("true", false),
        "try",
        peg$literalExpectation("try", false),
        "typeof",
        peg$literalExpectation("typeof", false),
        "var",
        peg$literalExpectation("var", false),
        "void",
        peg$literalExpectation("void", false),
        "while",
        peg$literalExpectation("while", false),
        "with",
        peg$literalExpectation("with", false),
        peg$otherExpectation("end of line"),
        "\n",
        peg$literalExpectation("\n", false),
        "\r\n",
        peg$literalExpectation("\r\n", false),
        "\r",
        peg$literalExpectation("\r", false),
        /^[\u2028\u2029]/,
        peg$classExpectation(["\u2028", "\u2029"], false, false),
        peg$otherExpectation("whitespace"),
        " ",
        peg$literalExpectation(" ", false),
        /^[\t\x0B\f \xA0\u1680\u2000-\u200A\u202F\u205F\u3000\xA0\uFEFF]/,
        peg$classExpectation(["\t", "\x0B", "\f", " ", "\xA0", "\u1680", ["\u2000", "\u200A"], "\u202F", "\u205F", "\u3000", "\xA0", "\uFEFF"], false, false),
        ";",
        peg$literalExpectation(";", false),
        function(expr) { return {expr: expr} },
        function(expr) { return {expr: expr, fromData: true} },
        "(",
        peg$literalExpectation("(", false),
        ")",
        peg$literalExpectation(")", false),
        function(expr) { return {expr: "(" + expr + ")"} },
        function(head, id) { return {id: id}; },
        function(head, expr) { return {expr: expr}; },
        function(head, tail) {
              if (head.expr === "undefined") {
                return buildMemberExpression(head.expr, tail);
              }

              if (!head.fromData && !tail.length) {
                return head.expr;
              }

              if (head.fromData && allowedDataProps && !_.includes(allowedDataProps, head.expr)) {
                return error(
                  '"' + head.expr + '" property at position ' + location().start.column + ' is not allowed. ' +
                  'You can only use one of the following: ' + allowedDataProps.join(', ')
                );
              }

              return buildSafeMemberExpression(head.expr, tail, head.fromData ? null : head.expr);
            },
        function(operator, ws, argument) {
              return operator + ws + argument;
            },
        "+",
        peg$literalExpectation("+", false),
        "=",
        peg$literalExpectation("=", false),
        "-",
        peg$literalExpectation("-", false),
        "!",
        peg$literalExpectation("!", false),
        function(head, tail) { return buildExpression(head, tail); },
        "*",
        peg$literalExpectation("*", false),
        "/",
        peg$literalExpectation("/", false),
        "%",
        peg$literalExpectation("%", false),
        /^[+=]/,
        peg$classExpectation(["+", "="], false, false),
        /^[\-=]/,
        peg$classExpectation(["-", "="], false, false),
        "<=",
        peg$literalExpectation("<=", false),
        ">=",
        peg$literalExpectation(">=", false),
        "<",
        peg$literalExpectation("<", false),
        ">",
        peg$literalExpectation(">", false),
        "===",
        peg$literalExpectation("===", false),
        "!==",
        peg$literalExpectation("!==", false),
        "==",
        peg$literalExpectation("==", false),
        "!=",
        peg$literalExpectation("!=", false),
        "&&",
        peg$literalExpectation("&&", false),
        "||",
        peg$literalExpectation("||", false),
        "?",
        peg$literalExpectation("?", false),
        function(test, consequent, alternate) {
              return test + " ? " + consequent + " : " + alternate;
            },
        function(callee, args) {
             if (!helpers || !helpers.hasOwnProperty(callee)) {
               let message = 'Couldn\'t find "' + callee + '" helper function.'

               if (helpers) {
                 message += ' Available helpers: ' + _.keys(helpers).join(', ') + '.';
               }

               return error(message);
             }

             return 'helpers.' + callee + args;
           },
        function(args) {
             return "(" + (args ? args[0] : "") + ")";
           },
        function(head, tail) {
             return buildExpression(head, tail);
           }
      ],

      peg$bytecode = [
        peg$decode("%;d/:#;x/1$;d/($8#: #!!)(#'#(\"'#&'#"),
        peg$decode("1\"\"5!7!"),
        peg$decode("%%<;&=.##&&!&'#/1#;#/($8\":\"\"! )(\"'#&'#"),
        peg$decode("<%%;$/3#$;%0#*;%&/#$+\")(\"'#&'#/\"!&,)=.\" 7#"),
        peg$decode("4$\"\"5!7%.5 &2&\"\"6&7'.) &2(\"\"6(7)"),
        peg$decode(";$.) &4*\"\"5!7+"),
        peg$decode(";'./ &;(.) &;0.# &;1"),
        peg$decode(";>.\xB3 &;?.\xAD &;@.\xA7 &;C.\xA1 &;D.\x9B &;E.\x95 &;F.\x8F &;G.\x89 &;H.\x83 &;M.} &;N.w &;O.q &;P.k &;R.e &;S._ &;T.Y &;V.S &;X.M &;Y.G &;Z.A &;\\.; &;].5 &;^./ &;_.) &;`.# &;a"),
        peg$decode(";A.A &;B.; &;I.5 &;J./ &;K.) &;Q.# &;W"),
        peg$decode(";0./ &;1.) &;2.# &;7"),
        peg$decode("%2,\"\"6,7-/?#;d/6$2.\"\"6.7//'$8#:0# )(#'#(\"'#&'#.b &%2,\"\"6,7-/R#;d/I$;+/@$;d/7$2.\"\"6.7//($8%:1%!\")(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%;t/\x8F#$%;d/D#22\"\"6273/5$;d/,$;t/#$+$)($'#(#'#(\"'#&'#0N*%;d/D#22\"\"6273/5$;d/,$;t/#$+$)($'#(#'#(\"'#&'#&/)$8\":4\"\"! )(\"'#&'#"),
        peg$decode("%25\"\"6576/?#;d/6$27\"\"6778/'$8#:9# )(#'#(\"'#&'#.b &%25\"\"6576/R#;d/I$;-/@$;d/7$27\"\"6778/($8%::%!\")(%'#($'#(#'#(\"'#&'#"),
        peg$decode("%;./\x8F#$%;d/D#22\"\"6273/5$;d/,$;./#$+$)($'#(#'#(\"'#&'#0N*%;d/D#22\"\"6273/5$;d/,$;./#$+$)($'#(#'#(\"'#&'#&/)$8\":4\"\"! )(\"'#&'#"),
        peg$decode("%;//S#;d/J$2;\"\"6;7</;$;d/2$;t/)$8%:=%\"$ )(%'#($'#(#'#(\"'#&'#"),
        peg$decode(";#.) &;7.# &;2"),
        peg$decode("%;U/\"!&,)"),
        peg$decode("%;[/\"!&,).* &%;L/\"!&,)"),
        peg$decode("<%;3/C#%<;$.# &;5=.##&&!&'#/($8\":?\"!!)(\"'#&'#=.\" 7>"),
        peg$decode("%%;4/B#2@\"\"6@7A/3$$;50#*;5&/#$+#)(#'#(\"'#&'#/\"!&,).V &%%2@\"\"6@7A/9#$;5/&#0#*;5&&&#/#$+\")(\"'#&'#/\"!&,).# &;4"),
        peg$decode("2B\"\"6B7C.D &%%;6/3#$;50#*;5&/#$+\")(\"'#&'#/\"!&,)"),
        peg$decode("4*\"\"5!7+"),
        peg$decode("4D\"\"5!7E"),
        peg$decode("<%%2G\"\"6G7H/B#$;80#*;8&/2$2G\"\"6G7H/#$+#)(#'#(\"'#&'#/\"!&,).Y &%%2I\"\"6I7J/B#$;90#*;9&/2$2I\"\"6I7J/#$+#)(#'#(\"'#&'#/\"!&,)=.\" 7F"),
        peg$decode("%%<2G\"\"6G7H.) &2K\"\"6K7L=.##&&!&'#/5#1\"\"5!7!/'$8\":M\" )(\"'#&'#.A &%2K\"\"6K7L/1#;:/($8\":N\"! )(\"'#&'#"),
        peg$decode("%%<2I\"\"6I7J.) &2K\"\"6K7L=.##&&!&'#/5#1\"\"5!7!/'$8\":M\" )(\"'#&'#.A &%2K\"\"6K7L/1#;:/($8\":N\"! )(\"'#&'#"),
        peg$decode(";;.# &;<"),
        peg$decode("2I\"\"6I7J.\xBF &2G\"\"6G7H.\xB3 &2K\"\"6K7L.\xA7 &%2O\"\"6O7P/& 8!:Q! ).\x90 &%2R\"\"6R7S/& 8!:T! ).y &%2U\"\"6U7V/& 8!:W! ).b &%2X\"\"6X7Y/& 8!:Z! ).K &%2[\"\"6[7\\/& 8!:]! ).4 &%2^\"\"6^7_/& 8!:`! )"),
        peg$decode("%%<;==.##&&!&'#/5#1\"\"5!7!/'$8\":M\" )(\"'#&'#"),
        peg$decode(";;.# &;5"),
        peg$decode("%2a\"\"6a7b/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2c\"\"6c7d/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2e\"\"6e7f/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2g\"\"6g7h/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2i\"\"6i7j/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2k\"\"6k7l/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2m\"\"6m7n/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2o\"\"6o7p/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2q\"\"6q7r/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2s\"\"6s7t/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2u\"\"6u7v/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2w\"\"6w7x/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2y\"\"6y7z/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2{\"\"6{7|/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2}\"\"6}7~/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x7F\"\"6\x7F7\x80/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x81\"\"6\x817\x82/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x83\"\"6\x837\x84/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x85\"\"6\x857\x86/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x87\"\"6\x877\x88/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x89\"\"6\x897\x8A/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x8B\"\"6\x8B7\x8C/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x8D\"\"6\x8D7\x8E/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x8F\"\"6\x8F7\x90/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x91\"\"6\x917\x92/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x93\"\"6\x937\x94/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x95\"\"6\x957\x96/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x97\"\"6\x977\x98/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x99\"\"6\x997\x9A/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x9B\"\"6\x9B7\x9C/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x9D\"\"6\x9D7\x9E/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\x9F\"\"6\x9F7\xA0/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\xA1\"\"6\xA17\xA2/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\xA3\"\"6\xA37\xA4/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\xA5\"\"6\xA57\xA6/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("%2\xA7\"\"6\xA77\xA8/8#%<;%=.##&&!&'#/#$+\")(\"'#&'#"),
        peg$decode("<2\xAA\"\"6\xAA7\xAB.A &2\xAC\"\"6\xAC7\xAD.5 &2\xAE\"\"6\xAE7\xAF.) &4\xB0\"\"5!7\xB1=.\" 7\xA9"),
        peg$decode("<2\xB3\"\"6\xB37\xB4.) &4\xB5\"\"5!7\xB6=.\" 7\xB2"),
        peg$decode("%$;c.# &;b0)*;c.# &;b&/\"!&,)"),
        peg$decode("%;d/2#2\xB7\"\"6\xB77\xB8/#$+\")(\"'#&'#.6 &%;d/,#;f/#$+\")(\"'#&'#"),
        peg$decode("%<1\"\"5!7!=.##&&!&'#"),
        peg$decode("%%;u/' 8!:\xB9!! ).\xAA &%;\"/' 8!:\xBA!! ).\x98 &%;)/' 8!:\xB9!! ).\x86 &%;*/' 8!:\xB9!! ).t &%;,/' 8!:\xB9!! ).b &%2\xBB\"\"6\xBB7\xBC/R#;d/I$;t/@$;d/7$2\xBD\"\"6\xBD7\xBE/($8%:\xBF%!\")(%'#($'#(#'#(\"'#&'#/\u0139#$%;d/J#2@\"\"6@7A/;$;d/2$;#/)$8$:\xC0$\"& )($'#(#'#(\"'#&'#.l &%;d/b#2,\"\"6,7-/S$;d/J$;t/A$;d/8$2.\"\"6.7//)$8&:\xC1&\"(\")(&'#(%'#($'#(#'#(\"'#&'#0\xA3*%;d/J#2@\"\"6@7A/;$;d/2$;#/)$8$:\xC0$\"& )($'#(#'#(\"'#&'#.l &%;d/b#2,\"\"6,7-/S$;d/J$;t/A$;d/8$2.\"\"6.7//)$8&:\xC1&\"(\")(&'#(%'#($'#(#'#(\"'#&'#&/)$8\":\xC2\"\"! )(\"'#&'#"),
        peg$decode(";g.F &%;i/<#;d/3$;h/*$8#:\xC3##\"! )(#'#(\"'#&'#"),
        peg$decode("%;]/\"!&,).\x99 &%%2\xC4\"\"6\xC47\xC5/>#%<2\xC6\"\"6\xC67\xC7=.##&&!&'#/#$+\")(\"'#&'#/\"!&,).a &%%2\xC8\"\"6\xC87\xC9/>#%<2\xC6\"\"6\xC67\xC7=.##&&!&'#/#$+\")(\"'#&'#/\"!&,).) &2\xCA\"\"6\xCA7\xCB"),
        peg$decode("%;h/\x83#$%;d/>#;k/5$;d/,$;h/#$+$)($'#(#'#(\"'#&'#0H*%;d/>#;k/5$;d/,$;h/#$+$)($'#(#'#(\"'#&'#&/)$8\":\xCC\"\"! )(\"'#&'#"),
        peg$decode("%%2\xCD\"\"6\xCD7\xCE/>#%<2\xC6\"\"6\xC67\xC7=.##&&!&'#/#$+\")(\"'#&'#/\"!&,).\x8D &%%2\xCF\"\"6\xCF7\xD0/>#%<2\xC6\"\"6\xC67\xC7=.##&&!&'#/#$+\")(\"'#&'#/\"!&,).U &%%2\xD1\"\"6\xD17\xD2/>#%<2\xC6\"\"6\xC67\xC7=.##&&!&'#/#$+\")(\"'#&'#/\"!&,)"),
        peg$decode("%;j/\x83#$%;d/>#;m/5$;d/,$;j/#$+$)($'#(#'#(\"'#&'#0H*%;d/>#;m/5$;d/,$;j/#$+$)($'#(#'#(\"'#&'#&/)$8\":\xCC\"\"! )(\"'#&'#"),
        peg$decode("%%2\xC4\"\"6\xC47\xC5/>#%<4\xD3\"\"5!7\xD4=.##&&!&'#/#$+\")(\"'#&'#/\"!&,).U &%%2\xC8\"\"6\xC87\xC9/>#%<4\xD5\"\"5!7\xD6=.##&&!&'#/#$+\")(\"'#&'#/\"!&,)"),
        peg$decode("%;l/\x83#$%;d/>#;o/5$;d/,$;l/#$+$)($'#(#'#(\"'#&'#0H*%;d/>#;o/5$;d/,$;l/#$+$)($'#(#'#(\"'#&'#&/)$8\":\xCC\"\"! )(\"'#&'#"),
        peg$decode("2\xD7\"\"6\xD77\xD8.\xA6 &2\xD9\"\"6\xD97\xDA.\x9A &%%2\xDB\"\"6\xDB7\xDC/>#%<2\xDB\"\"6\xDB7\xDC=.##&&!&'#/#$+\")(\"'#&'#/\"!&,).b &%%2\xDD\"\"6\xDD7\xDE/>#%<2\xDD\"\"6\xDD7\xDE=.##&&!&'#/#$+\")(\"'#&'#/\"!&,).* &%;S/\"!&,)"),
        peg$decode("%;n/\x83#$%;d/>#;q/5$;d/,$;n/#$+$)($'#(#'#(\"'#&'#0H*%;d/>#;q/5$;d/,$;n/#$+$)($'#(#'#(\"'#&'#&/)$8\":\xCC\"\"! )(\"'#&'#"),
        peg$decode("2\xDF\"\"6\xDF7\xE0.A &2\xE1\"\"6\xE17\xE2.5 &2\xE3\"\"6\xE37\xE4.) &2\xE5\"\"6\xE57\xE6"),
        peg$decode("%;p/\x8F#$%;d/D#2\xE7\"\"6\xE77\xE8/5$;d/,$;p/#$+$)($'#(#'#(\"'#&'#0N*%;d/D#2\xE7\"\"6\xE77\xE8/5$;d/,$;p/#$+$)($'#(#'#(\"'#&'#&/)$8\":\xCC\"\"! )(\"'#&'#"),
        peg$decode("%;r/\x8F#$%;d/D#2\xE9\"\"6\xE97\xEA/5$;d/,$;r/#$+$)($'#(#'#(\"'#&'#0N*%;d/D#2\xE9\"\"6\xE97\xEA/5$;d/,$;r/#$+$)($'#(#'#(\"'#&'#&/)$8\":\xCC\"\"! )(\"'#&'#"),
        peg$decode("%;s/~#;d/u$2\xEB\"\"6\xEB7\xEC/f$;d/]$;t/T$;d/K$2;\"\"6;7</<$;d/3$;t/*$8):\xED)#($ )()'#(('#(''#(&'#(%'#($'#(#'#(\"'#&'#.# &;s"),
        peg$decode("%;\"/;#;d/2$;v/)$8#:\xEE#\"\" )(#'#(\"'#&'#"),
        peg$decode("%2\xBB\"\"6\xBB7\xBC/a#;d/X$%;w/,#;d/#$+\")(\"'#&'#.\" &\"/7$2\xBD\"\"6\xBD7\xBE/($8$:\xEF$!!)($'#(#'#(\"'#&'#"),
        peg$decode("%;t/\x8F#$%;d/D#22\"\"6273/5$;d/,$;t/#$+$)($'#(#'#(\"'#&'#0N*%;d/D#22\"\"6273/5$;d/,$;t/#$+$)($'#(#'#(\"'#&'#&/)$8\":\xF0\"\"! )(\"'#&'#"),
        peg$decode("%;t/1#;e/($8\": \"!!)(\"'#&'#")
      ],

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleIndices)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleIndex = peg$startRuleIndices[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$decode(s) {
    var bc = new Array(s.length), i;

    for (i = 0; i < s.length; i++) {
      bc[i] = s.charCodeAt(i) - 32;
    }

    return bc;
  }

  function peg$parseRule(index) {
    var bc    = peg$bytecode[index],
        ip    = 0,
        ips   = [],
        end   = bc.length,
        ends  = [],
        stack = [],
        params, i;

    while (true) {
      while (ip < end) {
        switch (bc[ip]) {
          case 0:
            stack.push(peg$consts[bc[ip + 1]]);
            ip += 2;
            break;

          case 1:
            stack.push(void 0);
            ip++;
            break;

          case 2:
            stack.push(null);
            ip++;
            break;

          case 3:
            stack.push(peg$FAILED);
            ip++;
            break;

          case 4:
            stack.push([]);
            ip++;
            break;

          case 5:
            stack.push(peg$currPos);
            ip++;
            break;

          case 6:
            stack.pop();
            ip++;
            break;

          case 7:
            peg$currPos = stack.pop();
            ip++;
            break;

          case 8:
            stack.length -= bc[ip + 1];
            ip += 2;
            break;

          case 9:
            stack.splice(-2, 1);
            ip++;
            break;

          case 10:
            stack[stack.length - 2].push(stack.pop());
            ip++;
            break;

          case 11:
            stack.push(stack.splice(stack.length - bc[ip + 1], bc[ip + 1]));
            ip += 2;
            break;

          case 12:
            stack.push(input.substring(stack.pop(), peg$currPos));
            ip++;
            break;

          case 13:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (stack[stack.length - 1]) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 14:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (stack[stack.length - 1] === peg$FAILED) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 15:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (stack[stack.length - 1] !== peg$FAILED) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 16:
            if (stack[stack.length - 1] !== peg$FAILED) {
              ends.push(end);
              ips.push(ip);

              end = ip + 2 + bc[ip + 1];
              ip += 2;
            } else {
              ip += 2 + bc[ip + 1];
            }

            break;

          case 17:
            ends.push(end);
            ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

            if (input.length > peg$currPos) {
              end = ip + 3 + bc[ip + 1];
              ip += 3;
            } else {
              end = ip + 3 + bc[ip + 1] + bc[ip + 2];
              ip += 3 + bc[ip + 1];
            }

            break;

          case 18:
            ends.push(end);
            ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

            if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length) === peg$consts[bc[ip + 1]]) {
              end = ip + 4 + bc[ip + 2];
              ip += 4;
            } else {
              end = ip + 4 + bc[ip + 2] + bc[ip + 3];
              ip += 4 + bc[ip + 2];
            }

            break;

          case 19:
            ends.push(end);
            ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

            if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length).toLowerCase() === peg$consts[bc[ip + 1]]) {
              end = ip + 4 + bc[ip + 2];
              ip += 4;
            } else {
              end = ip + 4 + bc[ip + 2] + bc[ip + 3];
              ip += 4 + bc[ip + 2];
            }

            break;

          case 20:
            ends.push(end);
            ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

            if (peg$consts[bc[ip + 1]].test(input.charAt(peg$currPos))) {
              end = ip + 4 + bc[ip + 2];
              ip += 4;
            } else {
              end = ip + 4 + bc[ip + 2] + bc[ip + 3];
              ip += 4 + bc[ip + 2];
            }

            break;

          case 21:
            stack.push(input.substr(peg$currPos, bc[ip + 1]));
            peg$currPos += bc[ip + 1];
            ip += 2;
            break;

          case 22:
            stack.push(peg$consts[bc[ip + 1]]);
            peg$currPos += peg$consts[bc[ip + 1]].length;
            ip += 2;
            break;

          case 23:
            stack.push(peg$FAILED);
            if (peg$silentFails === 0) {
              peg$fail(peg$consts[bc[ip + 1]]);
            }
            ip += 2;
            break;

          case 24:
            peg$savedPos = stack[stack.length - 1 - bc[ip + 1]];
            ip += 2;
            break;

          case 25:
            peg$savedPos = peg$currPos;
            ip++;
            break;

          case 26:
            params = bc.slice(ip + 4, ip + 4 + bc[ip + 3]);
            for (i = 0; i < bc[ip + 3]; i++) {
              params[i] = stack[stack.length - 1 - params[i]];
            }

            stack.splice(
              stack.length - bc[ip + 2],
              bc[ip + 2],
              peg$consts[bc[ip + 1]].apply(null, params)
            );

            ip += 4 + bc[ip + 3];
            break;

          case 27:
            stack.push(peg$parseRule(bc[ip + 1]));
            ip += 2;
            break;

          case 28:
            peg$silentFails++;
            ip++;
            break;

          case 29:
            peg$silentFails--;
            ip++;
            break;

          default:
            throw new Error("Invalid opcode: " + bc[ip] + ".");
        }
      }

      if (ends.length > 0) {
        end = ends.pop();
        ip = ips.pop();
      } else {
        break;
      }
    }

    return stack[0];
  }


    var helpers = options.helpers;
    var allowedDataProps = options.allowedDataProps;

    function buildExpression(head, tail) {
      return head + tail
        .map(function (part) {
          return part.join('');
        })
        .join('');
    }

    function buildMemberExpression(head, tail) {
      return tail.reduce(function(expr, part) {
        return part.id ? (expr + '.' + part.id) : (expr + '[' + part.expr + ']');
      }, head);
    }

    function buildSafeMemberExpression(head, tail, sourceExpr) {
      tail = tail.map(function(part) {
        return part.id ? '"' + part.id + '"' : part.expr;
      });

      var path = [].concat(tail);

      if (!sourceExpr) {
        sourceExpr = "data";
        path.unshift('"' + head + '"');
      }

      var expr = "_.get(" + sourceExpr + ", ";

      if (path.length === 1) {
        expr += path[0];
      } else {
        expr += "[" + path.join(', ') + "]";
      }

      return expr + ")";
    }


  peg$result = peg$parseRule(peg$startRuleIndex);

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};
