<span
  [@state]="visibility"
  class="tooltip tooltip_{{ placementClassName }}"
  [class.tooltip_no-arrow]="!arrow"
  [class.tooltip_draggable]="draggable"
  [style.width.px]="width || null"
>
  <ng-container *ngIf="isTemplateContent()" [ngTemplateOutlet]="content" />

  <span *ngIf="isHtmlContent()" [outerHTML]="content | wSanitizeHtml: sanitizeConfig"></span>
</span>
