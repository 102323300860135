import {HorizontalPosition, Position, PositionAsText, PositionObject, Rect, VerticalPosition} from '../types';

import {parseTextPosition} from './parse-text-position';

const VERTICAL_COORD_GETTERS: Record<VerticalPosition, (rect: Rect) => number> = {
  top: rect => rect.top,
  center: rect => rect.top + rect.height / 2,
  bottom: rect => rect.top + rect.height,
};

const HORIZONTAL_COORD_GETTERS: Record<HorizontalPosition, (rect: Rect) => number> = {
  left: rect => rect.left,
  center: rect => rect.left + rect.width / 2,
  right: rect => rect.left + rect.width,
};

export function getPositionCoordsFromRect(rect: Rect, position: PositionAsText | PositionObject): Position {
  if (typeof position === 'string') {
    position = parseTextPosition(position);
  }

  return {
    top: VERTICAL_COORD_GETTERS[position.vertical](rect),
    left: HORIZONTAL_COORD_GETTERS[position.horizontal](rect),
  };
}
