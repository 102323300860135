import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

import {Hash} from '../types';
import {SCREEN_SIZES, SCREEN_SIZE_NAMES, ScreenSizeName} from '../constants/screen-sizes';

import {AbstractScreenSizeService, ScreenSize} from './abstract-screen-size.service';

export type LayoutName = ScreenSizeName;

const LAYOUT_NAMES = SCREEN_SIZE_NAMES;

export class Layout implements ScreenSize {
  maxWidth: number;

  constructor(
    public name: LayoutName,
    config: Omit<ScreenSize, 'name'>,
  ) {
    Object.assign(this, config);
  }
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService extends AbstractScreenSizeService {
  current: Layout;
  phone = new Layout('phone', {maxWidth: SCREEN_SIZES.phone});
  phablet = new Layout('phablet', {maxWidth: SCREEN_SIZES.phablet});
  tablet = new Layout('tablet', {maxWidth: SCREEN_SIZES.tablet});
  compact = new Layout('compact', {maxWidth: SCREEN_SIZES.compact});
  desktopSmall = new Layout('desktopSmall', {maxWidth: SCREEN_SIZES.desktopSmall});
  desktopHD = new Layout('desktopHD', {maxWidth: SCREEN_SIZES.desktopHD});
  desktopFullHD = new Layout('desktopFullHD', {maxWidth: SCREEN_SIZES.desktopFullHD});
  desktopMax = new Layout('desktopMax', {maxWidth: Infinity});
  change: Observable<Layout>;

  /**
   * Current page width less then BREAKPOINT.COMPACT
   */
  isCompactView$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    super();

    const mediaQueries: string[] = [];
    const mediaQueryToLayout: Hash<Layout> = {};

    for (const name of LAYOUT_NAMES) {
      const layout = this[name];

      if (isFinite(layout.maxWidth)) {
        const mediaQuery = `(max-width: ${layout.maxWidth}px)`;

        mediaQueries.push(mediaQuery);
        mediaQueryToLayout[mediaQuery] = layout;
      }
    }

    this.change = this.breakpointObserver.observe(mediaQueries).pipe(
      map(state => {
        for (const query of mediaQueries) {
          if (state.breakpoints[query]) {
            return mediaQueryToLayout[query];
          }
        }

        return this.desktopMax;
      }),
    );

    this.change.subscribe(layout => (this.current = layout));

    this.isCompactView$ = this.change.pipe(
      map(() => this.isCompactView),
      distinctUntilChanged(),
    );
  }

  /**
   * Current page width less then BREAKPOINT.COMPACT
   */
  get isCompactView(): boolean {
    return this.current.maxWidth <= this.compact.maxWidth;
  }

  /**
   * Current page width large then BREAKPOINT.COMPACT
   */
  get isDesktopView(): boolean {
    return !this.isCompactView;
  }

  /**
   * One-column layout in app.
   * If you want to change, please update media-query in w-page-content.scss also.
   */
  get isCompactLayout(): boolean {
    return this.current.maxWidth <= this.desktopSmall.maxWidth;
  }
}
