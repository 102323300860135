import _ from 'lodash';
import {Injectable} from '@angular/core';

import {AbstractEnvironmentService} from '@shared/services/abstract-environment-service';

import {RouterHelpers} from './router-helpers.service';

const cache = _.memoize(fn => fn());

@Injectable({
  providedIn: 'root',
})
export class Environment extends AbstractEnvironmentService {
  supportsTouch = false;
  supportsScopedCss = false;
  supportsPointer = Boolean(matchMedia('(pointer:fine)').matches);

  inIframe = false;
  scrollBarSize = 0;

  constructor(private routerHelper: RouterHelpers<{widget?: 'true'}>) {
    super();
  }

  get supportsForeignObject(): boolean {
    return cache(supportsForeignObject);
  }

  get isWidgetMode(): boolean {
    return this.inIframe || this.routerHelper.query.widget === 'true';
  }

  override initialize() {
    super.initialize();

    this.checkTouchSupport();
    this.checkIframeEmbedding();
    this.calculateScrollBarSize();
    this.checkScopedCssSupport();

    if (this.supportsScopedCss) {
      document.documentElement.classList.add('supports-scoped-css');
    }
  }

  private checkTouchSupport() {
    if ('ontouchstart' in document.documentElement) {
      this.supportsTouch = true;
    }
  }

  private checkIframeEmbedding() {
    try {
      this.inIframe = window !== window.top;
    } catch {
      /*
       * Catching possible security errors accessing `window.top`.
       * In this case assuming we're in an iframe.
       */
      this.inIframe = true;
    }
  }

  private checkScopedCssSupport() {
    this.supportsScopedCss = Boolean((window as any).CSSScopeRule);
  }

  private calculateScrollBarSize() {
    this.scrollBarSize = window.innerWidth - document.documentElement.clientWidth;
  }
}

function supportsForeignObject(): boolean {
  return (
    Boolean(document.createElementNS) &&
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    /SVGForeignObject/.test(`${document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')}`)
  );
}
