import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';

import {AuthUser} from '../services/auth-user';

export const authUserInitializedGuard: CanActivateFn = async () => {
  try {
    await inject(AuthUser).init();
  } catch {
    // handled by `AppComponent`
  }

  return true;
};
