import {kebabCase} from 'lodash';
import {ChangeDetectionStrategy, Component, Input, TemplateRef, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CommonModule} from '@angular/common';

import {HtmlSanitizerConfig} from '../../services/html-sanitizer.service';
import {SanitizeHtmlPipe} from '../../pipes/sanitize-html.pipe';

type HorizontalPlacement = 'left' | 'right';
type VerticalPlacement = 'top' | 'bottom';

export type TooltipPlacement = VerticalPlacement | HorizontalPlacement | `${VerticalPlacement} ${HorizontalPlacement}`;

@Component({
  selector: 'w-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('state', [
      state('void', style({opacity: 0})),
      state('visible', style({opacity: 1})),
      transition(':enter', animate('150ms ease')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SanitizeHtmlPipe],
})
export class TooltipComponent {
  @Input() placement: TooltipPlacement = 'top';
  @Input() content?: string | TemplateRef<void>;
  @Input() arrow = true;
  @Input() width?: number;
  @Input() draggable = false;

  visibility = 'visible';

  readonly sanitizeConfig: HtmlSanitizerConfig = {
    allowedTags: 'span div br ul ol li b strong em',
    allowedAttrs: 'class',
  };

  get placementClassName(): string {
    return kebabCase(this.placement);
  }

  isTemplateContent(): this is {content: TemplateRef<void>} {
    return this.content instanceof TemplateRef;
  }

  isHtmlContent(): this is {content: string} {
    return Boolean(this.content) && typeof this.content === 'string';
  }
}
