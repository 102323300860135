<a *ngIf="link; else unlinkedLogo" class="logo" [href]="url">
  <ng-container *ngTemplateOutlet="icon" />
</a>

<ng-template #unlinkedLogo>
  <span class="logo logo_passive">
    <ng-container *ngTemplateOutlet="icon" />
  </span>
</ng-template>

<ng-template #icon>
  <w-svg-icon iconId="workato" [iconClass]="classes" [size]="logoSize" />
</ng-template>
