import {animate, style, transition, trigger} from '@angular/animations';
import _ from 'lodash';
import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';

import {AuthUser, AvailableTeam} from '../../../services/auth-user';
import {TeamsService} from '../../../services/teams.service';
import {AccountEnvironmentService} from '../../../services/account-environments.service';
import {AccountEnvironment} from '../../../services/account-environments.types';

const LAST_ACCESSED_GROUP_VISIBILITY_THRESHOLD = 10;
const LAST_ACCESSED_GROUP_SIZE_LIMIT = 8;
const HAS_SEARCH_THRESHOLD = 10;

@Component({
  selector: 'w-team-switcher',
  templateUrl: './team-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./team-switcher.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({width: 0, opacity: 0}),
        animate('.2s .1s cubic-bezier(.45, .05, .35, .95)', style({width: '*', opacity: 1})),
      ]),
    ]),
  ],
})
export class TeamSwitcherComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Output() hideSidebar = new EventEmitter<void>();

  teams: AvailableTeam[] = [];
  hasLastAccessedGroup = false;
  lastAccessedTeams: AvailableTeam[] = [];
  loggedUserTeam?: AvailableTeam;
  filteredTeams: AvailableTeam[] | null = null;

  hasSearch = false;
  searchQuery = '';

  constructor(
    private authUser: AuthUser,
    private teamsService: TeamsService,
    private accountEnvironments: AccountEnvironmentService,
  ) {}

  ngOnInit() {
    const teams = [...this.authUser.availableTeams];

    this.hasSearch = teams.length > HAS_SEARCH_THRESHOLD;

    if (this.authUser.team_session) {
      this.loggedUserTeam = _.remove(teams, team => this.authUser.isLoggedUser(team.id))[0];
    }

    if (teams.length > LAST_ACCESSED_GROUP_VISIBILITY_THRESHOLD) {
      const [accessedTeams, nonAccessedTeams] = _.partition(teams, team => team.team_last_sign_in_at);
      const lastAccessedTeams = _.orderBy(accessedTeams, 'team_last_sign_in_at', 'desc');

      this.lastAccessedTeams = _.take(lastAccessedTeams, LAST_ACCESSED_GROUP_SIZE_LIMIT);
      this.teams = _.drop(lastAccessedTeams, LAST_ACCESSED_GROUP_SIZE_LIMIT).concat(nonAccessedTeams);
    } else {
      this.teams = teams;
    }

    this.teams.sort((a, b) => a.name.localeCompare(b.name));

    this.hasLastAccessedGroup = this.lastAccessedTeams.length > 0;
  }

  get currentEnvironment(): AccountEnvironment | null {
    return this.accountEnvironments.current;
  }

  get availableEnvironments(): AccountEnvironment[] {
    return this.accountEnvironments.available;
  }

  get currentTeamName(): string {
    return this.authUser.current_team?.name || this.authUser.name;
  }

  get hasTeams(): boolean {
    return this.authUser.availableTeams.length > 0;
  }

  get hasEnvironments(): boolean {
    return this.availableEnvironments.length > 0;
  }

  handleSelectTeam(team: AvailableTeam) {
    this.hideSidebar.emit();
    this.teamsService.showChangeTeamOverlay(team.name);
  }

  filterTeams() {
    if (this.searchQuery) {
      this.filteredTeams = this.authUser.availableTeams
        .filter(team => team.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.filteredTeams = null;
    }
  }

  clearSearchQuery() {
    this.searchQuery = '';
    this.filterTeams();
  }
}
