import _ from 'lodash';

import {LRUCache} from '@shared/services/lru-cache';

import {parse} from './parser';
import {
  Expression,
  ExpressionFunction,
  ExpressionHelpers,
  ExpressionParserOptions,
  ParseErrorResult,
  ParseResult,
} from './types';

function isParseError(result: ParseResult): result is ParseErrorResult {
  return Boolean((result as ParseErrorResult).error);
}

export class ExpressionParser {
  cache = new LRUCache<Expression, ParseResult>(100);
  internalHelpers: ExpressionHelpers;

  constructor(private opts: ExpressionParserOptions = {}) {
    this.internalHelpers = {
      get: _.get,
      ...opts.internalHelpers,
    };
  }

  compile(expression: Expression): ExpressionFunction {
    let result = this.cache.get(expression);

    if (!result) {
      try {
        result = {fn: this.makeFunctionFromExpression(expression)};
      } catch (error) {
        result = {error};
      }

      this.cache.put(expression, result);
    }

    if (isParseError(result)) {
      throw result.error;
    } else {
      return result.fn;
    }
  }

  parse(expression: Expression): Expression {
    return parse(expression, this.opts);
  }

  makeFunctionFromExpression(expression: Expression): ExpressionFunction {
    const transformedExpression = this.parse(expression);
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const fn = new Function('data', 'helpers', '_', `return (${transformedExpression})`);

    return data => fn(data, this.opts.helpers, this.internalHelpers);
  }
}
