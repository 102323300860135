import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

import {AuthUser, AvailableTeam} from '../../../services/auth-user';
import {PageUnloadService} from '../../../services/page-unload/page-unload.service';

@Component({
  selector: 'w-team-item',
  templateUrl: './team-item.component.html',
  styleUrls: ['./team-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamItemComponent {
  @Input({required: true}) team: AvailableTeam;
  @Input() searchQuery?: string;

  @Output() onSelect = new EventEmitter<void>();

  constructor(
    private authUser: AuthUser,
    private pageUnloadService: PageUnloadService,
  ) {}

  get groupName(): string {
    return this.authUser.isLoggedUser(this.team.id) ? 'My workspace' : this.team.group_name;
  }

  handleClick(event: MouseEvent) {
    /*
     * Can't be handled by global 'beforeunload' event, as we can't detect if user accepted or rejected the dialog.
     * But we need such information in order to conditionally show team change overlay in `TeamSwitcherComponent`.
     */
    if (!this.pageUnloadService.isAllowed({reason: 'teamchange'})) {
      event.preventDefault();

      return;
    }

    this.pageUnloadService.removeAllHandlers();
    this.onSelect.emit();
  }
}
