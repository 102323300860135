import {animate, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {distinctUntilKeyChanged} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {subscriptions} from '@shared/services/subscriptions';
import {elementIsOutside} from '@shared/utils/dom/element-is-outside';
import {GlobalCaptureEventListeners} from '@shared/services/global-capture-event-listeners.service';
import {LayoutService} from '@shared/services/layout.service';

import {RouterHelpers} from '../../../services/router-helpers.service';

@Component({
  selector: 'w-header-dropdown-trigger',
  templateUrl: './header-dropdown-trigger.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(20px)'}),
        animate('.2s ease', style({opacity: 1, transform: 'translateY(0)'})),
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'translateY(0)'}),
        animate('.2s ease', style({opacity: 0, transform: 'translateY(20px)'})),
      ]),
    ]),
  ],
})
export class HeaderDropdownTriggerComponent implements OnInit, OnDestroy {
  @Input() dropdown?: TemplateRef<void> | null;

  @Output() onDropdownToggle = new EventEmitter<boolean>();

  @HostBinding('class.header-item') hostClass = true;

  opened = false;
  isCompactView$: Observable<boolean>;

  private subs = subscriptions();

  constructor(
    private globalEvents: GlobalCaptureEventListeners,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private layoutService: LayoutService,
    private routerHelpers: RouterHelpers,
  ) {
    this.isCompactView$ = this.layoutService.isCompactView$;
  }

  ngOnInit() {
    this.subs.add(
      this.routerHelpers.routeChange$.pipe(distinctUntilKeyChanged('pathname')).subscribe(() => this.close()),
    );
  }

  ngOnDestroy() {
    if (this.opened) {
      this.close();
    }
  }

  toggle() {
    if (!this.dropdown) {
      return;
    }

    this.opened ? this.close() : this.open();
  }

  private open() {
    this.opened = true;
    this.globalEvents.add({
      click: this.handleDocumentClick,
      'keydown.esc': this.handleEscapeKeydown,
    });
    this.onDropdownToggle.emit(this.opened);
  }

  private close() {
    this.opened = false;
    this.globalEvents.remove({
      click: this.handleDocumentClick,
      'keydown.esc': this.handleEscapeKeydown,
    });
    this.onDropdownToggle.emit(this.opened);
  }

  private handleDocumentClick = (event: MouseEvent) => {
    if (elementIsOutside(event.target as Element, this.elementRef.nativeElement)) {
      this.close();
      this.cd.markForCheck();
    }
  };

  private handleEscapeKeydown = (): boolean => {
    this.close();
    this.cd.markForCheck();

    return true;
  };
}
