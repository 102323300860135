import _ from 'lodash';

export type RawModel = any;

/**
 * Possible values are:
 * `Array`: this is a directory, even if array is empty (you can have empty directory).
 * `true`: this is a directory, but it's children not loaded yet.
 * `any falsy value`: this is a leaf.
 */
type ModelChildren = RawModel[] | true | null;

export type TreeNodeChildren<Node> = Node[] | true | null;
export type ModelConverter<M> = (model: RawModel) => M;
export type NodeFilter<Node> = (node: Node) => boolean;

type Walker<Node> = (node: Node, state: any) => void | boolean;

export type Model = any;

export class TreeNode<M extends Model = Model, TParent extends TreeNode<any, any> = TreeNode<any, any>> {
  static fromList<M extends Model = Model, TNode extends TreeNode<M> = TreeNode<M>>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this: new (model: RawModel | M, modelConverter?: ModelConverter<M>) => TNode,
    models: RawModel[],
    modelConverter?: ModelConverter<M>,
  ): TNode[] {
    const nodes = _.map(models, model => new this(model, modelConverter));

    TreeNode.linkNodes(nodes);

    return nodes;
  }

  static linkNodes<T extends TreeNode>(nodes: T[]): T[] {
    _.each(nodes, (node, i) => {
      node.prev = nodes[i - 1] || null;
      node.next = nodes[i + 1] || null;
    });

    return nodes;
  }

  protected static childrenModelProp = 'children';

  model: M;
  prev: this | null;
  next: this | null;
  parent: TParent | null;

  private _childModels: ModelChildren;
  private _childNodes: TreeNodeChildren<this>;
  private _nodesCalculated = false;

  constructor(
    model: RawModel | M,
    public modelConverter?: ModelConverter<M>,
    parent?: any,
  ) {
    this.parent = parent;
    this.prev = null;
    this.next = null;
    this.update(model);
  }

  /*
   * Only `TreeNodeChildren` can be returned here.
   * `ModelChildren` has to be added because getter and setter types should be the same
   * https://github.com/Microsoft/TypeScript/issues/2521
   */
  get children(): TreeNodeChildren<this> | ModelChildren {
    if (!this._nodesCalculated) {
      if (this._childModels === true) {
        // Dynamic children (should be loaded)
        this._childNodes = true;
      } else if (_.isArray(this._childModels)) {
        this._childNodes = _.map(this._childModels, childModel => this.createChild(childModel) as this);
        TreeNode.linkNodes(this._childNodes as TreeNode[]);
      } else {
        this._childNodes = null;
      }

      this._nodesCalculated = true;
    }

    return this._childNodes;
  }

  set children(children: ModelChildren | TreeNodeChildren<this>) {
    if (_.isArray(children) && _.every(children, child => child instanceof TreeNode)) {
      this._childNodes = children as this[];
      this._nodesCalculated = true;
    } else {
      this._childModels = children;
      this._nodesCalculated = false;
    }
  }

  get depth(): number {
    let depth = 0;
    let parent = this.parent;

    while (parent) {
      depth++;
      parent = parent.parent;
    }

    return depth;
  }

  get isRoot(): boolean {
    return !this.parent;
  }

  get isLeaf(): boolean {
    if (this._nodesCalculated) {
      return !this.children;
    } else {
      // Optimization that allows not to create TreeNodes for children
      return !this._childModels;
    }
  }

  get branch(): Array<this | TParent> {
    let node: this | TParent = this;
    const nodes: Array<this | TParent> = [node];

    while (node.parent) {
      nodes.unshift(node.parent);
      node = node.parent;
    }

    return nodes;
  }

  update(model: RawModel | M): this {
    this.model = this.modelConverter ? this.modelConverter(model) : model;

    if (this.model) {
      this._childModels = this.model[(this.constructor as typeof TreeNode).childrenModelProp];
      this._nodesCalculated = false;
    }

    return this;
  }

  getNestingLevel(): number {
    let nestingLevel = 0;
    let node: this | TParent = this;

    while (node.parent) {
      nestingLevel++;
      node = node.parent;
    }

    return nestingLevel;
  }

  hasChildren(condition?: NodeFilter<this>): boolean {
    if (!this._nodesCalculated && !condition) {
      // Optimization that allows not to create TreeNodes for children
      return !_.isEmpty(this._childModels);
    }

    let hasChildren = !this.isLeaf && !_.isEmpty(this.children);

    if (hasChildren && condition) {
      hasChildren = _.some(this.children as this[], condition);
    }

    return hasChildren;
  }

  hasDynamicChildren(): boolean {
    if (this._nodesCalculated) {
      return this.children === true;
    } else {
      // Optimization that allows not to create TreeNodes for children
      return this._childModels === true;
    }
  }

  getResolvedChildren<TChild = this>(): TChild[] {
    if (!this.children || this.hasDynamicChildren()) {
      return [];
    }

    return this.children as TChild[];
  }

  getFirstChild(condition: NodeFilter<this>): this | null {
    if (!this.hasChildren()) {
      return null;
    }

    return _.find(this.children as this[], condition) || null;
  }

  getLastChild(condition: NodeFilter<this>): this | null {
    if (!this.hasChildren()) {
      return null;
    }

    return _.findLast(this.children as this[], condition) || null;
  }

  getNextNode(condition?: NodeFilter<this>): this | null {
    let nextNode = this.next;

    while (nextNode) {
      if (!condition || condition(nextNode)) {
        return nextNode;
      }

      nextNode = nextNode.next;
    }

    return null;
  }

  getPrevNode(condition?: NodeFilter<this>): this | null {
    let prevNode = this.prev;

    while (prevNode) {
      if (!condition || condition(prevNode)) {
        return prevNode;
      }

      prevNode = prevNode.prev;
    }

    return null;
  }

  prependChild(node: TreeNode): this {
    return this.insertChild(node, 0);
  }

  insertChild(node: TreeNode, position: number): this {
    if (!this.hasChildren()) {
      this.children = [];
    }

    const children = this.children as TreeNode[];

    children.splice(position, 0, node);
    node.parent = this;
    this.linkChildren();

    return this;
  }

  getPosition(): number {
    if (!this.parent) {
      return -1;
    }

    return (this.parent.children as TreeNode[]).indexOf(this);
  }

  remove(): this {
    if (this.parent) {
      this.parent.removeChild(this);
    }

    return this;
  }

  removeChild(node: TreeNode): this {
    if (node?.parent === this && this.hasChildren()) {
      const children = this.children as this[];

      _.pull(children, node);
      node.parent = null;
      this.linkChildren();
    }

    return this;
  }

  linkChildren(): this {
    if (this.hasChildren()) {
      TreeNode.linkNodes(this.children as this[]);
    }

    return this;
  }

  sortChildrenBy<TChild = this>(sorter: _.ListIterator<TChild, any> | string): this {
    if (this.hasChildren()) {
      this.children = _.sortBy(this.children as TChild[], sorter);
      this.linkChildren();
    }

    return this;
  }

  walk(walker: Walker<this>, state?: any): boolean {
    if (walker(this, state) === false) {
      return false;
    }

    return this.walkChildren(walker, state);
  }

  walkChildren<T extends TreeNode = this>(walker: Walker<T>, state?: any): boolean {
    const interrupted = _.some(this.children as T[], childNode => childNode.walk(walker, state) === false);

    return !interrupted;
  }

  findChild<T extends TreeNode = this>(cb: NodeFilter<T>): T | null {
    return _.find(this.children as T[], cb) || null;
  }

  findNode<TChild = this>(cb: NodeFilter<this | TChild>): this | TChild | null {
    let foundNode: TChild | this | null = null;

    this.walk(node => {
      if (cb(node)) {
        foundNode = node;

        return false;
      }
    });

    return foundNode;
  }

  toModel(): M {
    // Need `as any` because of https://github.com/Microsoft/TypeScript/issues/13557#issuecomment-296764891
    const model = {...(this.model as any)};

    if (this.hasChildren()) {
      model[(this.constructor as typeof TreeNode).childrenModelProp] = _.map(this.children as TreeNode[], item =>
        item.toModel(),
      );
    }

    return model;
  }

  protected createChild(model: any): any {
    const constructor = this.constructor as new (...args: any[]) => any;

    return new constructor(model, this.modelConverter, this);
  }
}
