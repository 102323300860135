import {distinctUntilKeyChanged, map} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {subscriptions} from '@shared/services/subscriptions';
import {LayoutService} from '@shared/services/layout.service';

import {AuthUser} from '../../../services/auth-user';
import {PageService} from '../../../services/page.service';
import {RouterHelpers} from '../../../services/router-helpers.service';

const MENU_BODY_CLASS = 'w-page-global_pushed';

@Component({
  selector: 'w-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() theme: 'dark' | 'light' = 'dark';

  menuOpened = false;
  isDesktopLayout$: Observable<boolean>;

  private subs = subscriptions();

  constructor(
    private authUser: AuthUser,
    private pageService: PageService,
    private routerHelpers: RouterHelpers,
    private cd: ChangeDetectorRef,
    private layout: LayoutService,
  ) {
    this.isDesktopLayout$ = this.layout.change.pipe(map(() => this.layout.isDesktopView));
  }

  ngOnInit() {
    this.subs.add(
      this.routerHelpers.routeChange$.pipe(distinctUntilKeyChanged('pathname')).subscribe(() => {
        if (this.menuOpened) {
          this.toggleMenu();
          this.cd.markForCheck();
        }
      }),
    );
  }

  get isAuthenticated(): boolean {
    return this.authUser.authenticated;
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;

    if (this.menuOpened) {
      this.pageService.addClass(MENU_BODY_CLASS);
    } else {
      this.pageService.removeClass(MENU_BODY_CLASS);
    }
  }
}
