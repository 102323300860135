<span
  class="switch-control__switcher switch-control__switcher_{{ size }}"
  [class.switch-control__switcher_active]="active"
  [class.switch-control__switcher_disabled]="disabled"
  [attr.data-name]="name"
  (click)="toggle($event)"
>
  <ng-container *ngIf="showOnOff">
    <span *ngIf="!active" class="switch-control__off">off</span>
    <span *ngIf="active" class="switch-control__on">on</span>
  </ng-container>
</span>
<span *ngIf="label" class="switch-control__label switch-control__label_{{ size }}">{{ label }}</span>
