import _ from 'lodash';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource} from '@shared/services/http-resource';

import {appSettings} from '../app-settings';
import {AdapterInfo, AdapterParams, Adapters} from '../types';

interface GetMetaParams {
  adapterNames: string[];
}

@Injectable({
  providedIn: 'root',
})
export class Integrations {
  private resource: HttpResource;

  constructor(private http: HttpClient) {
    this.resource = new HttpResource(this.http, {
      url: '/integrations/{{action}}',
    });
  }

  async getMeta(metaParams: GetMetaParams, params?: AdapterParams): Promise<Adapters> {
    const query = {
      name: _.sortBy(metaParams.adapterNames).join(','),
      v: appSettings.version,
      ...params,
    };

    return this.resource.get(
      {
        action: 'meta',
      },
      {
        query,
      },
    );
  }

  async getUpcoming(): Promise<AdapterInfo[]> {
    return this.resource.get({action: 'upcoming'});
  }

  async getCommunity(): Promise<AdapterInfo[]> {
    return this.resource.get({action: 'community'});
  }
}
