import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import {ComponentClass} from '@shared/types/angular';

import {PageBanner, PageBannerContentProps} from '../page-banners.types';
import {AbstractBannerComponent} from '../../../components/banners-content/abstract-banner.component';

@Component({
  selector: 'w-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageBannerComponent implements OnInit {
  @Input({required: true}) bannerId: PageBanner['id'];
  @Input({required: true}) content: PageBanner['content'];
  @Input({required: true}) type: PageBanner['type'];
  @Input() isDismissible = true;
  @Input() tag?: PageBanner['tag'];
  @Input('contentInputs') contentInputsProvided?: PageBannerContentProps;

  @Output() onDismiss = new EventEmitter<void>();

  contentInputs: Pick<AbstractBannerComponent, 'bannerId'> & Exclude<PageBannerContentProps, void>;

  ngOnInit() {
    if (this.isComponentContent(this.content)) {
      this.contentInputs = {
        bannerId: this.bannerId,
        ...(this.contentInputsProvided || {}),
      };
    }
  }

  isComponentContent(content: PageBanner['content']): content is ComponentClass {
    return typeof content !== 'string' && !(content instanceof TemplateRef);
  }
}
