import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';

import {IconSize} from '@shared/components/svg-icon/svg-icon.component';

@Component({
  selector: 'w-logo',
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() url = '/?fid=projects';
  @Input() link = true;
  @Input() size: 'large' | 'default' | 'small' = 'default';
  @Input() type?: 'gray' | 'black';

  get classes(): NgClass['ngClass'] {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'logo__workato': true,
      [`logo__workato_${this.type}`]: Boolean(this.type),
    };
  }

  get logoSize(): IconSize {
    switch (this.size) {
      case 'large':
        return [167, 27];
      case 'small':
        return [66, 11];
      case 'default':
        return [127, 20];
    }
  }
}
