import {Pipe, PipeTransform} from '@angular/core';

import {fromJson} from '../utils/from-json';

@Pipe({name: 'wParseJson'})
export class ParseJsonPipe implements PipeTransform {
  transform(str: string): any {
    return fromJson(str);
  }
}
