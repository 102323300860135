import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'wUrlEncode',
})
export class UrlEncodePipe implements PipeTransform {
  transform(...args: Parameters<typeof encodeURIComponent>): string {
    return encodeURIComponent(...args);
  }
}
