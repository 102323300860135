import {NgModule} from '@angular/core';

import {SharedModule} from '../../shared.module';
import {UserAvatarModule} from '../user-avatar/user-avatar.module';

import {HeaderComponent} from './header/header.component';
import {UserInfoComponent} from './user-info/user-info.component';
import {HeaderItemComponent} from './header-item/header-item.component';
import {HeaderDropdownComponent} from './header-dropdown/header-dropdown.component';
import {GuestHeaderComponent} from './guest-header/guest-header.component';
import {HeaderDropdownTriggerComponent} from './header-dropdown-trigger/header-dropdown-trigger.component';
import {HeaderLinkComponent} from './header-link/header-link.component';
import {HeaderLogoutButtonComponent} from './header-logout-button/header-logout-button.component';

@NgModule({
  imports: [SharedModule, UserAvatarModule],
  declarations: [
    HeaderComponent,
    UserInfoComponent,
    HeaderItemComponent,
    HeaderDropdownComponent,
    GuestHeaderComponent,
    HeaderDropdownTriggerComponent,
    HeaderLinkComponent,
    HeaderLogoutButtonComponent,
  ],
  exports: [
    HeaderComponent,
    HeaderItemComponent,
    GuestHeaderComponent,
    HeaderDropdownComponent,
    UserInfoComponent,
    HeaderLinkComponent,
    HeaderDropdownTriggerComponent,
    HeaderLogoutButtonComponent,
  ],
})
export class HeaderModule {}
