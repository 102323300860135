import {isPlainObject} from 'lodash';
import {inject} from '@angular/core';

import {
  ColumnTypeIcons,
  DATE_LIKE_SET_TYPES,
  DATE_LIKE_TYPES,
  DataTable,
  DataTableCellValue,
  DataTableColumn,
  DataTableColumnType,
  DataTableDateLikeColumnType,
  DataTableDateLikeSetColumnType,
  DataTableFile,
  DataTableInvalidRelation,
  DataTableNumberLikeColumnType,
  DataTableNumberSetLikeColumnType,
  DataTableRelation,
  DataTableRelationColumn,
  DataTableRelationSetColumnType,
  DataTableSetColumnType,
  DataTableTextLikeColumnType,
  DataTableTextSetLikeColumnType,
  DataTableValidRelation,
  NUMBER_LIKE_TYPES,
  NUMBER_SET_LIKE_TYPES,
  RELATION_SET_TYPES,
  TEXT_LIKE_TYPES,
  TEXT_SET_TYPES,
} from './data-table.types';
import {
  COLUMN_TYPE_ICON_OVERRIDES,
  DEFAULT_COLUMN_TYPE_ICONS,
  INVALID_RELATION_TEXT,
  REMOVED_RELATION_TEXT,
  SET_COLUMN_TYPES,
} from './data-table.constants';
import {DataTableUnifiedId} from './abstract-data-table.service';

export function isTableWithSameId(table: DataTable, id: DataTableUnifiedId): boolean {
  return table.id === id || table.table_id === id;
}

export function isRelationColumn(
  column: Pick<DataTableColumn, 'type'> | null | undefined,
): column is DataTableRelationColumn {
  return column?.type === 'relation' || column?.type === 'relation-set';
}

export function isRelationCellValue(value: DataTableCellValue): value is DataTableRelation {
  return Boolean(isPlainObject(value) && (value as DataTableValidRelation).record_id);
}

export function isRelationSetCellValue(value: DataTableCellValue): value is DataTableRelation[] {
  return Boolean(
    (value as DataTableRelation[])?.length &&
      isPlainObject(value![0]) &&
      (value![0] as DataTableValidRelation).record_id,
  );
}

export function isValidRelationCellValue(value: DataTableCellValue): value is DataTableValidRelation {
  return Boolean(isRelationCellValue(value) && !(value as DataTableInvalidRelation).invalid);
}

export function getRelationCellValueLabel(value: DataTableCellValue): string {
  return isValidRelationCellValue(value) ? value.value ?? REMOVED_RELATION_TEXT : INVALID_RELATION_TEXT;
}

export function isFileCellValue(value: DataTableCellValue): value is DataTableFile {
  return isPlainObject(value) && typeof (value as DataTableFile).files_storage_path === 'string';
}

// This function can only be used in constructor context, as it injects optional icon overrides
export function columnTypeIcons(): ColumnTypeIcons {
  return {
    ...DEFAULT_COLUMN_TYPE_ICONS,
    ...inject(COLUMN_TYPE_ICON_OVERRIDES, {optional: true}),
  };
}

export function isSetColumnType(type: DataTableColumnType): type is DataTableSetColumnType {
  return SET_COLUMN_TYPES.includes(type as DataTableDateLikeSetColumnType);
}

export function isNumberLikeColumnType(type: DataTableColumnType): type is DataTableNumberLikeColumnType {
  return NUMBER_LIKE_TYPES.includes(type as DataTableNumberLikeColumnType);
}

export function isDateLikeColumnType(type: DataTableColumnType): type is DataTableDateLikeColumnType {
  return DATE_LIKE_TYPES.includes(type as DataTableDateLikeColumnType);
}

export function isTextLikeColumnType(type: DataTableColumnType): type is DataTableTextLikeColumnType {
  return TEXT_LIKE_TYPES.includes(type as DataTableTextLikeColumnType);
}

export function isTextSetColumnType(type: DataTableColumnType): type is DataTableTextSetLikeColumnType {
  return TEXT_SET_TYPES.includes(type as DataTableTextSetLikeColumnType);
}

export function isNumberSetColumnType(type: DataTableColumnType): type is DataTableNumberSetLikeColumnType {
  return NUMBER_SET_LIKE_TYPES.includes(type as DataTableNumberSetLikeColumnType);
}

export function isDateLikeSetColumnType(type: DataTableColumnType): type is DataTableDateLikeSetColumnType {
  return DATE_LIKE_SET_TYPES.includes(type as DataTableDateLikeSetColumnType);
}

export function isRelationSetColumnType(type: DataTableColumnType): type is DataTableRelationSetColumnType {
  return (RELATION_SET_TYPES as DataTableColumnType[]).includes(type);
}
