import {Directive, EmbeddedViewRef, Input, OnChanges, TemplateRef, ViewContainerRef} from '@angular/core';

import {AuthUser, AuthUserPrivileges} from '../services/auth-user';

export class HasPermissionDirectiveContext<T = unknown> {
  public $implicit: T = null!;
}

@Directive({
  selector: '[wHasPermission]',
})
export class HasPermissionDirective implements OnChanges {
  @Input({alias: 'wHasPermission', required: true}) privileges: AuthUserPrivileges;

  @Input('wHasPermissionElse') elseTemplateRef?: TemplateRef<any>;

  private viewRef: EmbeddedViewRef<any> | null = null;
  private elseViewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private authUser: AuthUser,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnChanges() {
    if (this.hasPermission) {
      if (!this.viewRef) {
        this.viewContainer.clear();
        this.elseViewRef = null;
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.viewRef = null;

        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
        }
      }
    }
  }

  private get hasPermission(): boolean {
    return this.authUser.hasPrivilege(this.privileges);
  }
}
