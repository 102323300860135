import {Pipe, PipeTransform} from '@angular/core';

import {NullableDateLike} from '@shared/types';
import {DateFormatAlias, DatePipe} from '@shared/pipes/date.pipe';

@Pipe({name: 'wDateUtc'})
export class DateUtcPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(dateLike: NullableDateLike, format: DateFormatAlias): string {
    return this.datePipe.transform(dateLike, format, null, {zone: 'utc'});
  }
}
