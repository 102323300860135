import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {GlobalCaptureEventListeners} from '@shared/services/global-capture-event-listeners.service';

import {PageService, SIDEBAR_OPENED_CLASS} from '../../services/page.service';

export const SUBMENU_ANIMATION_TIME = 200;
export const SIDEBAR_TOGGLE_DELAY = 300;
export const SUBMENU_TOGGLE_DELAY = 300;

@Injectable({providedIn: 'root'})
export class SidebarService {
  isSidebarOpened$: Observable<boolean>;
  isSubmenuOpened$: Observable<boolean>;
  isTeamSwitcherOpened$: Observable<boolean>;

  private isSidebarOpenedSub = new BehaviorSubject<boolean>(false);
  private isSubmenuOpenedSub = new BehaviorSubject<boolean>(false);
  private isTeamSwitcherOpenedSub = new BehaviorSubject<boolean>(false);

  constructor(
    private globalEvents: GlobalCaptureEventListeners,
    private pageService: PageService,
  ) {
    this.isSidebarOpened$ = this.isSidebarOpenedSub.asObservable();
    this.isSubmenuOpened$ = this.isSubmenuOpenedSub.asObservable();
    this.isTeamSwitcherOpened$ = this.isTeamSwitcherOpenedSub.asObservable();
  }

  get isSidebarOpened(): boolean {
    return this.isSidebarOpenedSub.value;
  }

  get isSubmenuOpened(): boolean {
    return this.isSubmenuOpenedSub.value;
  }

  get isTeamSwitcherOpened(): boolean {
    return this.isTeamSwitcherOpenedSub.value;
  }

  openSidebar() {
    this.isSidebarOpenedSub.next(true);

    this.pageService.addClass(SIDEBAR_OPENED_CLASS);
  }

  hideSidebar() {
    this.closeSubmenu();
    this.closeTeamSwitcher();
    this.isSidebarOpenedSub.next(false);
    this.pageService.removeClass(SIDEBAR_OPENED_CLASS);
  }

  openSubmenu() {
    this.isSubmenuOpenedSub.next(true);
  }

  closeSubmenu() {
    this.isSubmenuOpenedSub.next(false);
  }

  openTeamSwitcher() {
    this.globalEvents.add({
      'keydown.esc': this.handleTeamSwitcherEscapeKeydown,
    });
    this.pageService.togglePageOverflow(true);

    this.isTeamSwitcherOpenedSub.next(true);
  }

  closeTeamSwitcher() {
    this.globalEvents.remove({
      'keydown.esc': this.handleTeamSwitcherEscapeKeydown,
    });
    this.pageService.togglePageOverflow(false);

    this.isTeamSwitcherOpenedSub.next(false);
  }

  private handleTeamSwitcherEscapeKeydown = (): boolean => {
    this.hideSidebar();

    return true;
  };
}
