export abstract class BroadcastChannelAdapter<TMessage = any> {
  static isAvailable(): boolean {
    return true;
  }

  abstract postMessage(message: TMessage): void;
  abstract setMessageHandler(handler: (message: TMessage) => void): void;
  abstract close(): void;

  constructor(protected readonly channelName: string) {}
}
