<div class="error-page">
  <div class="error-page__content">
    <div class="error-page__img"></div>
    <h1 class="error-page__title" [innerHTML]="data.title"></h1>
    <div *ngIf="data.description" class="error-page__description" [innerHTML]="data.description"></div>
    <div class="error-page__cta">
      <w-button *ngIf="canGoBack" (onClick)="goBack()"> Go back </w-button>
      <w-button *ngIf="data.buttons.refresh" (onClick)="refresh()"> Refresh </w-button>
      <w-button
        *ngIf="data.buttons.home"
        href="/"
        [nativeNavigation]="true"
        wMixpanel="{{ data.type }} page: Go to home page"
      >
        Go to home page
      </w-button>
    </div>
  </div>
</div>
