import {SelectFieldWithOptions, TextField} from '../../types';

import {IfCondition, Keywords} from './app-dictionary';

export const IF_CONDITIONS: IfCondition[] = [
  {
    name: 'contains',
    title: 'contains',
    description: 'Does field contain value? Accepts types: Array, String',
  },
  {
    name: 'starts_with',
    title: 'starts with',
    description: 'Does field start with value? Accepts types: String',
  },
  {
    name: 'ends_with',
    title: 'ends with',
    description: 'Does field end with value? Accepts types: String',
  },
  {
    name: 'not_contains',
    title: "doesn't contain",
    description: 'Does field lack value? Accepts types: Array, String',
  },
  {
    name: 'not_starts_with',
    title: "doesn't start with",
    description: 'Does field not start with value? Accepts types: String',
  },
  {
    name: 'not_ends_with',
    title: "doesn't end with",
    description: 'Does field not end with value? Accepts types: String',
  },
  {
    name: 'equals_to',
    title: 'equals',
    description: 'Is field equal to value? Must be same type. Accepts all data types.',
  },
  {
    name: 'not_equals_to',
    title: 'does not equal',
    description: 'Is field different from value? Accepts all data types.',
  },
  {
    name: 'greater_than',
    title: 'greater than',
    description: 'Is field greater than value? Accepts types: String, Date, Timestamp, Integer, Number, Boolean',
  },
  {
    name: 'less_than',
    title: 'less than',
    description: 'Is field less than value? Accepts types: String, Date, Timestamp, Integer, Number, Boolean',
  },
  {
    name: 'is_true',
    title: 'is true',
    description: 'Is field true? Accepts types: Boolean',
    hide_rhs: true,
  },
  {
    name: 'is_not_true',
    title: 'is not true',
    description: 'Is field false? Accepts types: Boolean',
    hide_rhs: true,
  },
  {
    name: 'present',
    title: 'is present',
    description: 'Is field a non-null value? Accepts all data types.',
    hide_rhs: true,
  },
  {
    name: 'blank',
    title: 'is not present',
    description: 'Is field a null value? Accepts all data types.',
    hide_rhs: true,
  },
];

export const CONDITION_SCHEMA: [TextField, SelectFieldWithOptions, TextField] = [
  {
    name: 'lhs',
    type: 'string',
    control_type: 'text',
    label: 'Data field',
  },
  {
    name: 'operand',
    type: 'string',
    control_type: 'select',
    label: 'Condition',
    options: IF_CONDITIONS,
    placeholder: 'Select condition',
  },
  {
    name: 'rhs',
    type: 'string',
    control_type: 'text',
    label: 'Value',
  },
];

export const KEYWORDS_CONFIG: Keywords = {
  trigger: {
    block: true,
    config: true,
  },

  foreach: {
    block: true,
    config: [
      {
        name: 'source',
        type: 'string',
        control_type: 'list',
        label: 'Input list',
        enforce_template_mode: true,
        hint: 'Input a list datapill.',
      },
      {
        name: 'repeat_mode',
        type: 'string',
        control_type: 'select',
        label: 'Repeat mode',
        options: [
          ['One item at a time', 'simple'],
          ['Batch of items', 'batch'],
        ],
        default: 'simple',
        hint: 'When set to <b>Batch of items</b>, the <b>Input list</b> is repeated in batches of specified size',
      },
      {
        name: 'batch_size',
        type: 'integer',
        control_type: 'integer',
        label: 'Batch size',
        optional: true,
        hint: 'Defaults to 100 when the value is not specified OR when the value is less than 1',
        ngIf: 'input.repeat_mode === "batch"',
      },
      {
        name: 'clear_scope',
        type: 'boolean',
        control_type: 'checkbox',
        optional: true,
        label: 'Clear step output',
        hint: "Typically <b>no</b> for most use cases. <a href='https://docs.workato.com/features/list-management.html#list-repeat-action' target='_blank'>Learn more</a>",
      },
    ],
  },

  stop: {
    config: [
      {
        name: 'stop_with_error',
        type: 'boolean',
        control_type: 'select',
        options: [
          {
            name: 'false',
            title: 'Successful',
          },
          {
            name: 'true',
            title: 'Failed',
          },
        ],
        label: 'In job report, mark stopped job as',
      },
      {
        name: 'stop_reason',
        type: 'string',
        control_type: 'text',
        label: 'Reason for failure',
        hint: 'Error message to be displayed in the job report for each failed job.',
        ngIf: 'input.stop_with_error === "true"',
      },
    ],
  },

  if: {
    block: true,
    config: [
      {
        type: 'array',
        of: 'object',
        name: 'conditions',
        properties: CONDITION_SCHEMA,
      },
    ],
  },

  elsif: {
    block: true,
    config: [
      {
        type: 'array',
        of: 'object',
        name: 'conditions',
        properties: CONDITION_SCHEMA,
      },
    ],
  },

  else: {
    block: true,
  },

  action: {
    block: false,
    config: true,
  },

  try: {
    block: true,
  },

  catch: {
    block: true,
    config: [
      {
        name: 'max_retry_count',
        type: 'number',
        control_type: 'select',
        label: 'Retry actions in Monitor block?',
        hint: 'After all retry attempts fail, actions in the <b>Error</b> block will be processed',
        default: 0,
        options: [
          ['Do not retry', 0],
          ['Retry 1 time', 1],
          ['Retry 2 times', 2],
          ['Retry 3 times', 3],
        ],
      },
      {
        name: 'retry_interval',
        type: 'number',
        control_type: 'select',
        label: 'Time interval between retries',
        hint: 'Time to wait before retrying actions in <b>Monitor</b> block',
        default: 2,
        options: [
          ['1 second', 1],
          ['2 seconds', 2],
          ['3 seconds', 3],
          ['4 seconds', 4],
          ['5 seconds', 5],
          ['6 seconds', 6],
          ['7 seconds', 7],
          ['8 seconds', 8],
          ['9 seconds', 9],
          ['10 seconds', 10],
        ],
        ngIf: 'input.max_retry_count > 0',
      },
    ],
  },

  repeat: {
    block: true,
  },

  while_condition: {
    config: [
      {
        type: 'array',
        of: 'object',
        name: 'conditions',
        properties: CONDITION_SCHEMA,
      },
    ],
  },
};
