<div
  [@slideInOut]="slideAnimationState"
  (@slideInOut.start)="animationInProgress = true"
  (@slideInOut.done)="animationInProgress = false"
  class="sidebar"
  [class.sidebar_opened]="isSidebarOpened"
  [class.sidebar_has-team-switcher]="isTeamSwitcherOpened"
  role="navigation"
  aria-label="Main"
>
  <div class="sidebar__user-info">
    <w-sidebar-user
      [env]="currentEnvironment"
      [hasTeamSwitch]="hasTeamSwitch"
      [isSidebarOpened]="isSidebarOpened"
      [isTeamSwitcherOpened]="isTeamSwitcherOpened"
    />
  </div>
  <div *ngIf="!isTeamSwitcherOpened" @fadeOutContent class="sidebar__content">
    <hr class="sidebar__separator" />
    <div class="sidebar__navigations">
      <div class="sidebar__items sidebar__items_top">
        <ng-container *ngIf="!isAppUser; else appUserItemsTmpl">
          <w-sidebar-item
            iconId="projects"
            mixpanel="Projects"
            [routerUrl]="{path: '/recipes', query: {fid: 'projects'}}"
            [alsoActiveFor]="['/', '/recipes', '/recipes/*', '/connections/*', '/data_tables/*']"
            [text]="projectsHeaderItemName"
          />

          <w-sidebar-item
            iconId="operations-hub"
            mixpanel="Operations hub"
            text="Operations hub"
            [routerUrl]="{path: '/dashboard'}"
          />

          <w-sidebar-item
            *ngIf="hasCommunityAccess"
            iconId="community"
            mixpanel="Community Recipes"
            text="Community library"
            [routerUrl]="{path: '/browse'}"
          />

          <w-sidebar-item *ngIf="hasPlatformItems" iconId="platform" text="Platform" [subMenu]="platformSubmenu" />

          <w-sidebar-item *ngIf="hasTools" iconId="tools" text="Tools" [subMenu]="toolsSubmenu" />

          <hr class="sidebar__separator" />

          <w-sidebar-item
            *wHasPermission="'federation_management.read'"
            iconId="organization-console"
            mixpanel="Automation HQ"
            text="Automation HQ"
            [routerUrl]="{path: '/organization_console'}"
          />

          <w-sidebar-item
            *ngIf="hasOemAdminConsole"
            mixpanel="Manage customers"
            iconId="manage_customers"
            text="Manage customers"
            [routerUrl]="{path: '/admin_console'}"
          />

          <w-sidebar-item *ngIf="adminSubmenu.length" iconId="admin" text="Admin" [subMenu]="adminSubmenu" />
        </ng-container>
        <ng-template #appUserItemsTmpl>
          <w-sidebar-item
            iconId="tools"
            mixpanel="Integration apps"
            text="Apps"
            [routerUrl]="{path: '/installed_integration_apps'}"
            [alsoActiveFor]="['/', '/installed_integration_apps/*']"
          />
        </ng-template>

        <w-sidebar-item
          *ngIf="workspaceAdminTool"
          mixpanel="Workspace admin"
          text="Workspace admin"
          [iconId]="workspaceAdminTool.id"
          [routerUrl]="{path: workspaceAdminTool.href}"
        />
        <w-sidebar-item
          *ngIf="hasUsageDashboardAccess"
          iconId="usage_dashboard"
          text="Usage"
          [routerUrl]="{path: '/usage'}"
        />
      </div>

      <div class="sidebar__items sidebar__items_bottom">
        <w-sidebar-item mixpanel="Account Settings" text="My profile" [routerUrl]="{path: '/users/current/edit'}">
          <ng-template wSidebarItemIcon>
            <w-user-avatar class="sidebar__user-avatar" [imgUrl]="userAvatar" [userName]="userName" [size]="16" />
          </ng-template>
        </w-sidebar-item>

        <w-sidebar-logout-item />
      </div>
    </div>
    <hr class="sidebar__separator" />
    <div class="sidebar__footer">
      <a class="sidebar__logo-link" [href]="logoLinkUrl" wMixpanel="Header navigation: Logo">
        <ng-container *ngIf="isWhitelabel; else workatoLogo">
          <img
            *ngIf="oemLogoSmall"
            class="sidebar__logo sidebar__logo_icon"
            [style.left.px]="oemFooterPositionLeft"
            [src]="oemLogoSmall"
            [alt]="oemCompany"
            (load)="handleOemLogoIconLoad($event)"
          />
          <img
            *ngIf="oemLogo"
            class="sidebar__logo sidebar__logo_full"
            [style.left.px]="oemFooterPositionLeft"
            [src]="oemLogo"
            [alt]="oemCompany"
          />
        </ng-container>
      </a>
      <ng-template #workatoLogo>
        <w-svg-icon
          class="sidebar__logo sidebar__logo_icon sidebar__logo_workato-icon"
          iconId="workato-logo"
          [size]="40"
        />
        <w-svg-icon class="sidebar__logo sidebar__logo_full" iconId="workato" [size]="148" />
      </ng-template>
      <div *ngIf="helpLinks.length" class="sidebar__help" [style.left.px]="oemFooterPositionLeft">
        <a *ngFor="let link of helpLinks" class="sidebar__help-link" [href]="link.href">
          {{ link.name }}
        </a>
      </div>
    </div>
  </div>
</div>

<div
  [@slideInOutCompactHeader]="slideAnimationStateCompactHeader"
  class="sidebar-compact-header"
  [class.sidebar-compact-header_opened]="isSidebarOpened"
>
  <a class="sidebar-compact-header__logo" [href]="logoLinkUrl" wMixpanel="Header navigation: Logo">
    <img *ngIf="isWhitelabel; else workatoTabletLogoTmpl" [src]="oemLogo" [alt]="oemCompany" />
  </a>

  <div class="sidebar-compact-header__burger-menu">
    <button class="burger-menu" [class.burger-menu_open]="isSidebarOpened" (click)="handleBurgerButtonClick()">
      <span class="burger-menu__item"></span>
      <span class="burger-menu__item"></span>
      <span class="burger-menu__item"></span>
      <span class="burger-menu__item"></span>
    </button>
  </div>
</div>

<div
  *ngIf="(isCompact && isSidebarOpened) || isTeamSwitcherOpened"
  @fadeInOutOverlay
  class="sidebar-overlay"
  (click)="hideSidebar()"
></div>

<ng-template #workatoTabletLogoTmpl>
  <w-svg-icon iconId="workato" [size]="148" />
</ng-template>
