import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CommonModule} from '@angular/common';

import {TemplateOrTextComponent} from '@shared/components/template-or-text/template-or-text.component';

import {BubbleHeaderDirective} from './bubble-header.directive';
import {BubbleContentDirective} from './bubble-content.directive';
import {BubbleActionsDirective} from './bubble-actions.directive';

@Component({
  selector: 'w-bubble',
  templateUrl: './bubble.component.html',
  standalone: true,
  imports: [CommonModule, TemplateOrTextComponent],
  styleUrls: ['./bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('state', [
      state('void', style({opacity: 0})),
      state('visible', style({opacity: 1})),
      transition(':enter', animate('250ms ease-out')),
    ]),
  ],
})
export class BubbleComponent {
  @Input('header') headerInput?: string | TemplateRef<void>;
  @Input('content') contentInput?: string | TemplateRef<void>;
  @Input() size: 'default' | 'large' = 'default';

  @ContentChild(BubbleHeaderDirective, {read: TemplateRef}) headerContentChild?: TemplateRef<void>;
  @ContentChild(BubbleContentDirective, {read: TemplateRef}) contentContentChild?: TemplateRef<void>;
  @ContentChild(BubbleActionsDirective, {read: TemplateRef}) actionsContentChild?: TemplateRef<void>;

  visibility = 'visible';

  get header(): string | TemplateRef<void> | undefined {
    return this.headerInput || this.headerContentChild;
  }

  get content(): string | TemplateRef<void> | undefined {
    return this.contentInput || this.contentContentChild;
  }
}
