import {ChangeDetectionStrategy, Component, Inject, Input, OnChanges, Optional, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import URL from 'url-parse';

import {WSimpleChanges} from '../../types/angular';
import {SvgIconComponent} from '../svg-icon/svg-icon.component';

import {FORBID_EXTERNAL_SAME_ORIGIN_LINK} from './external-link-tokens';

type ExternalLinkIconMode = 'visible' | 'hidden' | 'hover';

@Component({
  selector: 'w-external-link',
  templateUrl: './external-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./external-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
})
export class ExternalLinkComponent implements OnChanges {
  @Input({required: true}) href: string;
  @Input() iconSize = 10;
  @Input() iconMode: ExternalLinkIconMode = 'visible';
  @Input() color: 'primary' | 'secondary' | 'brand' | 'neutral' | 'active' | 'unset' = 'primary';
  @Input() underline = true;
  @Input() download = false;

  treatAsInternal = false;

  constructor(
    @Optional()
    @Inject(FORBID_EXTERNAL_SAME_ORIGIN_LINK)
    private forbidExternalSameOriginLink?: boolean,
  ) {}

  ngOnChanges(changes: WSimpleChanges<ExternalLinkComponent>) {
    if (changes.href) {
      this.updateTreatAsInternal();
    }
  }

  get target(): string {
    return this.treatAsInternal ? '_self' : '_blank';
  }

  private updateTreatAsInternal() {
    if (this.forbidExternalSameOriginLink) {
      this.treatAsInternal = new URL(this.href).origin === location.origin;
    } else {
      this.treatAsInternal = false;
    }
  }
}
