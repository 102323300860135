import {Pipe, PipeTransform} from '@angular/core';

import {lowerFirst} from '../utils/lower-first';

@Pipe({
  name: 'lowerFirst',
})
export class LowerFirstPipe implements PipeTransform {
  transform(str: string): string {
    return lowerFirst(str);
  }
}
