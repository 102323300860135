import {Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';
import {BehaviorSubject, Observable} from 'rxjs';

import {PageBanner, PageBannerContentProps, PageBannerTag} from './page-banners.types';

const BANNER_TYPES_ORDER = ['error', 'warning', 'notice'] as const;
const BANNER_TAG_TYPES_ORDER = ['error', 'warning', 'notice', 'info', 'success', undefined] as const;

@Injectable({
  providedIn: 'root',
})
export class PageBannersService {
  banners$: Observable<Array<PageBanner<PageBannerContentProps>>>;

  private banners: Record<PageBanner['type'], Map<PageBanner['id'], PageBanner<PageBannerContentProps>>> = {
    error: new Map(),
    warning: new Map(),
    notice: new Map(),
  };

  private _banners$ = new BehaviorSubject(this.getBannersByPriority());

  constructor() {
    this.banners$ = this._banners$.asObservable();
  }

  error<TContentProps extends PageBannerContentProps = void>(
    bannerOptions: Omit<PageBanner<TContentProps>, 'id' | 'type'>,
  ): PageBanner['id'] {
    return this.add({type: 'error', ...bannerOptions});
  }

  warning<TContentProps extends PageBannerContentProps = void>(
    bannerOptions: Omit<PageBanner<TContentProps>, 'id' | 'type'>,
  ): PageBanner['id'] {
    return this.add({type: 'warning', ...bannerOptions});
  }

  notice<TContentProps extends PageBannerContentProps = void>(
    bannerOptions: Omit<PageBanner<TContentProps>, 'id' | 'type'>,
  ): PageBanner['id'] {
    return this.add({type: 'notice', ...bannerOptions});
  }

  remove(id: PageBanner['id']) {
    const bannerType = BANNER_TYPES_ORDER.find(type => this.banners[type].has(id));

    if (!bannerType) {
      return;
    }

    this.banners[bannerType].delete(id);
    this._banners$.next(this.getBannersByPriority());
  }

  private add<TContentProps extends PageBannerContentProps = void>(
    banner: Omit<PageBanner<TContentProps>, 'id'>,
  ): PageBanner['id'] {
    const id = uuid();

    this.banners[banner.type].set(id, {id, ...banner});
    this._banners$.next(this.getBannersByPriority());

    return id;
  }

  private getBannersByPriority(): Array<PageBanner<PageBannerContentProps>> {
    return BANNER_TYPES_ORDER.flatMap(type => {
      const byTagMap = new Map<PageBannerTag['type'], Array<PageBanner<PageBannerContentProps>>>();

      for (const banner of this.banners[type].values()) {
        const tagType = banner.tag?.type;
        const banners = byTagMap.get(tagType);

        byTagMap.set(tagType, banners ? banners.concat(banner) : [banner]);
      }

      return BANNER_TAG_TYPES_ORDER.flatMap(tagType => byTagMap.get(tagType) || []);
    });
  }
}
