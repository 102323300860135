import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

import {LayoutService} from '@shared/services/layout.service';

import {SUBMENU_ANIMATION_TIME} from '../sidebar.service';
import {SidebarSubMenuItem} from '../sidebar.types';

@Component({
  selector: 'w-sidebar-submenu',
  templateUrl: './sidebar-submenu.component.html',
  styleUrls: ['./sidebar-submenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateX(-12px)'}),
        animate(
          `${SUBMENU_ANIMATION_TIME}ms cubic-bezier(.45, .05, .35, .95)`,
          style({opacity: 1, transform: 'translateX(0px)'}),
        ),
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'translateX(0px)'}),
        animate(
          `${SUBMENU_ANIMATION_TIME}ms cubic-bezier(.45, .05, .35, .95)`,
          style({opacity: 0, transform: 'translateX(-12px)'}),
        ),
      ]),
    ]),
  ],
})
export class SidebarSubmenuComponent {
  @Input({required: true}) subMenu: SidebarSubMenuItem[];

  constructor(private layout: LayoutService) {}

  get isCompact(): boolean {
    return this.layout.isCompactLayout;
  }
}
