<div>
  You are logged in as a delegated admin for <strong>{{ supportSession.support_user_name }}</strong
  >. All your actions will be logged. Your session will end
  <strong><w-date-time-label format="fromNow" [date]="durationTimeAgo" /></strong>.
</div>
<w-page-banner-button
  *ngIf="supportSession.duration < supportSession.max_duration"
  type="error"
  href="/users/support_session_extend"
  [nativeNavigation]="true"
  >Extend session</w-page-banner-button
>
<w-page-banner-button type="notice" wLogoutButton>End now</w-page-banner-button>
