import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

import {PageSpinnerService} from '../../services/page-spinner.service';
import {SpinnerComponent} from '../spinner/spinner.component';

@Component({
  selector: 'w-page-spinner',
  templateUrl: './page-spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
})
export class PageSpinnerComponent {
  constructor(public pageSpinner: PageSpinnerService) {}
}
