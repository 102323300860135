import {noop} from 'lodash';
import {Injectable} from '@angular/core';

import {Log} from '../log';

import {LocalStorageAdapter} from './adapters/local-storage-adapter';
import {WindowNameAdapter} from './adapters/window-name-adapter';

interface Store {
  setItem(key: string, val: any): void;
  getItem(key: string): any;
  removeItem(key: string): any;
  keys(): string[];
}

export type Adapter = Store;

export interface LocalStoreAccessor<T> {
  value: T;
}

export interface QuotaExceededException extends DOMException {
  name: 'QuotaExceededError' | 'NS_ERROR_DOM_QUOTA_REACHED';
  code: 22 | 1014;
}

const UNDEFINED_VALUE = Symbol('UNDEFINED_VALUE');

@Injectable({
  providedIn: 'root',
})
export class LocalStore implements Store {
  isAdapterUnavailable = false;

  private testKey = '__test__';
  private adapters = [LocalStorageAdapter, WindowNameAdapter];
  private adapter: Adapter;

  constructor(private log: Log) {
    const adapter = this.findWorkingAdapter();

    if (adapter) {
      this.adapter = adapter;
    } else {
      this.adapter = {
        getItem: noop,
        setItem: noop,
        removeItem: noop,
        keys: () => [],
      };
      this.isAdapterUnavailable = true;
      this.log.warn("LocalStore service couldn't find any working adapter. All method calls will be noop");
    }
  }

  getItem<T = any>(key: string): T | null {
    return this.adapter.getItem(key);
  }

  setItem<T = any>(key: string, val: T) {
    this.adapter.setItem(key, val);
  }

  removeItem<T = any>(key: string): T {
    return this.adapter.removeItem(key);
  }

  createAccessor<T>(key: string, defaultValue: T): LocalStoreAccessor<T> {
    const self = this;
    let cachedValue: T | typeof UNDEFINED_VALUE = UNDEFINED_VALUE;

    return {
      get value(): T {
        if (cachedValue === UNDEFINED_VALUE) {
          cachedValue = self.getItem(key) ?? defaultValue;
        }

        return cachedValue as T;
      },

      set value(val: T) {
        cachedValue = val;
        self.setItem(key, val);
      },
    };
  }

  keys(): string[] {
    return this.adapter.keys();
  }

  private findWorkingAdapter(): Adapter | undefined {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    for (const AdapterConstructor of this.adapters) {
      try {
        const adapter = new AdapterConstructor();

        if (this.testAdapter(adapter)) {
          return adapter;
        }
      } catch {
        // Ignoring the error and trying next adapter
      }
    }
  }

  private testAdapter(adapter: Adapter): boolean {
    const {testKey} = this;

    adapter.setItem(testKey, 1);

    if (adapter.getItem(testKey) !== 1) {
      return false;
    }

    adapter.removeItem(testKey);

    if (adapter.getItem(testKey) !== undefined) {
      return false;
    }

    return true;
  }
}

export function isQuotaExceededException(e: unknown): e is QuotaExceededException {
  return (
    e instanceof DOMException &&
    // everything except Firefox
    (e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      /*
       * `code` might not be present in some cases, so we need to test `name` as well.
       * Note that Firefox uses different name than other browsers (NS_ERROR_DOM_QUOTA_REACHED)
       */
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
  );
}
