import {isPlainObject} from 'lodash';

interface Options {
  animate?: boolean;
  margin?: number;
  stickySelectors?: string[];
}

interface Rect {
  top: number;
  bottom: number;
}

export function scrollElementIntoView(element: Element, opts?: Options): void;
export function scrollElementIntoView(container: Element, element?: Element | null, opts?: Options): void;

export function scrollElementIntoView(container: Element, element?: Element | Options | null, opts?: Options) {
  if (!container) {
    return;
  }

  if (arguments.length < 2 || isPlainObject(element)) {
    opts = element as Options;
    scrollWithinViewport(container, opts);
  } else {
    if (!element) {
      return;
    }

    scrollWithinContainer(container, element as Element, opts);
  }
}

function scrollWithinContainer(container: Element, element: Element, opts?: Options) {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const scrollDelta = getScrollDelta(containerRect, elementRect, opts);

  if (scrollDelta) {
    scrollElement(container, scrollDelta, opts);
  }
}

function scrollWithinViewport(element: Element, opts?: Options) {
  const elementRect = element.getBoundingClientRect();
  const stickyElementBottomEdges = (opts?.stickySelectors ?? ['.page-top-sticky-area']).map(
    s => document.querySelector(s)?.getBoundingClientRect().bottom ?? 0,
  );
  const scrollDelta = getScrollDelta(
    {
      top: Math.max(0, ...stickyElementBottomEdges),
      bottom: window.innerHeight,
    },
    elementRect,
    opts,
  );

  if (scrollDelta) {
    scrollElement(document.documentElement, scrollDelta, opts);
  }
}

function getScrollDelta(container: Rect, element: Rect, opts: Options = {}): number {
  let margin = opts.margin || 0;
  let scrollDelta = 0;

  if (element.top < container.top) {
    scrollDelta = element.top - container.top;
    // Margin should increase negative value
    margin *= -1;
  } else if (element.bottom > container.bottom) {
    const bottomDelta = element.bottom - container.bottom;
    const topDelta = element.top - container.top;

    // Handles the case when element's height is greater than container's height
    if (topDelta < bottomDelta) {
      scrollDelta = topDelta;
      margin *= -1;
    } else {
      scrollDelta = bottomDelta;
    }
  }

  if (scrollDelta) {
    return scrollDelta + margin;
  } else {
    return scrollDelta;
  }
}

function scrollElement(element: Element, scrollDelta: number, opts: Options = {}) {
  if (!TEST && opts.animate && 'scrollBehavior' in document.documentElement.style) {
    element.scrollTo({
      left: 0,
      top: element.scrollTop + scrollDelta,
      behavior: 'smooth',
    });
  } else {
    element.scrollTop += scrollDelta;
  }
}
