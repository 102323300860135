import {NgModule} from '@angular/core';

import {PluralPipe} from '@shared/pipes/plural.pipe';

@NgModule({
  imports: [PluralPipe],
  providers: [PluralPipe],
  exports: [PluralPipe],
})
export class PluralModule {}
