import {Directive, EventEmitter, OnDestroy, Output} from '@angular/core';

@Directive({
  selector: '[wOnDestroy]',
})
export class OnDestroyDirective implements OnDestroy {
  @Output('wOnDestroy') onDestroy = new EventEmitter<void>();

  ngOnDestroy() {
    this.onDestroy.emit();
  }
}
