import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import adminActiveIcon from '@icons/sidebar/admin-active.svg';
import adminIcon from '@icons/sidebar/admin.svg';
import apiActiveIcon from '@icons/sidebar/api-active.svg';
import apiIcon from '@icons/sidebar/api.svg';
import commonDataModelsActiveIcon from '@icons/sidebar/common-data-models-active.svg';
import commonDataModelsIcon from '@icons/sidebar/common-data-models.svg';
import communityActiveIcon from '@icons/sidebar/community-active.svg';
import communityIcon from '@icons/sidebar/community.svg';
import operationsHubActiveIcon from '@icons/sidebar/operations-hub-active.svg';
import operationsHubIcon from '@icons/sidebar/operations-hub.svg';
import integrationAppsActiveIcon from '@icons/sidebar/integration-apps-active.svg';
import integrationAppsIcon from '@icons/sidebar/integration-apps.svg';
import logoutIcon from '@icons/sidebar/logout.svg';
import lookupTablesActiveIcon from '@icons/sidebar/lookup-tables-active.svg';
import lookupTablesIcon from '@icons/sidebar/lookup-tables.svg';
import manageAppsActiveIcon from '@icons/sidebar/manage-apps-active.svg';
import manageAppsIcon from '@icons/sidebar/manage-apps.svg';
import manageCustomersActiveIcon from '@icons/sidebar/manage-customers-active.svg';
import manageCustomersIcon from '@icons/sidebar/manage-customers.svg';
import unsupportedConnectorsActiveIcon from '@icons/sidebar/managed-unsupported-connectors-active.svg';
import unsupportedConnectorsIcon from '@icons/sidebar/managed-unsupported-connectors.svg';
import oauthProfileActiveIcon from '@icons/sidebar/oauth-profile-active.svg';
import oauthProfileIcon from '@icons/sidebar/oauth-profile.svg';
import fileStorageIcon from '@icons/sidebar/file-storage.svg';
import fileStorageActiveIcon from '@icons/sidebar/file-storage-active.svg';
import onpremGroupsActiveIcon from '@icons/sidebar/onprem-groups-active.svg';
import onpremGroupsIcon from '@icons/sidebar/onprem-groups.svg';
import packagesActiveIcon from '@icons/sidebar/packages-active.svg';
import packagesIcon from '@icons/sidebar/packages.svg';
import peopleTasksActiveIcon from '@icons/sidebar/people-tasks-active.svg';
import peopleTasksIcon from '@icons/sidebar/people-tasks.svg';
import projectsActiveIcon from '@icons/sidebar/projects-active.svg';
import projectsIcon from '@icons/sidebar/projects.svg';
import propertiesActiveIcon from '@icons/sidebar/properties-active.svg';
import propertiesIcon from '@icons/sidebar/properties.svg';
import runtimeConnectionsActiveIcon from '@icons/sidebar/runtime-connections-active.svg';
import runtimeConnectionsIcon from '@icons/sidebar/runtime-connections.svg';
import sdkActiveIcon from '@icons/sidebar/sdk-active.svg';
import sdkCommunityAdminActiveIcon from '@icons/sidebar/sdk-community-admin-active.svg';
import sdkCommunityAdminIcon from '@icons/sidebar/sdk-community-admin.svg';
import sdkIcon from '@icons/sidebar/sdk.svg';
import settingsActiveIcon from '@icons/sidebar/settings-active.svg';
import settingsIcon from '@icons/sidebar/settings.svg';
import teamsActiveIcon from '@icons/sidebar/teams-active.svg';
import teamsIcon from '@icons/sidebar/teams.svg';
import templatesActiveIcon from '@icons/sidebar/templates-active.svg';
import templatesIcon from '@icons/sidebar/templates.svg';
import toolsActiveIcon from '@icons/sidebar/tools-active.svg';
import toolsIcon from '@icons/sidebar/tools.svg';
import topicsActiveIcon from '@icons/sidebar/topics-active.svg';
import topicsIcon from '@icons/sidebar/topics.svg';
import workbotsActiveIcon from '@icons/sidebar/workbots-active.svg';
import workbotsIcon from '@icons/sidebar/workbots.svg';
import logsActiveIcon from '@icons/sidebar/logs-active.svg';
import logsIcon from '@icons/sidebar/logs.svg';
import organizationConsole from '@icons/sidebar/organization-console.svg';
import organizationConsoleActive from '@icons/sidebar/organization-console-active.svg';
import lcapPortalIcon from '@icons/sidebar/lcap-portal.svg';
import lcapPortalActiveIcon from '@icons/sidebar/lcap-portal-active.svg';
import platformIcon from '@icons/sidebar/platform.svg';
import platformActiveIcon from '@icons/sidebar/platform-active.svg';
import usageDashboardIcon from '@icons/sidebar/usage-dashboard.svg';
import usageDashboardActiveIcon from '@icons/sidebar/usage-dashboard-active.svg';
import usageInsightsIcon from '@icons/sidebar/usage-insights.svg';
import usageInsightsActiveIcon from '@icons/sidebar/usage-insights-active.svg';

import {SidebarIconType} from '../sidebar.types';

const ICONS_MAP = new Map<SidebarIconType, Record<'active' | 'default', SvgIcon['id']>>([
  ['admin', {active: adminActiveIcon.id, default: adminIcon.id}],
  ['projects', {active: projectsActiveIcon.id, default: projectsIcon.id}],
  ['operations-hub', {active: operationsHubActiveIcon.id, default: operationsHubIcon.id}],
  ['community', {active: communityActiveIcon.id, default: communityIcon.id}],
  ['tools', {active: toolsActiveIcon.id, default: toolsIcon.id}],
  ['team', {active: teamsActiveIcon.id, default: teamsIcon.id}],
  ['service_console', {active: apiActiveIcon.id, default: apiIcon.id}],
  ['settings', {active: settingsActiveIcon.id, default: settingsIcon.id}],
  ['logout', {active: logoutIcon.id, default: logoutIcon.id}],
  ['workato_schema', {active: commonDataModelsActiveIcon.id, default: commonDataModelsIcon.id}],
  ['lookup_table', {active: lookupTablesActiveIcon.id, default: lookupTablesIcon.id}],
  ['custom_adapter_sdk', {active: sdkActiveIcon.id, default: sdkIcon.id}],
  ['custom_oauth_key', {active: oauthProfileActiveIcon.id, default: oauthProfileIcon.id}],
  ['file_storage', {active: fileStorageActiveIcon.id, default: fileStorageIcon.id}],
  ['installed_integration_apps', {active: integrationAppsActiveIcon.id, default: integrationAppsIcon.id}],
  ['workato_template', {active: templatesActiveIcon.id, default: templatesIcon.id}],
  ['opa', {active: onpremGroupsActiveIcon.id, default: onpremGroupsIcon.id}],
  ['people_task', {active: peopleTasksActiveIcon.id, default: peopleTasksIcon.id}],
  ['account_property', {active: propertiesActiveIcon.id, default: propertiesIcon.id}],
  ['topic', {active: topicsActiveIcon.id, default: topicsIcon.id}],
  ['package', {active: packagesActiveIcon.id, default: packagesIcon.id}],
  ['workbot', {active: workbotsActiveIcon.id, default: workbotsIcon.id}],
  ['sdk_community_admin', {active: sdkCommunityAdminActiveIcon.id, default: sdkCommunityAdminIcon.id}],
  ['manage_apps', {active: manageAppsActiveIcon.id, default: manageAppsIcon.id}],
  ['manage_customers', {active: manageCustomersActiveIcon.id, default: manageCustomersIcon.id}],
  ['unsupported_connectors', {active: unsupportedConnectorsActiveIcon.id, default: unsupportedConnectorsIcon.id}],
  ['runtime_connections', {active: runtimeConnectionsActiveIcon.id, default: runtimeConnectionsIcon.id}],
  ['logging_service', {active: logsActiveIcon.id, default: logsIcon.id}],
  ['organization-console', {active: organizationConsoleActive.id, default: organizationConsole.id}],
  ['lcap_portal', {active: lcapPortalActiveIcon.id, default: lcapPortalIcon.id}],
  ['platform', {active: platformActiveIcon.id, default: platformIcon.id}],
  ['usage_dashboard', {active: usageDashboardActiveIcon.id, default: usageDashboardIcon.id}],
  ['usage_insights', {active: usageInsightsActiveIcon.id, default: usageInsightsIcon.id}],
]);

@Component({
  selector: 'w-sidebar-icon',
  templateUrl: './sidebar-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarIconComponent {
  @Input({required: true}) iconId: SidebarIconType;
  @Input() isActive = false;

  get icon(): SvgIcon['id'] | undefined {
    const icons = ICONS_MAP.get(this.iconId);

    return this.isActive ? icons?.active : icons?.default;
  }
}
