import {MultiSelectFieldOption} from '../../modules/form-fields/form-field.types';
import {CustomReportSchema} from '../../modules/job-history/custom-report/custom-report.service';
import {
  ConditionsConfig,
  CopyableValue,
  JoinedSchemas,
  OutputSchemaField,
  RawOperation,
  TreeSelectValue,
} from '../index';
import {FieldQualifiedPath} from '../../modules/form-fields/field-helper.service';

import {SchemaField} from './form-fields';

export type StepKeyword =
  | 'action'
  | 'trigger'
  | 'foreach'
  | 'if'
  | 'elsif'
  | 'else'
  | 'try'
  | 'catch'
  | 'stop'
  | 'repeat'
  | 'while_condition';

export type StepIconType = StepKeyword | 'parameters' | 'repeat-while' | 'add-action' | 'recipe-function';

export interface StepInput {
  input?: FieldsInput;
  // trigger and catch steps keys
  filter?: FieldsInput;
  // foreach keys
  source?: FieldValue;
  repeat_mode?: FieldValue;
  batch_size?: FieldValue;
  clear_scope?: FieldValue;
  // config params keys
  param?: FieldValue;
  // action step keys
  external_input_definition?: FieldsInput;
}

export type StepSketchType = 'review' | 'configuring' | false;
export type RecipeAiChatBuildResultChange = 'configured' | 'generated' | 'commented' | 'updated' | 'undo';

export interface Step<TChild extends Step<TChild>> {
  number: number;
  keyword: StepKeyword;

  /**
   * Name of selected application
   * Is only set for triggers and actions
   * e.g. GitHub
   */
  provider?: string;

  /**
   * Unique name of performed operation
   * Is only set for triggers and actions
   * e.g. create_issue
   */
  name?: string;

  /**
   * Display name of performed operation
   * Is only set for triggers and actions
   * e.g. Create issue
   */
  title?: string;

  /**
   * Comment
   * @deprecated
   */
  custom_title?: string;
  comment?: string;

  /**
   * Unique string ID
   * e.g. b59f7bf2-55b2-4cd1-838b-c30c6a30fc67
   */
  uuid?: string;
  skip?: boolean;

  /**
   * A collection of conditions used for if/if-else step
   * Also used for trigger filter, allowing job
   * to be not executed if condition is fulfilled
   */
  filter?: ConditionsConfig;

  /**
   * Contains values filled in or selected by user in step config
   */
  input?: FieldsInput;

  /**
   * Indicates a step which has enabled dynamic input mapping from source step(s).
   * The mapping rules are stored and configured externally.
   */
  external_input_definition?: {
    enabled: boolean;
    sources: StepUuid[];
    protected_fields: FieldQualifiedPath[];
  };

  /**
   * Used to set repeat mode in foreach step
   */
  repeat_mode?: 'simple' | 'batch';

  /**
   * Source array/collection reference, used in foreach step
   */
  source?: string;
  block?: TChild[];
  config?: RawOperation;

  /**
   * A unique string ID for steps with outputs
   * Used to reference step data in pills (e.g. 26cddd66)
   */
  as?: string;
}

export interface RecipeStep extends Step<RecipeStep> {
  /**
   * Static schema, describing
   * fields rendered in step config (input)
   * and resulting step data (output)
   */
  schema?: {
    input?: SchemaField[];
    output?: OutputSchemaField[];
  };

  /**
   * Additional input schema, built and fetched from the backend using current config values
   * Is merged with the static input schema
   */
  extended_input_schema?: SchemaField[];

  /**
   * Additional output schema, built and fetched from the backend using current config values
   * Is merged with the static output schema
   */
  extended_output_schema?: OutputSchemaField[];

  /**
   * Joined schema is a dynamically appended child schema of the output schema field.
   * Each output schema field may define an extension point (or join point) using `join_point` or `fetch_with` properties.
   * Such fields, instead of defining their children beforehand, allow to load and append them in the runtime.
   * Joined schema may either be loaded with a backend request or reference available static schema (from a different provider or operation)
   * The loading happens on demand: when user expands the pill in the data-tree or when such pill is validated.
   * The `joined_schemas` field is used to cache the loaded joined schemas. It is removed before saving recipe to backend.
   */
  joined_schemas?: JoinedSchemas;

  /**
   * Contains values filled in or selected by user in trigger parameters
   * Parameters are shown as a separate "fake" step with a config
   */
  param?: FieldsInput;

  /**
   * JSON with trigger parameters' schema fields
   * Parsed type is SchemaField[]
   */
  parameters_schema?: string;

  help?: string | RecipeStepHelp;

  /**
   * Step title, shown in the canvas. May contain HTML for highlighting
   */
  description?: string;

  /**
   * Number of items in the chunk for batched foreach step
   */
  batch_size?: string;

  /**
   * Used in foreach step
   */
  clear_scope?: 'true' | 'false';

  /**
   * List of hidden by default optional field paths,
   * which user purposefully toggled as shown in input config
   * For nested fields field path is joined by dot,
   * e.g. { 'object.child': true }
   */
  visible_config_fields?: string[];

  /**
   * List of shown by default (sticky) optional field paths,
   * which user purposefully hid from input config
   * For nested fields field path is joined by dot,
   * e.g. { 'object.child': true }
   */
  hidden_config_fields?: string[];

  /**
   * Map between toggle fields path and flag saying whether primary (true) or secondary (false) subfield is toggled
   * Used to cache toggle fields state
   * For nested fields field path is joined by dot,
   * e.g. { 'object.child': true }
   */
  toggleCfg?: {
    [fieldPathString: string]: boolean;
  };

  /**
   * Map between fields path and selected picklist value's label
   * Used to store the label to be later shown without the need to load the label from the backend
   * For nested fields field path is joined by dot,
   * e.g. { 'object.child': true }
   */
  dynamicPickListSelection?: {
    [fieldPathString: string]: string | TreeSelectValue | TreeSelectValue[] | MultiSelectFieldOption[];
  };

  /**
   * Used to store required parts of extended schema when cloning someone else's recipe
   * Is needed because after the copy the recipe may not have required connection to reload the schema
   */
  requirements?: {
    extended_input_schema?: SchemaField[];
  };

  /**
   * Schema for job table customization
   * Contains titles and names of additional table columns
   */
  job_report_schema?: CustomReportSchema[];

  /**
   * Input for job table customization
   * Contains data for additional table columns
   */
  job_report_config?: FieldsInput;
  wizardFinished?: boolean;
  collapsed?: boolean;
  /**
   * Indicates that the step contains sensitive information that we shouldn't store in AWS S3 or include in our logs
   */
  mask_data?: boolean;

  /**
   * Whether an empty state with an invite to use copilot chat is shown
   */
  unfinished?: boolean;

  /**
   * Indicates the version of migration lastly done to this recipe
   * Set on the UI only. Might be used for analytics.
   */
  format_version?: RecipeStepFormatVersion;

  /**
   * Indicates that the step is a sketch
   * UI-only property, sketch steps are created by Recipe Copilot
   */
  sketch?: StepSketchType | 'configuring_comment';
}

export type StepUuid = NonNullable<RecipeStep['uuid']>;

export type StepAlias = NonNullable<RecipeStep['as']>;

export type TriggerType = 'unknown' | 'polling' | 'realtime' | 'mixed';

export interface FieldsInput {
  [fieldName: string]: FieldValue;
}

export type FieldValue = any;

export interface RecipeStepWithAction extends RecipeStep {
  provider: NonNullable<RecipeStep['provider']>;
  name: NonNullable<RecipeStep['name']>;
}

export interface RecipeStepHelp extends CopyableValue {
  body: string;
  learn_more_url?: string;
  learn_more_text?: string;
}

export type StepLabelType =
  | 'moved-up'
  | 'moved-down'
  | 'nested'
  | 'unnested'
  | 'skipped'
  | 'unskipped'
  | 'updated'
  | 'input-review'
  | 'masked';

export interface StepLabels<TLabelType extends StepLabelType = StepLabelType> {
  types: TLabelType[];
  additionalText: string | null;
}

export type StepLabelsColorScheme = 'error' | 'mocked' | 'default' | 'suggestion';

export enum RecipeStepFormatVersion {
  /** Initial migration from pills 1.0 to pills 2.0 */
  PILL_V2_MIGRATION = 2,
}
