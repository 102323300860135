import _ from 'lodash';
import {Subject} from 'rxjs';
import sha1 from 'hash.js/lib/hash/sha/1';
import {Injectable} from '@angular/core';

import {Connections} from '../pages/connections/connections.service';
import {TreeSelectItem} from '../modules/tree-select/tree-select-item-node';
import {
  Adapter,
  FieldWithAsyncPicklist,
  FieldWithPicklist,
  FieldWithStaticPicklist,
  FieldWithStepPicklist,
  FieldsInput,
  LOADING_INDICATOR,
  LegacyPicklist,
  LoadingIndicator,
  Picklist,
  PicklistFor,
  PicklistItem,
  PicklistOptions,
  PicklistRegistry,
  Recipe,
  RecipeStep,
  SchemaField,
  SelectFieldOption,
  SelectLikeField,
  TreePicklist,
} from '../types';
import {Connection} from '../pages/connections/connections.types';
import {FieldHelper} from '../modules/form-fields/field-helper.service';

import {SchemaHelper} from './schema-helper';

type ProviderPicklistRegistry = Map<string, OperationPicklistRegistry>;

type OperationPicklistRegistry = Map<string, Picklist | TreePicklist | LoadPicklistFn>;

type LoadPicklistFn = (
  connection?: Connection,
  stepInput?: object,
  extraParams?: object,
  recipeId?: Recipe['id'],
  ownersView?: boolean,
) => Promise<Picklist | TreePicklist | null>;

export interface RequestSuccessEvent {
  provider: string;
  field: string;
}

export interface RequestFailEvent {
  provider: string;
  field: string;
  error: string;
}

export interface PicklistRegisterOptions {
  staging?: boolean;
}

export interface PicklistRecipeContext {
  recipe: Recipe;
  rootStep: RecipeStep;
  ownersView: boolean;
}

export interface PicklistQueryOptions {
  ownersView?: boolean;
  recipeId?: Recipe['id'];
  extraParams?: object;
  staging?: boolean;
}

const CONST_PARAM_RE = /^"([^"]+)"$/;

@Injectable({
  providedIn: 'root',
})
export class PicklistService {
  requestSuccess = new Subject<RequestSuccessEvent>();
  requestFail = new Subject<RequestFailEvent>();

  private registry = new Map<string, ProviderPicklistRegistry>();
  private picklistCache = new Map<string, Promise<Picklist | TreePicklist | null>>();

  constructor(
    private connections: Connections,
    private fieldHelper: FieldHelper,
    private schemaHelper: SchemaHelper,
  ) {}

  register(adapter: Adapter, operationName: string, fields: SchemaField[], options: PicklistRegisterOptions = {}) {
    this.schemaHelper.forEachField(
      fields,
      (field, context) => {
        if (this.hasPicklist(field)) {
          this.registerFor(field, context.parents, adapter, operationName, options);
        }
      },
      {includeToggleField: true, includeKeyValue: true},
    );
  }

  resetForAdapter(adapter: Adapter) {
    if (this.registry.has(adapter.name)) {
      this.clearCache();
      this.registry.get(adapter.name)!.clear();
    }
  }

  clearCache() {
    this.picklistCache.clear();
  }

  getPicklist<TField extends SelectLikeField>(
    field: TField,
    registry?: PicklistRegistry,
  ): PicklistFor<TField['control_type']> | null {
    if (!registry) {
      return null;
    }

    const picklistPath = this.fieldHelper.getPicklistPath(field);

    return (_.get(registry, picklistPath) || null) as PicklistFor<TField['control_type']> | null;
  }

  getPicklistParams(field: FieldWithAsyncPicklist, stepInput: FieldsInput): object {
    const params = {...field.pick_list_params, ...field.pick_list_unlinked_params};

    return _.mapValues(params, value =>
      CONST_PARAM_RE.test(value) ? value.replace(CONST_PARAM_RE, '$1') : _.get(stepInput, value),
    );
  }

  loadPicklist(
    step: RecipeStep,
    picklistPath: string,
    connectionId: number | undefined,
    recipeId: Recipe['id'] | undefined,
    ownersView: boolean | undefined,
    extraParams: {__parent_id: TreeSelectItem['id']},
  ): Promise<TreePicklist | null> | TreePicklist;

  loadPicklist(
    step: RecipeStep,
    picklistPath: string,
    connectionId?: number,
    recipeId?: Recipe['id'],
    ownersView?: boolean,
    extraParams?: object,
  ): Promise<Picklist | null> | Picklist;

  loadPicklist(
    step: RecipeStep,
    picklistPath: string,
    connectionId?: number,
    recipeId?: Recipe['id'],
    ownersView?: boolean,
    extraParams?: object,
  ): Promise<Picklist | TreePicklist | null> | Picklist | TreePicklist {
    let connection;

    if (!step.provider || !step.name) {
      return [];
    }

    if (this.connections.connected(connectionId)) {
      connection = this.connections.find(connectionId);
    }

    const picklist = this.getPicklistRegistry(step.provider, step.name).get(picklistPath) ?? [];

    return _.isFunction(picklist) ? picklist(connection, step.input, extraParams, recipeId, ownersView) : picklist;
  }

  loadAsyncPicklistFor(
    field: FieldWithAsyncPicklist,
    adapter: Adapter,
    connection: Connection | undefined,
    stepInput: object | undefined,
    options: PicklistQueryOptions = {},
  ): Promise<Picklist | TreePicklist | null> {
    if (options.ownersView === false) {
      return Promise.resolve([]);
    }

    if (
      !connection &&
      !field.pick_list_connection_less &&
      (!_.isEmpty(adapter?.config?.input) || adapter?.config?.oauth === true)
    ) {
      return Promise.reject([]);
    }

    if (connection && connection.authorization_status !== 'success') {
      return Promise.reject([]);
    }

    const id = connection ? connection.id : adapter.name;
    const params = {
      id,
      field,
      flow_id: options.recipeId,
      staging: options.staging,
      pick_list_params: {
        ...this.getPicklistParams(field, (stepInput || {}) as FieldsInput),
        ...options.extraParams,
      },
    };

    const cacheKey = this.getCacheKey(params);

    if (this.picklistCache.has(cacheKey)) {
      return this.picklistCache.get(cacheKey)!;
    }

    const picklistPromise = this.connections.loadPicklist(params).then(
      picklist => {
        // store the return value in the cache
        this.requestSuccess.next({
          field: field.name,
          provider: adapter.name,
        });

        return this.isLegacyPicklist(picklist) ? picklist.map(title => [title, title] as PicklistItem) : picklist;
      },
      error => {
        // Assume that the error is transient and clear the cache key in order to enable subsequent requests
        this.picklistCache.delete(cacheKey);
        this.requestFail.next({
          provider: adapter.name,
          field: field.name,
          // We don't display the details about the server errors
          error: error.system ? null : error.message,
        });

        return Promise.reject([]);
      },
    );

    this.picklistCache.set(cacheKey, picklistPromise);

    return picklistPromise;
  }

  isLegacyPicklist(picklist: unknown): picklist is LegacyPicklist {
    return Array.isArray(picklist) && picklist.every(item => typeof item === 'string');
  }

  isPicklistLoaded<TPicklistType extends PicklistOptions | LegacyPicklist | SelectFieldOption[] | null>(
    picklist: PicklistOptions | LegacyPicklist | SelectFieldOption[] | null,
  ): picklist is Exclude<TPicklistType, LoadingIndicator | null> {
    return Boolean(picklist && picklist !== LOADING_INDICATOR);
  }

  hasPicklist(field: SchemaField): field is FieldWithPicklist {
    return this.fieldHelper.isSelectLikeField(field) && Boolean((field as FieldWithPicklist).pick_list);
  }

  hasStaticPicklist(field: SchemaField): field is FieldWithStaticPicklist {
    return Array.isArray((field as FieldWithStaticPicklist).pick_list);
  }

  hasAsyncPicklist(field: SchemaField): field is FieldWithAsyncPicklist {
    return typeof (field as FieldWithAsyncPicklist).pick_list === 'string' && !this.hasStepPicklist(field);
  }

  hasStepPicklist(field: SchemaField): field is FieldWithStepPicklist {
    const {pick_list} = field as FieldWithStepPicklist;

    return typeof pick_list === 'string' && pick_list.startsWith('$$');
  }

  hasDynamicPicklist(field: SchemaField): field is FieldWithAsyncPicklist | FieldWithStepPicklist {
    return this.hasAsyncPicklist(field) || this.hasStepPicklist(field);
  }

  hasPicklistParam(field: SchemaField, param: string): boolean {
    if (!this.hasAsyncPicklist(field)) {
      return false;
    }

    const {pick_list_params, pick_list_unlinked_params} = field;

    return Boolean(pick_list_params?.hasOwnProperty(param) || pick_list_unlinked_params?.hasOwnProperty(param));
  }

  areOptionsLoaded(field: SelectLikeField, registry?: PicklistRegistry): boolean {
    if (this.fieldHelper.isFieldWithOptions(field) && field.options?.length) {
      // static picklists options are always available instantly
      return true;
    }

    const picklist = this.getPicklist(field, registry);

    // non-static picklist exists (is not null) and is not being loaded
    return this.isPicklistLoaded(picklist);
  }

  private registerFor(
    field: SchemaField,
    parents: SchemaField[],
    adapter: Adapter,
    operationName: string,
    options: PicklistRegisterOptions,
  ) {
    const registry = this.getPicklistRegistry(adapter.name, operationName);
    const qualifiedPath = _.compact([...parents, field].map(({name}) => name));
    const pathString = this.fieldHelper.getPicklistPathFromQualifiedPath(qualifiedPath);

    if (this.hasStaticPicklist(field)) {
      registry.set(pathString, field.pick_list);
    } else if (this.hasAsyncPicklist(field)) {
      registry.set(pathString, (connection, stepInput, extraParams, recipeId, ownersView) =>
        this.loadAsyncPicklistFor(field, adapter, connection, stepInput, {
          extraParams,
          recipeId,
          ownersView,
          staging: options.staging,
        }),
      );
    }
  }

  private getPicklistRegistry(providerName: string, operationName: string): OperationPicklistRegistry {
    if (!this.registry.has(providerName)) {
      this.registry.set(providerName, new Map());
    }

    const providerRegistry = this.registry.get(providerName)!;

    if (!providerRegistry.has(operationName)) {
      providerRegistry.set(operationName, new Map());
    }

    return providerRegistry.get(operationName)!;
  }

  private getCacheKey(item: object): string {
    return sha1().update(JSON.stringify(item)).digest('hex');
  }
}
