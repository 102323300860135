import {Pipe, PipeTransform} from '@angular/core';

import {normalizeSpaces} from '../utils/normalize-spaces';

@Pipe({
  name: 'wNormalizeSpaces',
})
export class NormalizeSpacesPipe implements PipeTransform {
  transform(str: string): string {
    return normalizeSpaces(str);
  }
}
