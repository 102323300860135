import _ from 'lodash';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {inject} from '@angular/core';

import {AuthUser} from '../services/auth-user';
import {ServicePagesService} from '../services/service-pages.service';
import {RouteConfigData} from '../types';

export const restrictedRouteGuard: CanActivateFn | CanActivateChildFn = (route: ActivatedRouteSnapshot) => {
  if (!passesRequirements(route)) {
    inject(ServicePagesService).showNotFoundPage();

    return false;
  }

  return true;
};

function passesRequirements(route: Route | ActivatedRouteSnapshot): boolean {
  if (!route.data) {
    return true;
  }

  let allowed = true;
  const authUser = inject(AuthUser);
  const requirements = (route.data as RouteConfigData).requirements;

  if (requirements?.roles) {
    allowed &&= _.castArray(requirements.roles).every(role => authUser.hasRole(role));
  }

  if (requirements?.privileges) {
    allowed &&= authUser.hasPrivilege(requirements.privileges);
  }

  return allowed;
}

export const redirectToFirstUnrestrictedSiblingGuard: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const siblingRoutes = (route.parent?.routeConfig?.children || []).filter(
    child => child.component || child.children?.length,
  );
  let index = 0;
  let siblingRoute: Route;

  do {
    siblingRoute = siblingRoutes[index];
    index++;
  } while (siblingRoute && !passesRequirements(siblingRoute));

  return siblingRoute ? inject(Router).createUrlTree([state.url, siblingRoute.path]) : false;
};
