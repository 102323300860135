<w-header-link
  [href]="href"
  [type]="type"
  [routerUrl]="routerUrl"
  [alsoActiveFor]="alsoActiveFor"
  [mixpanel]="mixpanel"
  [mixpanelOptions]="mixpanelOptions"
>
  <ng-content />
</w-header-link>
