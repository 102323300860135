import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource} from '@shared/services/http-resource';

import {BillingCard} from '../types';
import {PROFILE_IS_BACK_OFFICE} from '../modules/profile/profile-utils';

import {AuthUser} from './auth-user';
import {OemAccount, UserProfile} from './profile.types';

interface ProfileProperties {
  show_people_tasks_onboarding?: boolean;
}

interface ProfileMembershipProperties {
  oem_welcome_shown?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private resource: HttpResource;
  private backofficeResource: HttpResource;
  private sharedActionsResource: HttpResource;

  constructor(
    private http: HttpClient,
    private authUser: AuthUser,
    @Optional() @Inject(PROFILE_IS_BACK_OFFICE) private isBackoffice = false,
  ) {
    this.resource = new HttpResource(this.http, {
      url: '/profiles/{{id}}/{{action}}.json',
    });
    this.backofficeResource = new HttpResource(this.http, {
      url: '/web_api/backoffice/admin/{{id}}/{{action}}.json',
    });
    this.sharedActionsResource = this.isBackoffice ? this.backofficeResource : this.resource;
  }

  async get(id: UserProfile['id']): Promise<UserProfile> {
    return this.sharedActionsResource.get({id});
  }

  async updateStripeToken(token: string): Promise<BillingCard> {
    const response = await this.resource.put(
      {id: this.authUser.id, action: 'update_stripe_token'},
      {stripe_token: token},
    );

    return response.data;
  }

  async updateProperties(props: ProfileProperties): Promise<void> {
    if (!this.authUser.hasPrivilege('workspace_settings')) {
      return;
    }

    return this.resource.put({id: this.authUser.id, action: 'properties_update'}, {profile: props});
  }

  async updateMembershipProperties(props: ProfileMembershipProperties): Promise<void> {
    return this.resource.put({id: this.authUser.id, action: 'membership_properties_update'}, {profile: props});
  }

  async getOemAccount(id: UserProfile['id']): Promise<OemAccount> {
    return this.resource.get({id, action: 'admin_oem'});
  }

  async updateOemAccount(id: UserProfile['id'], account: OemAccount): Promise<OemAccount> {
    return this.resource.update({id, action: 'admin_oem'}, {account});
  }
}
