import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

/**
 * Adds `X-Requested-With` header to all requests as Rails uses this header to identify XHR requests.
 */
@Injectable()
export class XhrRequestedWithInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(
      request.clone({
        headers: request.headers.append('X-Requested-With', 'XMLHttpRequest'),
      }),
    );
  }
}
