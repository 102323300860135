import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {HtmlSanitizerConfig, HtmlSanitizerService} from '../services/html-sanitizer.service';

@Pipe({
  name: 'wSanitizeHtml',
  standalone: true,
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(
    private htmlSanitizer: HtmlSanitizerService,
    private ngSanitizer: DomSanitizer,
  ) {}

  transform(html: string | null | undefined, config?: HtmlSanitizerConfig): SafeHtml | null {
    if (typeof html === 'string') {
      return this.ngSanitizer.bypassSecurityTrustHtml(this.htmlSanitizer.sanitize(html, config));
    } else {
      return null;
    }
  }
}
