import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';

import {UserSessionService} from '../services/user-session.service';

@Directive({
  selector: '[wLogoutButton]',
})
export class LogoutButtonDirective implements OnInit, OnDestroy {
  @Input('wLogoutButtonUrl') logoutUrl = '/users/sign_out';

  constructor(
    private elementRef: ElementRef,
    private userSession: UserSessionService,
  ) {}

  ngOnInit() {
    this.elementRef.nativeElement.addEventListener('click', this.handleClick);
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('click', this.handleClick);
  }

  private handleClick = () => this.userSession.logout(this.logoutUrl);
}
