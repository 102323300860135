import {Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';

type ElementHeight = number;

@Directive({
  selector: '[wResizable]',
})
export class ResizableDirective implements OnInit, OnDestroy {
  @Input('wResizableDisabled') disabled?: boolean;
  @Output('wResizableOnChange') onChange = new EventEmitter<ElementHeight>();
  @Output('wResizableOnStart') onStart = new EventEmitter<ElementHeight>();
  @Output('wResizableOnEnd') onEnd = new EventEmitter<ElementHeight>();

  element: HTMLElement;
  handlerElement: HTMLElement | null = null;
  startY: number;
  startHeight: number;
  startMinHeight: number;

  private currentHeight: ElementHeight | null = null;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    if (!this.disabled) {
      this.element = this.elementRef.nativeElement;
      this.addHandlerElement();
    }
  }

  ngOnDestroy() {
    this.removeHandlerElement();
  }

  addHandlerElement() {
    this.handlerElement = document.createElement('div');
    this.handlerElement.classList.add('w-resize-handler');
    this.ngZone.runOutsideAngular(() => {
      this.handlerElement!.addEventListener('mousedown', this.handleMouseDown);
    });
    this.element.appendChild(this.handlerElement);
  }

  removeHandlerElement() {
    if (this.handlerElement) {
      this.handlerElement.removeEventListener('mousedown', this.handleMouseDown);
      this.handlerElement.parentNode!.removeChild(this.handlerElement);
      this.handlerElement = null;
    }
  }

  stopEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  handleMouseDown = (event: MouseEvent) => {
    if (event.which !== 1 || event.metaKey || event.ctrlKey) {
      return;
    }

    this.stopEvent(event);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
    document.body.classList.add('no-select');
    document.body.style.cursor = 'ns-resize';
    this.currentHeight = null;
    this.startY = event.pageY;
    this.startHeight = this.element.offsetHeight;
    this.startMinHeight = parseInt(getComputedStyle(this.element)['min-height'], 10);
  };

  handleMouseMove = (event: MouseEvent) => {
    this.stopEvent(event);

    // Keep minimum height of the field
    const height = Math.max(this.startHeight + event.pageY - this.startY, this.startMinHeight);

    this.element.style.height = `${height}px`;

    const clientHeight = this.element.clientHeight;

    if (this.currentHeight === null) {
      this.ngZone.run(() => this.onStart.emit(clientHeight));
    }

    this.onChange.emit(clientHeight);
    this.currentHeight = clientHeight;
  };

  handleMouseUp = (event: MouseEvent) => {
    this.stopEvent(event);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.body.classList.remove('no-select');
    document.body.style.cursor = '';
    this.ngZone.run(() => {
      this.onEnd.emit(this.currentHeight!);
    });
  };
}
