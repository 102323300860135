import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, from, map, shareReplay, switchMap, take} from 'rxjs';

import {ComponentClass} from '@shared/types/angular';

@Injectable({
  providedIn: 'root',
})
export class OemThemeCustomizationService {
  readonly isCustomizationMode$: Observable<boolean>;
  readonly customizationComponent$: Observable<ComponentClass | null>;

  private readonly customizationMode = new BehaviorSubject<boolean>(false);
  private readonly preloadComponent = new Subject<void>();

  constructor() {
    this.isCustomizationMode$ = this.customizationMode.pipe(shareReplay(1));
    this.customizationComponent$ = this.preloadComponent.pipe(
      take(1),
      switchMap(() => from(import('./oem-theme-customization.component').then(m => m.OemThemeCustomizationComponent))),
      switchMap(component => this.isCustomizationMode$.pipe(map(mode => (mode ? component : null)))),
    );
  }

  enableOemCustomization() {
    this.preloadComponent.next();
  }

  setMode(mode: boolean) {
    this.customizationMode.next(mode);
  }
}
