<div class="w-form-group" [class.w-form-group_error]="error || hasError">
  <label class="w-form-label">
    <div *ngIf="label" class="w-form-label__text">
      {{ label }}
      <span *ngIf="hint" class="w-form-label__hint w-form-label__hint_color-inherit">{{ hint }}</span>
    </div>
    <ng-content />
  </label>
  <div *ngIf="error" class="w-form-message w-form-message_error">
    {{ error }}
  </div>
</div>
