import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

export interface PingApiSettings {
  url: string;
  responseType: 'text' | 'json';
}

export const XSRF_HEADER_NAME = new InjectionToken('XSRF_HEADER_NAME');
export const PING_API_SETTINGS = new InjectionToken<PingApiSettings>('PING_API_SETTINGS');

const INVALID_AUTH_TOKEN_ERROR_PATTERN = 'ActionController::InvalidAuthenticityToken';

@Injectable()
export class CsrfTokenRefreshInterceptor implements HttpInterceptor {
  private repeatedRequests = new Set<string>();

  constructor(
    private http: HttpClient,
    @Inject(XSRF_HEADER_NAME) private xsrfHeaderName: string,
    @Inject(PING_API_SETTINGS) private pingApiSettings: PingApiSettings,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      /*
       * CSRF token is not checked for `GET` and `HEAD` requests so server won't fail with `InvalidAuthenticityToken`
       * for them.
       */
      request.method === 'GET' ||
      request.method === 'HEAD' ||
      // We can properly handle only JSON requests
      request.responseType !== 'json'
    ) {
      return next.handle(request);
    }

    const requestKey = this.getRequestKey(request);

    if (this.repeatedRequests.has(requestKey)) {
      // We shouldn't handle repeated requests or we can end up in the infinite requests loop.
      this.repeatedRequests.delete(requestKey);

      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (this.isInvalidAuthTokenError(errorResponse)) {
          return (
            // Making a request to refresh XSRF token (cookie with the new token will be set)
            this.makePingRequest().pipe(
              // Throwing original error if something went wrong
              catchError(() => throwError(errorResponse)),
              switchMap(() => {
                /*
                 * Repeating the request, but removing invalid XSRF token from the headers.
                 * In this case Angular will take the new valid token from the cookie.
                 */
                this.repeatedRequests.add(requestKey);

                return this.http.request(
                  request.clone({
                    headers: request.headers.delete(this.xsrfHeaderName),
                  }),
                );
              }),
            )
          );
        } else {
          return throwError(errorResponse);
        }
      }),
    );
  }

  private makePingRequest(): Observable<string | object> {
    if (this.pingApiSettings.responseType === 'text') {
      return this.http.get(this.pingApiSettings.url, {responseType: 'text'});
    }

    return this.http.get(this.pingApiSettings.url);
  }

  private getRequestKey(req: HttpRequest<any>): string {
    return `${req.method} ${req.urlWithParams}`;
  }

  private isInvalidAuthTokenError(errorResponse: HttpErrorResponse): boolean {
    return Boolean(
      errorResponse.status === 422 &&
        // ToDo: cooperate with backend team to fix this. https://workato.atlassian.net/browse/UI-495
        (errorResponse.error?.message === INVALID_AUTH_TOKEN_ERROR_PATTERN ||
          errorResponse.error?.exception?.includes(INVALID_AUTH_TOKEN_ERROR_PATTERN)),
    );
  }
}
