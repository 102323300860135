import {simpleHash} from './simple-hash';

/**
 * Will always pick the same item from `items` array for the same provided value.
 * For example can be used to pick the same background color for user avatar given his name.
 */
export function pickStableItemForValue<TItem>(value: string, items: TItem[]): TItem {
  const hash = Math.abs(simpleHash(value));

  return items[hash % items.length];
}
