import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {HeaderLinkComponent} from '../header-link/header-link.component';

@Component({
  selector: 'w-header-item',
  templateUrl: './header-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderItemComponent {
  @Input({required: true}) href: HeaderLinkComponent['href'];
  @Input() routerUrl?: HeaderLinkComponent['routerUrl'];
  @Input() alsoActiveFor?: HeaderLinkComponent['alsoActiveFor'];
  @Input() mixpanel?: HeaderLinkComponent['mixpanel'];
  @Input() mixpanelOptions?: HeaderLinkComponent['mixpanelOptions'];
  @Input() type: HeaderLinkComponent['type'] = 'default';

  @HostBinding('class.header-item') hostClass = true;
}
