import {AfterViewInit, Directive, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[wAfterRender]',
})
export class AfterRenderDirective implements AfterViewInit {
  @Output('wAfterRender') afterRender = new EventEmitter<void>();

  ngAfterViewInit() {
    this.afterRender.emit();
  }
}
