import {isArray, memoize, toString} from 'lodash';
import {ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';
import {CommonModule, NgClass} from '@angular/common';
import svgSprite from '@workato/svg-sprite-loader-runtime';

import {WSimpleChanges} from '../../types/angular';

export type IconSize = number | number[];
export type IconDimension = 'width' | 'height';

@Component({
  selector: 'w-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class SvgIconComponent implements OnChanges {
  static findIcon = memoize((id: SvgIcon['id']): SvgIcon => svgSprite.find(id));

  /*
   * More info about SVG icons and this attribute: https://github.com/workato/workato/wiki/%5BUI%5D-Usage-of-SVG-icons
   * Shared icons (from 'app/assets/images/shared/icons') should have @shared/ prefix
   */
  @Input({required: true}) iconId: SvgIcon['id'];
  @Input() url?: string;
  @Input() iconClass?: NgClass['ngClass'];
  @Input() size?: IconSize;

  icon: SvgIcon;
  defaultIconClass?: string;

  ngOnChanges(changes: WSimpleChanges<SvgIconComponent>) {
    if (changes.iconId) {
      this.icon = SvgIconComponent.findIcon(this.iconId);
    }

    if (changes.iconId && this.iconId) {
      const modifier = this.iconId
        // shared icons prefix
        .replace(/^@shared\//, 'shared/')
        // convert path slashes to dashes
        .replace('/', '-');

      this.defaultIconClass = `svg-icon svg-icon_${modifier}`;
    }
  }

  get width(): number {
    return this.getDimension('width');
  }

  get height(): number {
    return this.getDimension('height');
  }

  get path(): string {
    return this.url || this.iconId ? `${toString(this.url)}#${toString(this.iconId)}` : '';
  }

  private getDimension(dimension: IconDimension): number {
    if (isArray(this.size)) {
      return this.size[dimension === 'width' ? 0 : 1];
    } else if (typeof this.size === 'number') {
      return this.calculateDimension(this.size, dimension);
    } else if (this.icon) {
      return this.icon[dimension] || 0;
    } else {
      return 0;
    }
  }

  private calculateDimension(size: number, dimension: IconDimension): number {
    const {icon} = this;

    if (icon?.width && icon.height) {
      const maxDimension = Math.max(icon.width, icon.height);
      const ratio = size / maxDimension;

      return Math.round(ratio * icon[dimension]!);
    } else {
      return size;
    }
  }
}
