<div
  class="fullscreen-dialog {{ class }}"
  [ngStyle]="style"
  wTrapScroll="always"
  cdkTrapFocus
  cdkTrapFocusAutoCapture="true"
>
  <ng-container *ngIf="isTemplateContent(content); else componentContainer" [ngTemplateOutlet]="content" />
  <ng-template #componentContainer />
  <ng-container *ngIf="cancellable">
    <button
      #closeConfirm="overlay"
      class="fullscreen-dialog__close"
      type="button"
      (click)="handleCloseClick()"
      wOverlayShowOn="manual"
      wOverlayPlacement="bottom right"
      [wOverlay]="closeConfirmationBubble"
    ></button>
  </ng-container>
</div>

<ng-template #closeConfirmationBubble let-overlay>
  <w-bubble [header]="closeConfirmation!.header" [content]="closeConfirmation!.content">
    <ng-template wBubbleActions>
      <w-button size="compact" (onClick)="close()">
        {{ buttonTitles!.close }}
      </w-button>
      <w-button type="outline" size="compact" theme="neutral" (onClick)="overlay.hide()">
        {{ buttonTitles!.cancel }}
      </w-button>
    </ng-template>
  </w-bubble>
</ng-template>
