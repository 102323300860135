import _ from 'lodash';
import URL from 'url-parse';
import {filter, map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, Event, NavigationEnd, ResolveEnd, Router} from '@angular/router';

import {PageSpinnerService} from '@shared/services/page-spinner.service';

import {PageService} from '../../services/page.service';
import {RouteData} from '../../types';
import {PerformanceService} from '../../modules/performance/performance.service';

@Component({
  selector: 'w-router-outlet',
  templateUrl: './router-outlet.component.html',
})
export class RouterOutletComponent implements OnInit {
  lastNavigatedPath: string;
  initialNavigation = true;

  constructor(
    private router: Router,
    private page: PageService,
    private pageSpinner: PageSpinnerService,
    private performanceService: PerformanceService,
  ) {}

  ngOnInit() {
    this.router.initialNavigation();
    this.pageSpinner.show();
    this.page.setScrollbarSize();

    this.router.events
      .pipe(
        filter((event: Event): event is ResolveEnd => event instanceof ResolveEnd),
        map((event: ResolveEnd) => [this.getRouteUrl(event), this.getRouteData(event)]),
      )
      .subscribe(this.handleRouteChange);

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      () => {
        this.scrollToTop();

        if (this.initialNavigation) {
          this.initialNavigation = false;
          this.pageSpinner.hide();
        }
      },
      () => {
        this.pageSpinner.hide();
      },
    );
  }

  getRouteData(event: ResolveEnd): RouteData {
    // See more details https://toddmotto.com/dynamic-page-titles-angular-2-router-events
    let route: ActivatedRouteSnapshot | undefined = event.state.root;
    const data: RouteData = {};

    // Collecting data from active route tree
    do {
      _.assign(data, route.data);
      route = route.children.find(child => child.outlet === 'primary');
    } while (route);

    return data;
  }

  getRouteUrl(event: ResolveEnd): string {
    return event.url;
  }

  handleRouteChange = ([url, data]: [string, RouteData]) => {
    this.performanceService.reportPageView(url.split('?')[0]);

    if (!data.skipTitleChange) {
      this.updatePageTitle(data);
    }

    this.updatePageLayout(data);
  };

  private updatePageTitle(data: RouteData) {
    this.page.title = _.isFunction(data.title) ? data.title(data) : data.title!;
  }

  private updatePageLayout(data: RouteData) {
    if (typeof data.pageClass === 'string') {
      this.page.setClass(data.pageClass);
    } else {
      this.page.setDefaultClass();
    }

    this.page.toggleWizardPage(Boolean(data.wizardPage));
    this.page.toggleHeader(true);
    this.page.setPageSize(data.size);
  }

  private scrollToTop() {
    const {pathname} = new URL(this.router.url);

    // Scroll to top only when path has changed
    if (pathname !== this.lastNavigatedPath) {
      window.scrollTo(0, 0);
      this.lastNavigatedPath = pathname;
    }
  }
}
