import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DateTime} from 'luxon';

import {AuthUser} from '../../../services/auth-user';
import {AccountPeriod} from '../../../services/account-period.service';
import {AccountEnvironmentService} from '../../../services/account-environments.service';
import {AbstractBannerComponent} from '../abstract-banner.component';

@Component({
  selector: 'w-plan-usage-alert-banner',
  templateUrl: './plan-usage-alert-banner.component.html',
  styleUrls: ['./plan-usage-alert-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanUsageAlertBannerComponent extends AbstractBannerComponent implements OnInit {
  isFedChild: boolean;
  isFedAdmin: boolean;
  isEnvEnabled: boolean;
  showViewUsageLink: boolean;
  daysLeftToPlanRenewal: number;
  planUsageAmount: number;

  constructor(
    private authUser: AuthUser,
    private accountPeriod: AccountPeriod,
    private environmentService: AccountEnvironmentService,
  ) {
    super();
  }

  ngOnInit() {
    const {periodEndsAt, planUsageAmount} = this.accountPeriod;

    this.isFedChild = this.authUser.federation_child;
    this.isFedAdmin = this.authUser.federation && !this.isFedChild;
    this.planUsageAmount = planUsageAmount;
    this.isEnvEnabled = this.environmentService.isEnabled;
    this.showViewUsageLink = !this.environmentService.isSecondary;
    this.daysLeftToPlanRenewal = Math.floor(DateTime.fromISO(periodEndsAt).diffNow('days').days);
  }
}
