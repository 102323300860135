<a
  class="link link_{{ color }} external-link external-link_icon-{{ iconMode }}"
  [class.link_no-underline]="!underline"
  [target]="target"
  [href]="href"
  [attr.download]="download ? '' : null"
>
  <ng-content />
  <w-svg-icon
    *ngIf="iconMode !== 'hidden' && !treatAsInternal"
    class="external-link__icon"
    iconId="@shared/external-link"
    [size]="iconSize"
  />
</a>
