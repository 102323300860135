<ng-container *ngIf="subMenu; else linkTmpl">
  <button
    *ngIf="hasMultipleSubmenuItems; else subMenuSingleItemTmpl"
    class="sidebar-item sidebar-item_submenu"
    [class.sidebar-item_active]="subMenuHasActiveItem"
    [class.sidebar-item_opened]="isSubmenuOpened || isSubmenuOpenedForTablet"
    [attr.aria-labelledby]="submenuAriaLabelId"
    (mouseenter)="handleSubmenuTriggerMouseenter()"
    (mouseleave)="handleSubmenuTriggerMouseleave($event)"
    (click)="handleSubmenuTriggerClick()"
  >
    <ng-container *ngTemplateOutlet="contentTmpl" />
    <div class="sidebar-item__chevron" [class.sidebar-item__chevron_visible]="isSidebarOpened">
      <w-svg-icon class="sidebar-item__chevron-icon" iconId="@shared/chevron-right" />
    </div>
  </button>

  <ng-container *ngIf="isSubmenuOpenedForTablet">
    <ng-container *ngTemplateOutlet="subMenuTmpl" />
  </ng-container>

  <ng-template #subMenuSingleItemTmpl>
    <w-sidebar-item
      *ngIf="firstSubMenuLink"
      [href]="firstSubMenuLink.href"
      [iconId]="firstSubMenuLink.iconId"
      [routerUrl]="firstSubMenuLink.routerUrl"
      [mixpanel]="firstSubMenuLink.name"
      [text]="firstSubMenuLink.name"
    />
  </ng-template>
</ng-container>

<ng-template #linkTmpl>
  <a
    *ngIf="href; else routerLinkTmpl"
    class="sidebar-item"
    [class.sidebar-item_active]="isActive"
    [href]="href"
    (click)="hideSidebar()"
    [wMixpanel]="mixpanel"
    [wMixpanelOptions]="mixpanelOptions"
  >
    <ng-container *ngTemplateOutlet="contentTmpl" />
  </a>
</ng-template>

<ng-template #routerLinkTmpl>
  <a
    class="sidebar-item"
    [class.sidebar-item_active]="isActive"
    routerLinkActive
    [routerLink]="routerUrl!.path"
    [queryParams]="routerUrl!.query"
    [fragment]="routerUrl!.fragment"
    (click)="hideSidebar()"
    [wMixpanel]="mixpanel"
    [wMixpanelOptions]="mixpanelOptions"
  >
    <ng-container *ngTemplateOutlet="contentTmpl" />
  </a>
</ng-template>

<ng-template #contentTmpl>
  <div *ngIf="iconId || iconTemplate" class="sidebar-item__icon">
    <w-sidebar-icon
      *ngIf="iconId; else iconTemplate!"
      [iconId]="iconId"
      [isActive]="isActive || subMenuHasActiveItem"
    />
  </div>

  <div class="sidebar-item__name" [class.sidebar-item__name_visible]="isSidebarOpened">
    {{ text }}
  </div>
</ng-template>

<ng-template #subMenuTmpl wOverlayContent>
  <w-sidebar-submenu *ngIf="subMenu" [id]="submenuAriaLabelId" [subMenu]="subMenu" />
</ng-template>
