import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource, Request} from '@shared/services/http-resource';

import {Adapter, PagedResponse} from '../../types';
import {AbstractFolder} from '../../modules/folders/folders.types';

import {
  Asset,
  AssetStatus,
  AssetType,
  AssetsSortingTerm,
  ConnectionAsset,
  DataPipelineAsset,
  LcapPageAsset,
  RecipeAsset,
  TableAsset,
} from './assets.types';

export interface AssetsRequestQueryParams {
  asset_type: AssetType | null;
  deleted: boolean | null; // trash folder
  sort_term: AssetsSortingTerm | null;
  status: AssetStatus | null;
  text: string | null;
  folder_id: AbstractFolder['id'] | null;
  page: number;
  trigger: string | null;
  edited_by_user_id: string | null;
  adapters: string | null;
  'tags[]': string | string[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private resource: HttpResource;

  constructor(private http: HttpClient) {
    this.resource = new HttpResource(this.http, {
      url: '/web_api/mixed_assets/{{action}}.json',
    });
  }

  list<TAsset extends Asset = Asset>(query: Partial<AssetsRequestQueryParams>): Request<PagedResponse<TAsset>> {
    return this.resource.getAll({query});
  }

  getAdapters(): Promise<Array<Adapter['name']>> {
    return this.resource.get({action: 'adapters'});
  }

  isRecipeAsset(asset: Asset): asset is RecipeAsset {
    return asset.asset_type === 'recipe';
  }

  isConnectionAsset(asset: Asset): asset is ConnectionAsset {
    return asset.asset_type === 'connection';
  }

  isTableAsset(asset: Asset): asset is TableAsset {
    return asset.asset_type === 'table';
  }

  isLcapPageAsset(asset: Asset): asset is LcapPageAsset {
    return asset.asset_type === 'lcap_page';
  }

  isDataPipelineAsset(asset: Asset): asset is DataPipelineAsset {
    return asset.asset_type === 'data_pipeline';
  }
}
