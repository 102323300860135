import {Injectable} from '@angular/core';
import {HotkeysCheatsheetComponent} from 'angular2-hotkeys';
import {Router} from '@angular/router';

import {Hotkeys} from '@shared/services/hotkeys.service';

import {OemService} from '../modules/oem/oem.service';
import {PROJECTS_FOLDER_ID} from '../modules/folders/folders.service';

import {AuthUser} from './auth-user';

type GlobalHotkey = [hotkey: string, description: string, urlOrAction: string | (() => void)];

@Injectable({
  providedIn: 'root',
})
export class GlobalHotkeys extends Hotkeys {
  constructor(
    private authUser: AuthUser,
    private oemService: OemService,
    private router: Router,
  ) {
    super();
  }

  init(cheatsheet: HotkeysCheatsheetComponent) {
    if (!this.authUser.authenticated) {
      // Disable cheatsheet by removing the default toggle hotkey
      this.hotkeys.remove(this.hotkeys.get('?'));

      return;
    }

    this.informAboutCheatSheetToggle(cheatsheet);

    // Adding esc handler manually so that it is not present in the cheatsheet
    const escHandler = () => {
      if (cheatsheet.helpVisible$.value) {
        this.closeCheatsheet();

        return false;
      }

      return true;
    };

    this.add('esc', escHandler, {removable: false});

    if (this.oemService.isEmbeddedWidget) {
      // No global navigation hotkeys when in embedded widget mode
      return;
    }

    const globalHotkeys: GlobalHotkey[] = [
      ['r m', 'Browse projects', `/?fid=${PROJECTS_FOLDER_ID}`],
      ['c m', 'Browse connections', '/?fid=smart-connection'],
    ];

    if (!this.oemService.communityNavItemHidden) {
      globalHotkeys.push(['r e', 'Browse community recipes', '/browse/recipes']);
    }

    if (this.authUser.hasPrivilege('connection.create')) {
      globalHotkeys.push(['c c', 'Create connection', '/connections/new']);
    }

    if (this.authUser.hasPrivilege('recipe.create')) {
      globalHotkeys.push(['r c', 'Create recipe', '/recipes/new']);
    }

    for (const [hotkey, description, urlOrAction] of globalHotkeys) {
      const action = typeof urlOrAction === 'string' ? () => this.router.navigateByUrl(urlOrAction) : urlOrAction;

      this.add(hotkey, action, {description, priority: 5});
    }
  }

  private informAboutCheatSheetToggle(cheatsheet: HotkeysCheatsheetComponent) {
    const defaultToggleCheatSheet = cheatsheet.toggleCheatSheet.bind(cheatsheet);

    cheatsheet.toggleCheatSheet = () => {
      defaultToggleCheatSheet();

      if (cheatsheet.helpVisible$.value) {
        this.mixpanelService.track('Hotkeys: cheatsheet was opened');
      } else {
        // We should get notified when it gets closed by close button
        this.closeCheatsheet();
      }
    };
  }
}
