import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import successIcon from '@shared-icons/check-round.svg';
import errorIcon from '@shared-icons/exclamation-rhomb.svg';

import {Toast} from './toasts.types';

@Directive()
export abstract class AbstractToastComponent implements OnInit {
  @Input({required: true}) toast: Toast;

  @Output() close = new EventEmitter<void>();

  ngOnInit() {
    if (this.toast.timer) {
      this.toast.timer.resume();
    }
  }

  get toastIcon(): SvgIcon['id'] | null {
    if (this.toast.icon) {
      return this.toast.icon.id;
    }

    switch (this.toast.type) {
      case 'success':
        return successIcon.id;
      case 'error':
        return errorIcon.id;
      default:
        return null;
    }
  }

  handleActionClick() {
    this.toast.options.action!();

    if (this.toast.options.actionRemoveToast) {
      this.close.emit();
    }
  }

  handleMouseenter() {
    if (this.toast.timer) {
      this.toast.timer.pause();
    }
  }

  handleMouseleave() {
    if (this.toast.timer) {
      this.toast.timer.resume();
    }
  }
}
