import {OutputSchemaField} from './form-fields';
import {RecipeStep} from './step';

export enum JoinedSchemaState {
  READY,
  REQUIRES_FETCH,
  FETCH_IN_PROGRESS,
  FETCH_ERROR,
}

export interface JoinedSchemas {
  [name: string]: JoinedSchema;
}

export interface JoinedSchemaFetchParams {
  action: string;
  parentKey: string;
  input?: Record<string, string>;
}

export interface JoinPoint extends JoinedSchemaFetchParams {
  // Provider of the step which have this join point in it's output
  provider: NonNullable<RecipeStep['provider']>;
  // Alias of the step which have this join point in it's output
  alias: NonNullable<RecipeStep['as']>;
}

export interface JoinedSchema extends JoinPoint {
  schema: OutputSchemaField[];
  state: JoinedSchemaState;
  // Dynamically loaded joined schema. Once loaded, it's contents are merged into `schema` field.
  dynamicSchema?: Promise<OutputSchemaField[]>;
}
