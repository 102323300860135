import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DateTime} from 'luxon';

import {AuthUser} from '../../../services/auth-user';
import {AbstractBannerComponent} from '../abstract-banner.component';

@Component({
  selector: 'w-delegated-admin-error-banner',
  templateUrl: './delegated-admin-error-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegatedAdminErrorBannerComponent extends AbstractBannerComponent implements OnInit {
  supportSession: AuthUser['support_session'];
  durationTimeAgo: DateTime;

  constructor(private authUser: AuthUser) {
    super();
  }

  ngOnInit() {
    this.supportSession = this.authUser.support_session;
    this.durationTimeAgo = DateTime.fromISO(this.supportSession.started_at).plus({
      hours: this.supportSession.duration,
    });
  }
}
