import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'wAbbrNumber'})
export class AbbrNumberPipe implements PipeTransform {
  transform(number: number): string {
    switch (true) {
      case number >= 1000000:
        return `${this.abbreviate(number / 1000000)}m`;
      case number >= 1000:
        return `${this.abbreviate(number / 1000)}k`;
      default:
        return String(number);
    }
  }

  abbreviate(number: number): string {
    return number.toFixed(1).replace(/\.0$/, '');
  }
}
