import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationExtras, RouterStateSnapshot} from '@angular/router';

import {OemService} from '../../modules/oem/oem.service';

interface PageBeforeUnloadContext {
  reason: 'beforeunload';
}

interface PageRouteChangeContext {
  reason: 'routechange';
  currentRoute: ActivatedRouteSnapshot;
  currentState: RouterStateSnapshot;
  nextNavigationState: NavigationExtras['state'];
  nextState?: RouterStateSnapshot;
}

interface PageTeamChangeContext {
  reason: 'teamchange';
}

export type PageUnloadHandler = (context: PageUnloadHandlerContext) => PageUnloadHandlerResult;
export type PageUnloadHandlerContext = PageBeforeUnloadContext | PageRouteChangeContext | PageTeamChangeContext;

export type PageUnloadHandlerResult = void | boolean;

type Component = object;

@Injectable({
  providedIn: 'root',
})
export class PageUnloadService {
  private enabled = true;
  private globalHandlers = new Set<PageUnloadHandler>();
  private pageHandlers = new Map<Component, Set<PageUnloadHandler>>();

  constructor(private oem: OemService) {
    this.enabled = !this.oem.embedded;

    window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
      if (!this.isAllowed({reason: 'beforeunload'})) {
        // See https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
        event.returnValue = false;

        return false;
      }
    });
  }

  isAllowed(context: PageUnloadHandlerContext): boolean {
    const globalCheckResult = this.isAllowedGlobally(context);

    if (!globalCheckResult) {
      return globalCheckResult;
    }

    for (const pageComponent of this.pageHandlers.keys()) {
      if (!this.isAllowedFor(pageComponent, context)) {
        return false;
      }
    }

    return true;
  }

  isAllowedGlobally(context: PageUnloadHandlerContext): boolean {
    return this.checkHandlers(this.globalHandlers, context);
  }

  isAllowedFor(pageComponent: Component, context: PageUnloadHandlerContext): boolean {
    const handlers = this.pageHandlers.get(pageComponent);

    if (handlers?.size) {
      return this.checkHandlers(handlers, context);
    } else {
      return true;
    }
  }

  addHandler(handler: PageUnloadHandler, pageComponent?: Component) {
    if (!this.enabled) {
      return;
    }

    if (pageComponent) {
      if (!this.pageHandlers.has(pageComponent)) {
        this.pageHandlers.set(pageComponent, new Set());
      }

      this.pageHandlers.get(pageComponent)!.add(handler);
    } else {
      this.globalHandlers.add(handler);
    }
  }

  removeHandler(handler: PageUnloadHandler, pageComponent?: Component) {
    if (pageComponent) {
      if (this.pageHandlers.has(pageComponent)) {
        this.pageHandlers.get(pageComponent)!.delete(handler);
      }
    } else {
      this.globalHandlers.delete(handler);
    }
  }

  removeAllHandlers() {
    this.globalHandlers.clear();
    this.pageHandlers.clear();
  }

  private checkHandlers(handlers: Set<PageUnloadHandler>, context: PageUnloadHandlerContext): boolean {
    for (const handler of handlers) {
      if (handler(context) === false) {
        return false;
      }
    }

    return true;
  }
}
