import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';

import {OverlayDirective} from '@shared/modules/overlay/overlay.directive';
import {AbstractDialogComponent} from '@shared/modules/dialog/abstract-dialog.component';
import {DialogOptions} from '@shared/modules/dialog/dialog.types';
import {DIALOG_ARIA_LABEL_PROVIDER} from '@shared/modules/dialog/dialog.tokens';
import {OverlayModule} from '@shared/modules/overlay/overlay.module';
import {ButtonComponent} from '@shared/components/button/button.component';

import {BubbleModule} from '../../bubble/bubble.module';

@Component({
  selector: 'w-fullscreen-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  standalone: true,
  imports: [CommonModule, OverlayModule, A11yModule, BubbleModule, ButtonComponent],
  styleUrls: ['./fullscreen-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DIALOG_ARIA_LABEL_PROVIDER],
})
export class FullscreenDialogComponent extends AbstractDialogComponent implements OnInit {
  @ViewChild('closeConfirm') closeConfirm: OverlayDirective;

  buttonTitles: DialogOptions['closeConfirmationButtons'];

  override ngOnInit() {
    if (this.cancellable) {
      this.buttonTitles = {
        close: 'Close',
        cancel: 'Cancel',
        ...this.closeConfirmationButtons,
      };
    }

    super.ngOnInit();
  }

  handleCloseClick() {
    if (this.closeConfirmation) {
      this.closeConfirm.show();
    } else {
      this.close();
    }
  }
}
