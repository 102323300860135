<div class="toast toast_{{ toast.type }}" (mouseenter)="handleMouseenter()" (mouseleave)="handleMouseleave()">
  <button
    class="toast__close"
    type="button"
    (click)="close.emit()"
    wIconButton="@shared/close"
    wIconButtonAriaLabel="Close notification"
  ></button>

  <w-svg-icon *ngIf="toastIcon" iconClass="toast__icon" [iconId]="toastIcon" [size]="16" />
  <div class="toast__main">
    <div class="toast__content-wrapper">
      <div class="toast__title" [class.toast__title_main]="toast.content">
        {{ toast.title }}
      </div>
      <div *ngIf="toast.content" class="toast__content" [innerHTML]="toast.content"></div>
    </div>
    <div *ngIf="toast.options.actionTitle && toast.options.action" class="toast__action-wrapper">
      <button class="toast__action" type="button" (click)="handleActionClick()">
        {{ toast.options.actionTitle }}
      </button>
    </div>
  </div>
</div>
