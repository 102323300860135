import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {AbstractBannerComponent} from '../abstract-banner.component';

@Component({
  selector: 'w-trial-warning-banner',
  templateUrl: './trial-warning-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialWarningBannerComponent extends AbstractBannerComponent {
  @Input({required: true}) daysTillTrialEnds: number;
  @Input({required: true}) upgradeUrl: string;
}
