import {KMSUpdateStatus, Kms} from './key-management.types';

export function isKmsSwitchedSuccessfully({status, cmk_type}: Kms, expectedKmsType: Kms['cmk_type']): boolean {
  const {INITIAL, FINALIZE} = KMSUpdateStatus;

  return (status === INITIAL || status === FINALIZE) && cmk_type === expectedKmsType;
}

export function isKmsSwitchFailed(userKmsToCheck: Kms): boolean {
  return userKmsToCheck.status === KMSUpdateStatus.FAILED;
}
