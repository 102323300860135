import {NgModule} from '@angular/core';

import {BubbleComponent} from './bubble.component';
import {BubbleActionsDirective} from './bubble-actions.directive';
import {BubbleContentDirective} from './bubble-content.directive';
import {BubbleHeaderDirective} from './bubble-header.directive';

@NgModule({
  imports: [BubbleComponent],
  declarations: [BubbleActionsDirective, BubbleContentDirective, BubbleHeaderDirective],
  exports: [BubbleComponent, BubbleActionsDirective, BubbleContentDirective, BubbleHeaderDirective],
})
export class BubbleModule {}
