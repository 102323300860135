import {Component, ViewEncapsulation} from '@angular/core';

import {AbstractToastComponent} from '@shared/components/toast/toast.component';

@Component({
  selector: 'w-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent extends AbstractToastComponent {}
