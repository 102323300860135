import _ from 'lodash';

/*
 * Masks the input string using `maskChar`, while showing last `numberOfVisibleChars`
 * You can use `mask` pipe as an alternative for using this function in code.
 */
export function mask(
  value: string | null | undefined,
  numberOfVisibleChars = 3,
  maskChar = 'X',
  maskLength = 10,
): string {
  const str = _.trim(_.toString(value));

  return str.replace(
    new RegExp(`^.{${Math.max(str.length - numberOfVisibleChars, 0)}}`),
    _.repeat(maskChar, maskLength),
  );
}
