import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

import {ButtonComponent} from '@shared/components/button/button.component';
import {ButtonTheme} from '@shared/components/button/button.types';

import {PageBanner} from '../page-banners.types';

@Component({
  selector: 'w-page-banner-button',
  templateUrl: './page-banner-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBannerButtonComponent {
  @Input({required: true}) type: PageBanner['type'];
  @Input() href: ButtonComponent['href'];
  @Input() nativeNavigation: ButtonComponent['nativeNavigation'];
  @Input() pending: ButtonComponent['pending'];

  @Output() onClick = new EventEmitter<MouseEvent>();

  get theme(): Extract<ButtonTheme, 'neutral' | 'negative' | 'warning'> {
    switch (this.type) {
      case 'error':
        return 'negative';
      case 'notice':
        return 'neutral';
      case 'warning':
        return 'warning';
    }
  }
}
