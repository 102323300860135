import {Pipe, PipeTransform} from '@angular/core';

import {mask} from '../utils/mask';

@Pipe({
  name: 'mask',
})
export class MaskPipe implements PipeTransform {
  transform(...args: Parameters<typeof mask>): ReturnType<typeof mask> {
    return mask(...args);
  }
}
