import {HorizontalPosition, PositionAsText, PositionObject, VerticalPosition} from '../types';

export function parseTextPosition(position: PositionAsText): PositionObject {
  let vertical: VerticalPosition;
  let horizontal: HorizontalPosition;

  if (position === 'center') {
    vertical = horizontal = 'center';
  } else if (position === 'left' || position === 'right') {
    horizontal = position;
    vertical = 'center';
  } else if (position === 'top' || position === 'bottom') {
    vertical = position;
    horizontal = 'center';
  } else {
    [vertical, horizontal] = position.split(' ') as [VerticalPosition, HorizontalPosition];
  }

  return {vertical, horizontal};
}
