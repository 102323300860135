import {Component, Input, NgModule, OnInit, ViewEncapsulation} from '@angular/core';

import {RouterUrl} from '@shared/types/angular';

import {SharedModule} from '../../shared.module';
import {RouterHelpers} from '../../services/router-helpers.service';

@Component({
  selector: 'w-ia-header',
  templateUrl: './ia-header.component.html',
  styleUrl: './ia-header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class IaHeaderComponent implements OnInit {
  @Input({required: true}) url: RouterUrl;
  @Input() name = 'Integrations';
  @Input() widgetOnly = false;

  visible = false;

  constructor(private routerHelpers: RouterHelpers<{widget?: 'true'; app_store_mode?: 'true'}>) {}

  ngOnInit() {
    this.visible =
      !this.widgetOnly ||
      (this.routerHelpers.query.widget === 'true' && this.routerHelpers.query.app_store_mode === 'true');
  }
}

@NgModule({
  imports: [SharedModule],
  declarations: [IaHeaderComponent],
  exports: [IaHeaderComponent],
})
export class IaHeaderModule {}
