import {AuthUser} from '../../services/auth-user';

import {DEFAULT_SCOPE_GROUP_BY, NEAR_LIMIT_MULTIPLIER, SCOPE_ALLOWED_GROUP_BY} from './usage-dashboard.constants';
import {UsageGroupBy, UsageScopeFilter} from './usage-dashboard.types';

export function isAllowedGroupBy(scope: UsageScopeFilter, groupBy: UsageGroupBy): boolean {
  return SCOPE_ALLOWED_GROUP_BY[scope].includes(groupBy);
}

export function getDefaultGroupBy(scope: UsageScopeFilter): UsageGroupBy {
  return DEFAULT_SCOPE_GROUP_BY[scope];
}

export function getPercents(value: number, total: number): number {
  return Math.round((value / total) * 100);
}

export function isNearLimit(limit: number, value: number): boolean {
  return value >= limit * NEAR_LIMIT_MULTIPLIER;
}

export function isTBPV2MembershipPlan(user: AuthUser): boolean {
  return user.membership.type === 'tbp_v2';
}

export function hasApiProxyAccess(user: AuthUser): boolean {
  return user.hasAdHocFeatures('realtime_usage');
}
