import {DateTime, DateTimeOptions} from 'luxon';

import {NullableDateLike} from '../../types';

export const parseDateTime = (
  dateLike: NullableDateLike,
  parseFormat: string | null = null,
  parseOptions?: DateTimeOptions,
): DateTime | null => {
  if (!dateLike) {
    return null;
  }

  let dateTime: DateTime | null = null;

  if (DateTime.isDateTime(dateLike)) {
    dateTime = dateLike;
  }

  if (dateLike instanceof Date) {
    dateTime = DateTime.fromJSDate(dateLike, parseOptions);
  }

  if (typeof dateLike === 'number') {
    dateTime = DateTime.fromMillis(dateLike, parseOptions);
  }

  if (typeof dateLike === 'string') {
    dateTime = parseFormat
      ? DateTime.fromFormat(dateLike, parseFormat, parseOptions)
      : DateTime.fromISO(dateLike, parseOptions);
  }

  return dateTime?.isValid ? dateTime : null;
};
