import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';

import {AlertService} from '@shared/services/alert';

import {PageBannersService} from '../../../modules/page-banners/page-banners.service';
import {AuthUser} from '../../../services/auth-user';
import {UserService} from '../../../services/user.service';
import {AbstractBannerComponent} from '../abstract-banner.component';

@Component({
  selector: 'w-resend-email-warning-banner',
  templateUrl: './resend-email-warning-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendEmailWarningBannerComponent extends AbstractBannerComponent implements OnInit {
  unconfirmedEmail: string;
  resendConfirmationInProgress = false;

  constructor(
    private authUser: AuthUser,
    private userService: UserService,
    private alertService: AlertService,
    private pageBannersService: PageBannersService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.unconfirmedEmail = this.authUser.unconfirmedEmail!;
  }

  async resendConfirmationEmail() {
    this.resendConfirmationInProgress = true;

    try {
      await this.userService.resendConfirmationEmail();
    } catch ({message}) {
      this.alertService.error('Unable to send confirmation email', message);

      this.resendConfirmationInProgress = false;

      return;
    } finally {
      this.cd.markForCheck();
    }

    this.alertService.success('The email has been sent');
    this.pageBannersService.remove(this.bannerId);
  }
}
