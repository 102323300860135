import _ from 'lodash';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FixedHeaderService {
  height$ = new BehaviorSubject<number>(0);

  // Array of headers that may *possibly* be fixed/sticky
  private headers: Array<HTMLElement | string> = [];

  add(elem: HTMLElement): void {
    this.headers.push(elem);
    this.notifyFixedHeaderHeight();
  }

  remove(elem: HTMLElement): void {
    _.pull(this.headers, elem);
    this.notifyFixedHeaderHeight();
  }

  notifyFixedHeaderHeight() {
    this.height$.next(this.getFixedHeaderHeight());
  }

  getFixedHeaderHeight(): number {
    let height = 0;

    for (const header of this.headers) {
      const elem = typeof header === 'string' ? document.querySelector<HTMLElement>(header) : header;

      if (!elem) {
        continue;
      }

      const style = window.getComputedStyle(elem);

      /*
       * Seems like sometimes `getComputedStyle` may return `null`
       * Fixes https://sentry.awsprod.workato.com/workato/workato-frontend/issues/94843/?referrer=slack
       */
      if (!style) {
        continue;
      }

      if (style.position === 'fixed' || style.position === 'sticky') {
        height = Math.max(height, elem.offsetHeight + parseInt(style.top, 10));
      }
    }

    return height;
  }
}
