import {Injectable} from '@angular/core';

import {DateTimeUserFormat} from '@shared/types';
import {AbstractDateFormatService} from '@shared/services/abstract-date-format.service';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService extends AbstractDateFormatService {
  // ISO 8601
  readonly defaultDateTimeValueFormat: string = "yyyy-MM-dd'T'HH:mm:ssZZ";
  readonly defaultDateTimeValueWithoutTimezoneFormat = "yyyy-MM-dd'T'HH:mm:ss";
  readonly defaultDateValueFormat = 'yyyy-MM-dd';
  readonly defaultTimeValueFormat = 'HH:mm:ss';

  init(dateTimeFormat: DateTimeUserFormat) {
    const isDayFirstFormat = dateTimeFormat === 'DMY_24h' || dateTimeFormat === 'DMY_12h';
    const is24HoursFormat = dateTimeFormat === 'DMY_24h' || dateTimeFormat === 'MDY_24h';

    if (isDayFirstFormat) {
      this._dayOfMonth = 'd MMM';
      this._inputDate = 'dd/MM/yyyy';
      this._shortInputDate = 'dd/MM/yy';
    }

    if (is24HoursFormat) {
      this._inputTime = this._time = 'HH:mm';
      this._timeWithSeconds = 'HH:mm:ss';
    }
  }
}
