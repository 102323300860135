import {pickStableItemForValue} from '@shared/utils/pick-stable-item-for-value';

import {HexColor} from '../types';

export type AvatarPaletteColor = HexColor;
export const AVATAR_PALETTE: AvatarPaletteColor[] = [
  '#C4AB4E',
  '#6E5501',
  '#8F79D9',
  '#533AA2',
  '#41B7BF',
  '#108291',
  '#4F98E6',
  '#1060C2',
  '#916C5A',
  '#523326',
  '#858DCF',
  '#4F5DB0',
  '#B380CE',
  '#703199',
  '#B5B267',
  '#555224',
  '#8BB6A0',
  '#365E4A',
  '#CA8748',
  '#723909',
  '#D795B8',
  '#76204F',
  '#857891',
  '#584B63',
  '#92A3AD',
  '#476270',
];

export function getColorByName(name: string): AvatarPaletteColor {
  return pickStableItemForValue(name, AVATAR_PALETTE);
}
