import _pluralize from 'pluralize';
import {Pipe, PipeTransform} from '@angular/core';

import {formatNumber} from './numeral.pipe';

// If letters in uppercase, pluralize adds `s` in uppercase too.
// We should replace `PLURAL_EXCEPTIONS` after merge https://github.com/plurals/pluralize/pull/193
const PLURAL_EXCEPTIONS = [
  {rule: /OPA$/, replacement: 'OPAs'},
  {rule: /ID$/, replacement: 'IDs'},
];

export function pluralize(value: string | number, suffix: string, onlySuffix?: boolean, format?: string): string {
  const exception = PLURAL_EXCEPTIONS.find(({rule}) => rule.test(suffix));

  let pluralizedSuffix: string;

  if (exception) {
    pluralizedSuffix = Number(value) === 1 ? suffix : suffix.replace(exception.rule, exception.replacement);
  } else {
    pluralizedSuffix = _pluralize(suffix, Number(value));
  }

  return onlySuffix ? pluralizedSuffix : `${formatNumber(value, format)} ${pluralizedSuffix}`;
}

@Pipe({
  name: 'wPlural',
  standalone: true,
})
export class PluralPipe implements PipeTransform {
  transform(value: string | number, suffix: string, onlySuffix?: boolean, format?: string): string {
    return pluralize(value, suffix, onlySuffix, format);
  }
}
