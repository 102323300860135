import {inject} from '@angular/core';
import {CanLoadFn} from '@angular/router';

import {OemService} from '../../modules/oem/oem.service';
import {ServicePagesService} from '../../services/service-pages.service';

export const browsePageGuard: CanLoadFn = () => {
  if (inject(OemService).communityNavItemHidden) {
    inject(ServicePagesService).showNotFoundPage();

    return false;
  }

  return true;
};
