<w-page-banner
  *ngFor="let banner of banners$ | async"
  [type]="banner.type"
  [bannerId]="banner.id"
  [content]="banner.content"
  [contentInputs]="banner.contentInputs"
  [tag]="banner.tag"
  [isDismissible]="banner.isDismissible ?? true"
  (onDismiss)="onDismiss(banner)"
/>
