import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {escape} from 'lodash';
import dataPipelineIcon from '@icons/resource/data_pipeline.svg';

import {HttpResource, Request} from '@shared/services/http-resource';

import {Folder} from '../../modules/folders/folders.types';
import {RootDialogService} from '../../services/root-dialog.service';
import {RedirectedResponse} from '../../types';
import {makeRedirectedRequest} from '../../utils/make-redirected-request';

import {DataPipeline} from './data-pipeline.types';

interface DataPipelineObjectSyncState {
  id: string;
  object_name: string;
  state: 'STARTED' | 'FAILED' | 'SUCCEEDED';
  created_at: string;
  updated_at: string;
  loaded_count: number;
  updated_count: number;
  inserted_count: number;
  duration_millis: number;
  error: string;
}

export interface DataPipelineSyncs {
  syncs: DataPipelineObjectSyncState[];
}

@Injectable({
  providedIn: 'root',
})
export class DataPipelineService {
  private resource: HttpResource;

  constructor(
    private http: HttpClient,
    private dialog: RootDialogService,
  ) {
    this.resource = new HttpResource(this.http, {
      url: '/web_api/data_pipelines/{{id}}/{{action}}.json',
    });
  }

  get(id: DataPipeline['id']): Request<DataPipeline> {
    return this.resource.get({id});
  }

  create(name: string, projectFolderId: number): Promise<DataPipeline> {
    return this.resource.create<DataPipeline>({name, folder_id: projectFolderId});
  }

  update(id: DataPipeline['id'], props: Partial<DataPipeline>): Promise<void> {
    return this.resource.update({id}, props);
  }

  moveToFolder(id: DataPipeline['id'], folderId: Folder['id']): Promise<void> {
    return this.update(id, {folder_id: folderId});
  }

  delete(id: DataPipeline['id']): Promise<void> {
    return this.resource.delete({id});
  }

  clone(id: DataPipeline['id']): Promise<RedirectedResponse> {
    return makeRedirectedRequest(this.http.post(this.resource.url({id, action: 'clone'}), {}, {observe: 'response'}));
  }

  start(id: DataPipeline['id']): Promise<DataPipeline> {
    return this.resource.get({id, action: 'start'});
  }

  stop(id: DataPipeline['id']): Promise<DataPipeline> {
    return this.resource.get({id, action: 'stop'});
  }

  syncs(id: DataPipeline['id']): Request<DataPipelineSyncs> {
    return this.resource.get({id, action: 'syncs'});
  }

  isActive(dataPipeline: Pick<DataPipeline, 'running'>): boolean {
    return Boolean(dataPipeline.running);
  }

  async showDeleteConfirmation(dataPipeline: DataPipeline): Promise<boolean> {
    return this.dialog.openConfirmationDialog(
      'Permanently delete data pipeline?',
      `Deleting <b>${escape(
        dataPipeline.name,
      )}</b>.<br/>Data pipeline can’t be recovered from Trash, so this action can’t be undone.`,
      {
        width: 624,
        confirmButton: 'Delete pipeline',
        cancelButton: 'Cancel',
        headerIcon: dataPipelineIcon.id,
        theme: 'negative',
      },
    );
  }
}
