import {escapeRegExp} from 'lodash';

export type StringMapper<TResult> = (str: string) => TResult;

export const noopMapper: StringMapper<string> = str => str;

interface SplitStringOptions<TMatched, TUnmatched> {
  mapMatched: StringMapper<TMatched>;
  // `noopMapper` by default
  mapUnmatched?: StringMapper<TUnmatched>;
  // `true` by default
  ignoreCase?: boolean;
  // `false` by default (will replace all matches)
  onlyFirst?: boolean;
}

export function splitString<TMatched, TUnmatched = string>(
  str: string,
  query: string | RegExp,
  opts: SplitStringOptions<TMatched, TUnmatched>,
): Array<TMatched | TUnmatched> {
  let lastUnmatchedIndex = 0;
  let match: RegExpExecArray | null;
  const result: Array<TMatched | TUnmatched> = [];
  const mapMatched = opts.mapMatched;
  const mapUnmatched = opts.mapUnmatched ?? (noopMapper as unknown as StringMapper<TUnmatched>);

  if (query) {
    const ignoreCase = opts.ignoreCase ?? true;
    const regexpFlags = (ignoreCase ? 'i' : '') + (opts.onlyFirst ? '' : 'g');
    const regexpSource = typeof query === 'string' ? escapeRegExp(query) : query.source;
    const queryRegexp = new RegExp(regexpSource, regexpFlags);

    // eslint-disable-next-line no-cond-assign
    while ((match = queryRegexp.exec(str))) {
      if (!match[0]) {
        // Breaking the loop if we matched empty string (or it will be endless)
        break;
      }

      if (lastUnmatchedIndex < match.index) {
        result.push(mapUnmatched(str.slice(lastUnmatchedIndex, match.index)));
      }

      result.push(mapMatched(match[0]));
      lastUnmatchedIndex = match.index + match[0].length;

      if (opts.onlyFirst) {
        break;
      }
    }
  }

  if (lastUnmatchedIndex < str.length) {
    result.push(mapUnmatched(str.slice(lastUnmatchedIndex, str.length)));
  }

  return result;
}
