import {Component, OnInit} from '@angular/core';

import {PageService} from '../../../services/page.service';

@Component({
  selector: 'w-page-load-error',
  templateUrl: './page-load-error.component.html',
})
export class PageLoadErrorComponent implements OnInit {
  constructor(private pageService: PageService) {}

  ngOnInit() {
    this.pageService.addClass('error-page');
  }

  reloadPage() {
    // Using full page reload in case there are some problems with client/server version mismatch
    location.reload();
  }
}
