import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';

import {LayoutService} from '@shared/services/layout.service';

import {FixedHeaderService} from '../../../services/fixed-header.service';
import {GuestHeaderLink} from '../header.types';
import {RouterHelpers} from '../../../services/router-helpers.service';

import {headerLinks} from './header-links';

@Component({
  selector: 'w-guest-header',
  templateUrl: './guest-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestHeaderComponent implements AfterViewInit, OnDestroy {
  navigationLinks: GuestHeaderLink[];
  isCompactView$: Observable<boolean>;

  constructor(
    private layoutService: LayoutService,
    private fixedHeader: FixedHeaderService,
    private elementRef: ElementRef,
    private routerHelpers: RouterHelpers,
  ) {
    this.navigationLinks = headerLinks;
    this.isCompactView$ = this.layoutService.isCompactView$;
  }

  ngAfterViewInit() {
    this.fixedHeader.add(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.fixedHeader.remove(this.elementRef.nativeElement);
  }

  get requestDemoLink(): string {
    let link = '/request_demo';
    const isOnRecipePage = this.routerHelpers.isCurrentlyOnPage('recipe');

    if (isOnRecipePage) {
      link += '?utm_content=recipes_nav';
    }

    return link;
  }
}
