import {Injectable} from '@angular/core';

import {
  PAGE_ERROR_DATA,
  ServicePagesService as SharedServicePagesService,
} from '@shared/services/service-pages.service';
import {BaseRequestError, RequestError} from '@shared/services/http-resource';

import {PageDataLoadError} from '../components/abstract-page.component';

@Injectable({
  providedIn: 'root',
})
export class ServicePagesService extends SharedServicePagesService {
  protected override getDescriptionForServerError(err: BaseRequestError): string {
    let description = PAGE_ERROR_DATA['500'].description ?? 'Internal error';
    let requestId: string | null = null;

    if (err instanceof PageDataLoadError) {
      for (const error of Object.values(err.originalErrors)) {
        requestId = this.getRequestId(error!);

        if (requestId) {
          break;
        }
      }
    } else {
      requestId = this.getRequestId(err);
    }

    if (requestId) {
      description += `<br/>Request ID: ${requestId}`;
    }

    return description;
  }

  protected override isBaseRequestError(error: Error): error is BaseRequestError {
    return error instanceof PageDataLoadError || error instanceof RequestError;
  }
}
