import _ from 'lodash';

/*
 * Converts first letter to lower case only if word doesn't start with abbreviation.
 * Word is considered abbreviation if it starts with at least two upper-case letters
 * You can use `lowerFirst` pipe as an alternative for using this function in code.
 */
export function lowerFirst(str: string): string {
  return /^[A-ZА-Я]{2,}/.test(str) ? str : _.lowerFirst(str);
}
