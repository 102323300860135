import {ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation} from '@angular/core';

import {AuthUser} from '../../services/auth-user';

@Component({
  selector: 'w-page-top-sticky-area',
  templateUrl: './page-top-sticky-area.component.html',
  styleUrls: ['./page-top-sticky-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageTopStickyAreaComponent {
  @HostBinding('class.page-top-sticky-area') readonly ensureHostClass = true;

  @HostBinding('class.below-top-header')
  get notAuthenticated(): boolean {
    return !this.authUser.authenticated;
  }

  constructor(private authUser: AuthUser) {}
}
