import {Injectable} from '@angular/core';

import {AlertService} from '@shared/services/alert';

import {PageBanner} from '../modules/page-banners/page-banners.types';
import {ResendEmailWarningBannerComponent} from '../components/banners-content/resend-email-warning-banner/resend-email-warning-banner.component';
import {TeamInvitationsBannerComponent} from '../components/banners-content/team-invitations-banner/team-invitations-banner.component';
import {DelegatedAdminErrorBannerComponent} from '../components/banners-content/delegated-admin-error-banner/delegated-admin-error-banner.component';
import {TrialWarningBannerComponent} from '../components/banners-content/trial-warning-banner/trial-warning-banner.component';
import {PlanUsageAlertBannerComponent} from '../components/banners-content/plan-usage-alert-banner/plan-usage-alert-banner.component';
import {PageBannersService} from '../modules/page-banners/page-banners.service';
import {OemService} from '../modules/oem/oem.service';

import {AccountPeriod} from './account-period.service';
import {AuthUser} from './auth-user';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalBannersService {
  constructor(
    private authUser: AuthUser,
    private pageBannersService: PageBannersService,
    private alertService: AlertService,
    private userService: UserService,
    private accountPeriod: AccountPeriod,
    private oemService: OemService,
  ) {}

  init() {
    const {
      team_session,
      show_action_usage_notification,
      unconfirmedEmail,
      invitations,
      support_session,
      daysTillTrialEnds,
      authenticated,
    } = this.authUser;

    if (authenticated && !team_session && unconfirmedEmail) {
      this.showUnconfirmedEmailBanner();
    }

    if (authenticated && invitations?.length) {
      this.showTeamInvitationsBanner();
    }

    if (support_session?.id) {
      this.showSupportSessionBanner();
    }

    if (daysTillTrialEnds > 0 && daysTillTrialEnds < 6) {
      this.showUserTrialBanner();
    }

    if (!this.oemService.embedded && show_action_usage_notification) {
      this.showPlanUsageLimitBanner();
    }
  }

  private showUnconfirmedEmailBanner() {
    this.pageBannersService.warning({
      content: ResendEmailWarningBannerComponent,
      tag: {text: 'Unverified email'},
    });
  }

  private showTeamInvitationsBanner() {
    this.pageBannersService.notice({
      content: TeamInvitationsBannerComponent,
      tag: {text: 'Pending invitation'},
    });
  }

  private showSupportSessionBanner() {
    this.pageBannersService.error({content: DelegatedAdminErrorBannerComponent});
  }

  private showUserTrialBanner() {
    this.pageBannersService.warning({
      content: TrialWarningBannerComponent,
      contentInputs: {
        daysTillTrialEnds: this.authUser.daysTillTrialEnds,
        upgradeUrl: this.authUser.upgrade_url,
      },
      tag: {text: 'Warning'},
    });
  }

  private showPlanUsageLimitBanner() {
    const planUsageAmount = this.accountPeriod.planUsageAmount;
    const bannerOptions = {
      tag: {text: planUsageAmount < 100 ? 'Usage alert' : 'Limit reached'},
      content: PlanUsageAlertBannerComponent,
      onDismiss: this.disableUsageAlertForCurrentTier,
    };

    if (planUsageAmount < 100) {
      this.pageBannersService.warning(bannerOptions);
    } else {
      this.pageBannersService.error(bannerOptions);
    }
  }

  private disableUsageAlertForCurrentTier = async (banner: PageBanner) => {
    if (this.authUser.action_usage_notification_key) {
      this.pageBannersService.remove(banner.id);

      try {
        await this.userService.disableActionUsageNotification(this.authUser.action_usage_notification_key);
      } catch {
        this.alertService.error('Unable to dismiss usage alert');
      }
    }
  };
}
