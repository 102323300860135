import {Directive, EmbeddedViewRef, Input, OnChanges, TemplateRef, ViewContainerRef} from '@angular/core';

import {WSimpleChanges} from '@shared/types/angular';

@Directive({
  selector: '[wRecreateViewKey]',
})
export class RecreateViewDirective implements OnChanges {
  @Input({alias: 'wRecreateViewKey', required: true}) key: any;

  viewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnChanges(changes: WSimpleChanges<RecreateViewDirective>): void {
    if (changes.key) {
      if (this.viewRef) {
        this.destroyView();
      }

      this.createView();
    }
  }

  private createView() {
    this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private destroyView() {
    this.viewRef!.destroy();
    this.viewRef = null;
  }
}
