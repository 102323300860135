import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';

import {OverlayContentTemplate} from './overlay.types';

@Directive({
  selector: 'ng-template[wOverlayContent]',
  standalone: true,
})
export class OverlayContentDirective<TContext = any> implements OverlayContentTemplate<TContext> {
  constructor(
    public templateRef: TemplateRef<TContext>,
    public viewContainerRef: ViewContainerRef,
  ) {}
}
