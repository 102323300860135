import {splitString} from './split-string';

export type StringReplacer = (str: string) => string;
export const noopReplacer: StringReplacer = str => str;

interface ReplaceStringOptions {
  // `noopReplacer` by default
  replaceMatched?: StringReplacer;
  // `noopReplacer` by default
  replaceUnmatched?: StringReplacer;
  // `true` by default
  ignoreCase?: boolean;
  // `false` by default (will replace all matches)
  onlyFirst?: boolean;
}

export function replaceString(str: string, query: string | RegExp, opts: ReplaceStringOptions): string {
  return splitString(str, query, {
    mapMatched: opts.replaceMatched ?? noopReplacer,
    mapUnmatched: opts.replaceUnmatched ?? noopReplacer,
    ignoreCase: opts?.ignoreCase ?? true,
    onlyFirst: opts?.onlyFirst ?? false,
  }).join('');
}
