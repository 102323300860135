<w-button
  class="page-banner__button page-banner__button_{{ type }}"
  [href]="href"
  type="outline"
  size="compact"
  [theme]="theme"
  [nativeNavigation]="nativeNavigation"
  [pending]="pending"
  (onClick)="onClick.emit($event)"
>
  <ng-content />
</w-button>
