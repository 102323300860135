/**
 * Stops keyboard events coming from IME.
 * Input Method Editor is a special character picker build into OS, e.g. for Japanese kanji
 * https://en.wikipedia.org/wiki/Input_method
 */
export function suppressImeEvent(event: KeyboardEvent) {
  /*
   * isComposing is the modern approach, though it does not work in Safari
   * Using deprecated keyCode === 229 comparison as a fallback
   */
  if (event.isComposing || event.keyCode === 229) {
    event.stopPropagation();
  }
}
