import {isPlainObject} from 'lodash';

import {Adapter} from '../local-store';

export class WindowNameAdapter implements Adapter {
  private storage: {[key: string]: any};

  constructor() {
    try {
      this.storage = JSON.parse(window.name);

      if (!isPlainObject(this.storage)) {
        this.storage = {};
      }
    } catch {
      this.storage = {};
    }
  }

  setItem(key: string, val: any) {
    this.storage[key] = val;
    this.saveState();
  }

  getItem(key: string): any {
    return this.storage[key];
  }

  removeItem(key: string) {
    delete this.storage[key];
    this.saveState();
  }

  keys(): string[] {
    return Object.keys(this.storage);
  }

  private saveState() {
    window.name = JSON.stringify(this.storage);
  }
}
