import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {IconButtonComponent} from '@shared/components/icon-button/icon-button.component';
import {TrimmedTextComponent} from '@shared/components/trimmed-text/trimmed-text.component';

import {PluralModule} from '../../pipes/plural.pipe';
import {SharedModule} from '../../shared.module';
import {UserAvatarModule} from '../user-avatar/user-avatar.module';
import {KeyboardNavigationModule} from '../keyboard-navigation/keyboard-navigation.module';

import {SidebarSubmenuComponent} from './sidebar-submenu/sidebar-submenu.component';
import {SidebarIconComponent} from './sidebar-icon/sidebar-icon.component';
import {SidebarItemComponent} from './sidebar-item/sidebar-item.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {TeamItemComponent} from './team-item/team-item.component';
import {TeamSwitchSplashScreenComponent} from './team-switch-splash-screen/team-switch-splash-screen.component';
import {TeamSwitcherComponent} from './team-switcher/team-switcher.component';
import {TeamsGroupComponent} from './teams-group/teams-group.component';
import {SidebarUserComponent} from './sidebar-user/sidebar-user.component';
import {EnvSwitcherComponent} from './env-switcher/env-switcher.component';
import {SidebarLogoutItemComponent} from './sidebar-logout-item/sidebar-logout-item.component';
import {SidebarItemIconDirective} from './sidebar-item/sidebar-item-icon.directive';

@NgModule({
  declarations: [
    SidebarComponent,
    SidebarIconComponent,
    SidebarItemComponent,
    SidebarItemIconDirective,
    SidebarSubmenuComponent,
    SidebarLogoutItemComponent,
    TeamSwitcherComponent,
    TeamItemComponent,
    TeamsGroupComponent,
    TeamSwitcherComponent,
    TeamSwitchSplashScreenComponent,
    SidebarUserComponent,
    EnvSwitcherComponent,
  ],
  imports: [
    SharedModule,
    FormsModule,
    PluralModule,
    UserAvatarModule,
    KeyboardNavigationModule,
    IconButtonComponent,
    TrimmedTextComponent,
  ],
  exports: [SidebarComponent, SidebarItemIconDirective],
})
export class SidebarModule {}
