import {escape, escapeRegExp} from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {replaceString} from '../utils/replace-string';

@Pipe({
  name: 'textHighlight',
  standalone: true,
})
export class TextHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, term: string | RegExp | undefined | null, className?: string): SafeHtml;

  transform(
    text: string | undefined | null,
    term: string | RegExp | undefined | null,
    className?: string,
  ): SafeHtml | null;

  transform(
    text: string | undefined | null,
    term: string | RegExp | undefined | null,
    className?: string,
  ): SafeHtml | null {
    if (typeof text === 'string') {
      return this.sanitizer.bypassSecurityTrustHtml(this.highlight(text, term, className));
    } else {
      return null;
    }
  }

  highlight(text: string, term: string | RegExp | undefined | null, className = 'text-highlight'): string {
    return replaceString(text, term ?? '', {
      onlyFirst: true,
      replaceMatched: str => `<span class="${className}">${escape(str)}</span>`,
      replaceUnmatched: escape,
    });
  }
}

export function createHighlightRegexp(term: string): RegExp {
  return new RegExp(`(?:${escapeRegExp(term)})+`, 'i');
}
