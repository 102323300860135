import _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';

import {subscriptions} from '@shared/services/subscriptions';
import {BaseRequestError, RequestError} from '@shared/services/http-resource';
import {Hash} from '@shared/types';

export type PageState<TData extends object, TItem> = {
  [TDataKey in keyof TData]: TItem;
};

type DataLoader<TParams, TQueryParams, TResult> = (
  params: TParams,
  queryParams: TQueryParams,
) => TResult | void | Promise<TResult | void>;

type DataLoaders<TData extends object, TParams, TQueryParams> = {
  [TDataKey in keyof TData]?: DataLoader<TParams, TQueryParams, TData[TDataKey]>;
};

export type ParamsConverters<TParams extends Hash<any>> = {
  [TParamName in keyof TParams]?: (paramValue: string) => TParams[TParamName];
};

export class AbstractPageComponent<
  TData extends object,
  TParams extends Hash<any> = Hash<string>,
  TQueryParams extends Hash<any> = Hash<string>,
  TNavigationState extends Hash<any> = Hash<any>,
> {
  data = {} as TData;
  loaded = {} as PageState<TData, boolean>;
  errors = {} as Partial<PageState<TData, Error>>;

  protected subs = subscriptions();

  private paramsConverters?: ParamsConverters<TParams>;
  private queryParamsConverters?: ParamsConverters<TQueryParams>;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    opts: Partial<{
      paramsConverters: ParamsConverters<TParams>;
      queryParamsConverters: ParamsConverters<TQueryParams>;
    }> = {},
  ) {
    this.paramsConverters = opts.paramsConverters;
    this.queryParamsConverters = opts.queryParamsConverters;
  }

  get dataLoaded(): boolean {
    return _.every(this.loaded, Boolean);
  }

  get dataLoading(): boolean {
    return !this.dataLoaded;
  }

  get dataLoadError(): string | undefined {
    return Object.values(this.errors).find((value): value is Error => Boolean(value))?.message;
  }

  get params(): TParams {
    return _.mapValues(this.rawParams, (value, key) => this.param(key)) as TParams;
  }

  get queryParams(): TQueryParams {
    return _.mapValues(this.rawQueryParams, (value, key) => this.queryParam(key)) as TQueryParams;
  }

  get rawParams(): Record<keyof TParams, string> {
    return this.route.snapshot.params as any;
  }

  get rawQueryParams(): Record<keyof TQueryParams, string> {
    return this.route.snapshot.queryParams as any;
  }

  get navigationState(): TNavigationState {
    const navigation = this.router.getCurrentNavigation();

    if (!navigation) {
      return {} as TNavigationState;
    }

    return (navigation.extras.state || {}) as TNavigationState;
  }

  async loadData(loaders: DataLoaders<TData, TParams, TQueryParams>): Promise<void> {
    const promises: Array<Promise<any>> = [];
    const originalErrors = {} as Partial<PageState<TData, Error>>;
    const {params, queryParams} = this;

    _.forEach(loaders, (loader, key) => {
      this.loaded[key] = false;
      this.errors[key] = null;

      const promise = Promise.resolve()
        .then(() => loader!(params, queryParams))
        .then(result => (this.data[key] = result))
        .catch(err => {
          originalErrors[key] = err;

          if (!(err instanceof RequestError)) {
            err = new Error('Something went wrong. Try to reload the page.');
          }

          this.errors[key] = err;
        })
        .finally(() => (this.loaded[key] = true));

      promises.push(promise);
    });

    await Promise.all(promises);

    if (!_.isEmpty(originalErrors)) {
      throw new PageDataLoadError(originalErrors);
    }
  }

  param<TParamName extends keyof TParams>(name: TParamName): TParams[TParamName] {
    const value = this.rawParams[name];
    const converter = this.paramsConverters?.[name];

    return value !== undefined && converter ? converter(value) : (value as TParams[TParamName]);
  }

  queryParam<TParamName extends keyof TQueryParams>(name: TParamName): TQueryParams[TParamName] {
    const value = this.rawQueryParams[name];
    const converter = this.queryParamsConverters?.[name];

    return value !== undefined && converter ? converter(value) : (value as TQueryParams[TParamName]);
  }
}

export class PageDataLoadError<TData extends object> extends Error implements BaseRequestError {
  override name = 'PageDataLoadError';

  constructor(public originalErrors: Partial<PageState<TData, Error>>) {
    super();
    this.message = this.generateErrorMessage();
  }

  get isNotFound(): boolean {
    return _.some(this.originalErrors, err => err instanceof RequestError && err.isNotFound);
  }

  get isUnauthorized(): boolean {
    return _.some(this.originalErrors, err => err instanceof RequestError && err.isUnauthorized);
  }

  get isServerError(): boolean {
    return _.some(this.originalErrors, err => err instanceof RequestError && err.isServerError);
  }

  get isServiceUnavailable(): boolean {
    return _.some(this.originalErrors, err => err instanceof RequestError && err.isServiceUnavailable);
  }

  private generateErrorMessage(): string {
    let message = 'Error loading page data for the following keys:';

    _.forEach(this.originalErrors, (error, dataKey) => {
      message += `\n  ${dataKey}: ${error!.message || error}`;
    });

    return message;
  }
}
