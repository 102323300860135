/**
 * CAUTION!
 *
 * This module is used in `oem-client.js` which will be injected into the vendor's website.
 *
 * It means it **should not**:
 *   - Import any heavy dependencies like `lodash`
 *   - Use any ES6+ features that require polyfills e.g. `Promise`, `Map`, `Set`, async/await etc.
 *   - Use any ES6+ methods that require polyfills e.g. `String#startsWith`, `Array#find` etc.
 */

interface EmbeddingUtilsConfig {
  workatoOrigin: string;
  vendorOrigin: string;
  pathPrefix: string;
}

const FULL_URL_REGEXP = /^[a-z0-9+\-.]+:\/\//;

export class EmbeddingUtils {
  constructor(private config: EmbeddingUtilsConfig) {}

  updateConfig(config: Partial<EmbeddingUtilsConfig>) {
    this.config = {
      ...this.config,
      ...config,
    };
  }

  getWorkatoUrl(vendorUrl: string, withOrigin = false): string | null {
    const {vendorOrigin, pathPrefix} = this.config;

    if (FULL_URL_REGEXP.test(vendorUrl)) {
      if (vendorUrl.indexOf(vendorOrigin) === 0) {
        vendorUrl = vendorUrl.slice(vendorOrigin.length);
      } else {
        return null;
      }
    }

    if (
      vendorUrl.indexOf(pathPrefix) === 0 &&
      (vendorUrl.charAt(pathPrefix.length) === '/' || vendorUrl.length === pathPrefix.length)
    ) {
      let workatoUrl = vendorUrl.slice(pathPrefix.length);

      if (workatoUrl.charAt(0) !== '/') {
        workatoUrl = `/${workatoUrl}`;
      }

      if (withOrigin) {
        workatoUrl = `${this.config.workatoOrigin}${workatoUrl}`;
      }

      return workatoUrl;
    } else {
      return null;
    }
  }

  getEmbeddingUrl(workatoUrl: string, withOrigin = false): string | null {
    const {workatoOrigin} = this.config;

    if (FULL_URL_REGEXP.test(workatoUrl)) {
      if (workatoUrl.indexOf(workatoOrigin) === 0) {
        workatoUrl = workatoUrl.slice(workatoOrigin.length);
      } else {
        return null;
      }
    }

    if (workatoUrl.charAt(0) !== '/') {
      workatoUrl = `/${workatoUrl}`;
    }

    let embeddingUrl = `${this.config.pathPrefix}${workatoUrl}`;

    if (withOrigin) {
      embeddingUrl = `${this.config.vendorOrigin}${embeddingUrl}`;
    }

    return embeddingUrl;
  }
}
