import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import _ from 'lodash';

import {nonNullable} from '@shared/utils/non-nullable';
import {HttpResource, Request, RequestError} from '@shared/services/http-resource';

import {
  ClosedSourcePublication,
  CodeErrors,
  CommunityCertifiedCustomAdapterMessage,
  CommunityCustomAdapterParams,
  CopyCustomAdapterParams,
  CustomAdapter,
  CustomAdapterActionsRequestData,
  CustomAdapterActionsResponse,
  CustomAdapterBodyRequestData,
  CustomAdapterBodyResponse,
  CustomAdapterCopyResponse,
  CustomAdapterData,
  CustomAdapterOperationTestTrace,
  CustomAdapterRecipe,
  CustomAdapterShareParams,
  CustomAdapterSharingToken,
  CustomAdapterVersionFilters,
  CustomAdapterVersionLogItem,
  CustomAdapterVersions,
  GetCustomAdapterParams,
  GetSharedCustomAdaptersParams,
  PrefixedCustomAdapterData,
  ProspectsSearchItem,
  ShareCustomAdapterParams,
  TestOperationParams,
} from '../pages/custom-adapters/custom-adapters.types';
import {
  AdapterInfo,
  CommunityCertifiedAdapter,
  Hash,
  OperationSummary,
  PagedResponse,
  RawCommunityCertifiedAdapter,
  Recipe,
} from '../types';
import {OemSharedCustomAdapter} from '../modules/oem/oem.types';
import {Connection} from '../pages/connections/connections.types';

import {TabsCommunicationService} from './tabs-communication/tabs-communication.service';
import {BroadcastChannelAdapter} from './tabs-communication/broadcast-channel-adapters/broadcast-channel-adapter';
import {AuthUser} from './auth-user';

export class CustomAdapterDeployCodeError extends Error {
  constructor(
    message: string,
    public codeErrors: CodeErrors,
  ) {
    super(message);
  }
}

export const MAX_COMMUNITY_CERT_ADAPTERS_TO_SHOW = 50;

@Injectable({
  providedIn: 'root',
})
export class CustomAdaptersService {
  resource: HttpResource;
  configsResource: HttpResource;
  adminResource: HttpResource;
  webApiResource: HttpResource;
  adapterProspectsResource: HttpResource;
  communityCertifiedAdaptersResource: HttpResource;

  constructor(
    private http: HttpClient,
    private authUser: AuthUser,
    private tabsCommunicationService: TabsCommunicationService,
  ) {
    this.resource = new HttpResource(this.http, {
      url: '/custom_adapters/{{id}}/{{action}}/{{version}}.json',
    });
    this.configsResource = new HttpResource(this.http, {
      url: '/web_api/published_custom_adapters.json',
    });
    this.adminResource = new HttpResource(this.http, {
      url: `/web_api/${this.authUser.federation ? 'federations' : 'oem_admin'}/custom_adapters/{{id}}/{{action}}.json`,
    });
    this.webApiResource = new HttpResource(this.http, {
      url: '/web_api/custom_adapters/{{id}}/{{action}}.json',
    });
    this.adapterProspectsResource = new HttpResource(this.http, {
      url: '/web_api/adapter_prospects/{{action}}.json',
    });
    this.communityCertifiedAdaptersResource = new HttpResource(this.http, {
      url: '/web_api/certified_custom_adapters.json',
    });
  }

  async getAll(): Promise<CustomAdapter[]> {
    return this.resource.getAll();
  }

  async getConfigs(): Promise<AdapterInfo[]> {
    return this.configsResource.getAll();
  }

  getAllShared(params: GetSharedCustomAdaptersParams = {}): Request<PagedResponse<OemSharedCustomAdapter>> {
    return this.adminResource.getAll({query: params});
  }

  async getAllCertified(): Promise<CommunityCertifiedAdapter[]> {
    const adapters = await this.communityCertifiedAdaptersResource.getAll();

    return adapters.map(({config, ...rest}: RawCommunityCertifiedAdapter) => ({
      ...rest,
      ...config,
    }));
  }

  async getAllOemPublished(): Promise<CustomAdapter[]> {
    return this.adminResource.get({action: 'catalog'});
  }

  async shareForOem(id: CustomAdapter['id'], params: ShareCustomAdapterParams): Promise<void> {
    return this.adminResource.post({action: 'publication', id}, params);
  }

  async unshareForOem(id: CustomAdapter['id']): Promise<void> {
    return this.adminResource.delete({action: 'publication', id});
  }

  async getInfo(names: string[]): Promise<AdapterInfo[]> {
    return this.resource.get({action: 'info'}, {query: {name: names.join(',')}});
  }

  async get(id: CustomAdapter['id'], params: GetCustomAdapterParams = {}): Promise<CustomAdapter> {
    const adapter = await this.resource.get({id}, {query: params});

    if (adapter.code) {
      adapter.code = this.normalizeCode(adapter.code);
    }

    return adapter;
  }

  async create(data: CustomAdapterData): Promise<CustomAdapter['id']> {
    return this.resource.create(this.adapterToFormData(this.getDataWithPrefixedKeys(data)));
  }

  async getActionsFromSpecification(data: CustomAdapterActionsRequestData): Promise<CustomAdapterActionsResponse> {
    return this.resource.post({action: 'preview_specification_actions'}, this.adapterToFormData(data));
  }

  async generateAdapterBodyFromSpecification(data: CustomAdapterBodyRequestData): Promise<CustomAdapterBodyResponse> {
    return this.resource.post({action: 'generate_from_specification'}, this.adapterToFormData(data));
  }

  async copy(id: CustomAdapter['id'], token?: CustomAdapterSharingToken): Promise<CustomAdapterCopyResponse> {
    const data: CopyCustomAdapterParams = {};

    if (token) {
      data.token = token;
    } else {
      data.community = true;
    }

    return this.resource.post({id, action: 'copy'}, data);
  }

  async saveCode(id: CustomAdapter['id'], code: CustomAdapter['code']): Promise<CustomAdapter> {
    return this.update(id, {code});
  }

  async update(id: CustomAdapter['id'], data: CustomAdapterData): Promise<CustomAdapter> {
    const adapter: CustomAdapter = await this.resource.put(
      {id},
      this.adapterToFormData(this.getDataWithPrefixedKeys(data)),
    );

    adapter.code = this.normalizeCode(adapter.code);

    return adapter;
  }

  async lint(id: CustomAdapter['id'], code: string): Promise<CodeErrors> {
    return this.resource.post({id, action: 'lint'}, {code});
  }

  async updateCode(id: CustomAdapter['id']): Promise<CustomAdapter> {
    const adapter: CustomAdapter = await this.resource.post({id, action: 'update_code'});

    adapter.code = this.normalizeCode(adapter.code);

    return adapter;
  }

  async updateConnection(id: CustomAdapter['id'], connectionId: Connection['id']): Promise<true> {
    return this.resource.update({id, action: 'update_test_account'}, {account_id: connectionId});
  }

  async getTestInputForOperation(id: CustomAdapter['id'], info: OperationSummary): Promise<Hash<any>> {
    return this.resource.get({id, action: 'input_for_test'}, {query: info});
  }

  async testOperation(id: CustomAdapter['id'], params: TestOperationParams): Promise<CustomAdapterOperationTestTrace> {
    return this.resource.post({id, action: 'test_operation'}, params);
  }

  async delete(id: CustomAdapter['id']): Promise<void> {
    return this.resource.delete({id});
  }

  async recipes(id: CustomAdapter['id']): Promise<CustomAdapterRecipe[]> {
    return this.resource.get({id, action: 'flows'});
  }

  async versions(
    id: CustomAdapter['id'],
    params: CustomAdapterVersionFilters & {
      page?: number;
      per_page?: number;
    },
  ): Promise<CustomAdapterVersions> {
    return this.resource.get({id, action: 'version_list'}, {query: params});
  }

  async versionShareLog(
    id: CustomAdapter['id'],
    params: CustomAdapterVersionFilters & {
      page?: number;
      per_page?: number;
    },
  ): Promise<PagedResponse<CustomAdapterVersionLogItem>> {
    return this.adminResource.get({action: 'publication_logs', id}, {query: params});
  }

  async updateNote(
    id: CustomAdapter['id'],
    version: CustomAdapter['version_no'],
    note: CustomAdapter['note'],
  ): Promise<CustomAdapter['version_no']> {
    return this.resource.put({id, action: 'versions', version}, {note});
  }

  async deploy(
    id: CustomAdapter['id'],
    version: CustomAdapter['version_no'],
    note: CustomAdapter['note'],
  ): Promise<CustomAdapter> {
    let adapter: CustomAdapter;

    try {
      adapter = await this.resource.post({id, action: 'publish'}, {version_no: version, note});
    } catch (err) {
      throw this.processDeployError(err);
    }

    adapter.code = this.normalizeCode(adapter.code);

    return adapter;
  }

  async revert(id: CustomAdapter['id'], version: CustomAdapter['version_no']): Promise<CustomAdapter> {
    return this.resource.post({id, action: 'revert'}, {version_no: version});
  }

  async share(id: CustomAdapter['id'], params: CustomAdapterShareParams): Promise<CustomAdapter> {
    return this.webApiResource.post({id, action: 'share'}, params);
  }

  async updateSharedVersion(id: CustomAdapter['id']): Promise<CustomAdapter> {
    return this.webApiResource.post({id, action: 'update_shared_version'});
  }

  async shareWithCommunity(id: CustomAdapter['id'], params: CommunityCustomAdapterParams): Promise<CustomAdapter> {
    return this.webApiResource.post({id, action: 'share_with_community'}, this.getFormDataForListing(params));
  }

  async updateListing(id: CustomAdapter['id'], params: CommunityCustomAdapterParams): Promise<CustomAdapter> {
    return this.webApiResource.put({id, action: 'share_with_community'}, this.getFormDataForListing(params));
  }

  async unshareWithCommunity(id: CustomAdapter['id']): Promise<CustomAdapter> {
    return this.webApiResource.delete({
      id,
      action: 'share_with_community',
    });
  }

  async getCommunityRecipes(id: CustomAdapter['id']): Promise<Array<Pick<Recipe, 'id' | 'name'>>> {
    return this.webApiResource.get({id, action: 'community_recipes'});
  }

  prospectsSearch(value: string): Request<ProspectsSearchItem[]> {
    return this.adapterProspectsResource.get({action: 'search'}, {query: {q: value}});
  }

  getPublicSharedLink(id: CustomAdapter['id']): string {
    return `${location.origin}/custom_adapters/${encodeURIComponent(id)}/details?community=true`;
  }

  getFormDataForListing(data: CommunityCustomAdapterParams): FormData {
    const result = new FormData();

    result.append('provider', data.provider);
    result.append('show_source_code', String(!data.preventDirectInstall));

    if (this.authUser.hasRole('adapter_prospects_admin')) {
      result.append('certified', String(Boolean(data.certified)));
    }

    data.categories.forEach(category => {
      result.append('categories[]', category);
    });

    if (data.searchKeywords.length) {
      data.searchKeywords.forEach(keyword => {
        result.append('search_keywords[]', keyword);
      });
    } else {
      result.append('search_keywords', '');
    }

    if (data.logo) {
      result.append('logo', data.logo);
    }

    if (data.preventDirectInstall && data.landingPageUrl) {
      result.append('external_landing_url', data.landingPageUrl);
    }

    return result;
  }

  getCommunityCertifiedAdaptersUpdatesChannel(): BroadcastChannelAdapter<CommunityCertifiedCustomAdapterMessage> {
    return this.tabsCommunicationService.createChannel('communityCertifiedAdaptersUpdatesChannel');
  }

  getAdapterUrl(id: CommunityCertifiedAdapter['id'], queryParams?: Hash<string>): string {
    let path = `custom_adapters/${id}`;

    if (queryParams) {
      const queryParamsObj = new URLSearchParams(queryParams);

      path += `?${queryParamsObj.toString()}`;
    }

    return path;
  }

  getClosedSourcePublication(id: number): Promise<PagedResponse<ClosedSourcePublication>> {
    return this.webApiResource.get({id, action: 'closed_source_publication'});
  }

  saveClosedSourcePublication(id: number, emails: string[], notify: boolean): Promise<void> {
    return this.webApiResource.put({id, action: 'closed_source_publication'}, {emails, notify});
  }

  private processDeployError(err: RequestError): RequestError | CustomAdapterDeployCodeError {
    const syntaxErrors = err.details?.syntax?.[0];
    const semanticErrors = err.details?.semantic?.[0];

    if (syntaxErrors || semanticErrors) {
      return new CustomAdapterDeployCodeError(err.message, {
        syntax: syntaxErrors || [],
        semantic: semanticErrors || [],
      });
    }

    return err;
  }

  private normalizeCode(code: CustomAdapter['code']): CustomAdapter['code'] {
    /*
     * Fix for line breaks. In old adapters sometimes contains `\r\n` and getValue in CodeMirror converts it to `\n`
     * and that is why after code lint there are two different codes.
     * https://codemirror.net/doc/manual.html#option_lineSeparator
     */
    return code && code.replace(/\r\n/g, '\n');
  }

  private getDataWithPrefixedKeys(data: CustomAdapterData): PrefixedCustomAdapterData {
    return _.mapKeys(data, (_v, key) => `custom_adapter[${key}]`) as PrefixedCustomAdapterData;
  }

  private adapterToFormData(
    adapterData: PrefixedCustomAdapterData | CustomAdapterActionsRequestData | CustomAdapterBodyRequestData,
  ): FormData {
    const data = new FormData();

    Object.keys(adapterData).forEach(key => {
      if (nonNullable(adapterData[key])) {
        data.append(key, adapterData[key]);
      }
    });

    return data;
  }
}
