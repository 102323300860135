import {FooterLinkGroup} from './footer.types';

export const footerLinks: FooterLinkGroup[] = [
  {
    title: 'Product',
    name: 'product',
    links: [
      {
        title: 'How it works',
        href: 'https://www.workato.com/how_it_works',
      },
      {
        title: 'Pricing',
        href: 'https://www.workato.com/pricing',
      },
      {
        title: 'Product Tour',
        href: 'https://www.workato.com/product_tour',
      },
      {
        title: "What's New",
        href: 'https://www.workato.com/product-hub/whats-new/',
        target: '_blank',
        rel: 'noopener',
      },
      {
        title: 'Recipes',
        href: 'https://app.workato.com/browse/recipes',
        target: '_blank',
        rel: 'noopener',
      },
      {
        title: 'Security',
        href: 'https://www.workato.com/legal/security',
      },
      {
        title: 'Documentation',
        href: 'https://docs.workato.com/',
        target: '_blank',
        rel: 'noopener',
      },
      {
        title: 'Product Blog',
        href: 'https://www.workato.com/product-hub/',
        target: '_self',
        rel: 'noopener',
      },
    ],
  },
  {
    title: 'Solutions',
    name: 'solutions',
    links: [
      {
        title: 'HR',
        href: 'https://www.workato.com/editions/hr',
      },
      {
        title: 'Sales',
        href: 'https://www.workato.com/editions/sales',
      },
      {
        title: 'Marketing',
        href: 'https://www.workato.com/editions/marketing',
      },
      {
        title: 'Finance',
        href: 'https://www.workato.com/editions/finance',
      },
      {
        title: 'Support',
        href: 'https://www.workato.com/editions/support',
      },
      {
        title: 'IT',
        href: 'https://www.workato.com/editions/it',
      },
      {
        title: 'Product (Embed)',
        href: 'https://www.workato.com/embed-saas-integrations',
      },
      {
        title: 'Higher Ed',
        href: 'https://www.workato.com/highered',
      },
    ],
  },
  {
    title: 'Resources',
    name: 'resources',
    links: [
      {
        title: 'Docs',
        href: 'https://docs.workato.com/',
        rel: 'noopener',
        target: '_blank',
      },
      {
        title: 'Customer Success',
        href: 'https://support.workato.com/support/home',
        rel: 'noopener',
        target: '_blank',
      },
      {
        title: 'Content Library',
        href: 'https://www.workato.com/resources',
      },
      {
        title: 'IT Community',
        href: 'https://systematic.workato.com/',
        rel: 'noopener',
        target: '_blank',
      },
      {
        title: 'Workato for Slack',
        href: 'https://www.workato.com/workbot-slack',
      },
      {
        title: 'Workato for MS Teams',
        href: 'https://www.workato.com/workbot-teams',
      },
    ],
  },
  {
    title: 'Use Cases',
    name: 'use cases',
    links: [
      {
        title: 'Product Led Sales',
        href: 'https://www.workato.com/use_cases/product_led_sales',
      },
      {
        title: 'Order to Cash',
        href: 'https://www.workato.com/use_cases/order_to_cash',
      },
      {
        title: 'Employee Onboarding',
        href: 'https://www.workato.com/use_cases/employee_onboarding',
      },
      {
        title: 'Embedded Integrations',
        href: 'https://www.workato.com/use_cases/productized_integrations',
      },
      {
        title: 'Enterprise iPaaS',
        href: 'https://www.workato.com/use_cases/ipaas',
      },
      {
        title: 'IT Help Desk Bot',
        href: 'https://www.workato.com/accelerators/it_help_desk_bot',
      },
      {
        title: 'All Use Cases >',
        href: 'https://www.workato.com/use_cases',
      },
    ],
  },
  {
    title: 'Applications',
    name: 'applications',
    links: [
      {
        title: 'Salesforce',
        href: 'https://www.workato.com/integrations/salesforce',
      },
      {
        title: 'Slack',
        href: 'https://www.workato.com/integrations/slack',
      },
      {
        title: 'Marketo',
        href: 'https://www.workato.com/integrations/marketo',
      },
      {
        title: 'NetSuite',
        href: 'https://www.workato.com/integrations/netsuite',
      },
      {
        title: 'ServiceNow',
        href: 'https://www.workato.com/integrations/service_now',
      },
      {
        title: 'Workday',
        href: 'https://www.workato.com/integrations/workday',
      },
      {
        title: 'All Apps >',
        href: 'https://www.workato.com/integrations',
      },
    ],
  },
  {
    title: 'Company',
    name: 'company',
    links: [
      {
        title: 'About us',
        href: 'https://www.workato.com/about_us',
      },
      {
        title: 'Customers',
        href: 'https://www.workato.com/customers',
      },
      {
        title: 'Blog',
        href: 'https://www.workato.com/the-connector/',
        rel: 'noopener',
      },
      {
        title: 'Press',
        href: 'https://www.workato.com/press',
      },
      {
        title: 'Careers',
        href: 'https://www.workato.com/careers',
      },
      {
        title: 'Partners',
        href: 'https://www.workato.com/partners',
      },
      {
        title: 'Sitemap',
        href: 'https://www.workato.com/sitemap',
      },
      {
        title: 'Events',
        href: 'https://discover.workato.com/events/p/1',
        rel: 'noopener',
        target: '_blank',
      },
    ],
  },
];
