import {UrlQuery} from '../types/angular';

import {convertUrlQueryToHttpParams} from './convert-url-query-to-http-params';

export function stringifyUrlQuery(query: UrlQuery | null | undefined): string {
  if (!query) {
    return '';
  }

  const queryString = convertUrlQueryToHttpParams(query)?.toString() || '';

  return queryString ? `?${queryString}` : '';
}
