import {BrowserOptions, captureException, init} from '@sentry/angular';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';

import {AlertService} from '@shared/services/alert';

import {appSettings} from './app-settings';

const SENTRY_WHITELIST_URL: BrowserOptions['allowUrls'] = [/workato\.com/];
const SENTRY_IGNORE_URL: BrowserOptions['denyUrls'] = [/pendo\.workato\.com/];

if (PRODUCTION && appSettings.sentryDsn) {
  init({
    dsn: appSettings.sentryDsn,
    release: appSettings.version,
    allowUrls: SENTRY_WHITELIST_URL,
    denyUrls: SENTRY_IGNORE_URL,
    // Avoiding truncation of error messages
    maxValueLength: 8192,
  });
}

@Injectable()
class AppErrorHandler extends ErrorHandler {
  constructor(
    private alert: AlertService,
    private ngZone: NgZone,
  ) {
    super();
  }

  override handleError(err: unknown) {
    let handled = false;

    try {
      handled = this.handleKnownError(err);
    } catch (exception) {
      this.handleUnknownError(exception);
    }

    if (!handled) {
      this.handleUnknownError(err);
    }
  }

  handleKnownError(err: unknown): boolean {
    if (err instanceof Error && /ChunkLoadError/.test(err.message)) {
      /*
       * Using `ngZone.run` here as this handler is called inside a `root` zone so without it
       * change detection won't be triggered and an alert won't be shown.
       */
      this.ngZone.run(() => {
        this.alert.error(
          'Error loading the page',
          'It may be related to unstable internet connection.<br>Try to reload the page please.',
          {
            actionTitle: 'Reload',
            action: () => {
              location.reload();
            },
            removeDelay: null,
          },
        );
      });

      return true;
    }

    return false;
  }

  handleUnknownError(err: unknown) {
    super.handleError(err);
  }
}

@Injectable()
export class ProductionErrorHandler extends AppErrorHandler {
  override handleUnknownError(err: unknown) {
    captureException(err);
  }
}

@Injectable()
export class DevelopmentErrorHandler extends AppErrorHandler {}
