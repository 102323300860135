import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {isNil, mapValues, omit, omitBy} from 'lodash';

import {HttpResource, Request} from '@shared/services/http-resource';
import {PagedResponse} from '@shared/types';
import {DataTableHelper} from '@shared/modules/data-table/data-table.helper';
import {
  DataTable,
  DataTableColumn,
  DataTableMeta,
  DataTableQueryFilter,
  DataTableRow,
  DataTableRowId,
  DataTableRowsResult,
  DataTableSorting,
  DataTableValidRelation,
} from '@shared/modules/data-table/data-table.types';
import {ROW_ID_KEY} from '@shared/modules/data-table/data-table.constants';
import {LocalStore} from '@shared/services/local-store/local-store';
import {
  AbstractDataTableService,
  DataTableListRequestParams,
  DataTableMetaListRequestParams,
  DataTableRowsRequestParams,
  DataTableUnifiedId,
} from '@shared/modules/data-table/abstract-data-table.service';
import {download} from '@shared/utils/download';

import {DataTableChangeEvent, DataTablesTrackingService} from './data-tables-tracking.service';

export const ROWS_PER_PAGE = 200;

type DataTableColumnPayload = Omit<DataTableColumn, 'id'> & {id?: DataTableColumn['id']};

interface DataTableColumnForCSV {
  field: DataTableColumn['id'];
  name: DataTableColumn['title'];
}

export interface DataTablePreview {
  total_entries_count: number;
  schema: DataTableColumn[];
  entries: DataTableRow[];
}

@Injectable({
  providedIn: 'root',
})
export class DataTableService extends AbstractDataTableService {
  private tablesResource: HttpResource;
  private recordsResource: HttpResource;
  private queriesResource: HttpResource;
  private tableLoadRequests = new Map<DataTableUnifiedId, Request<DataTable>>();

  constructor(
    private http: HttpClient,
    private dataTableHelper: DataTableHelper,
    private localStore: LocalStore,
    private dataTablesTrackingService: DataTablesTrackingService,
  ) {
    super();
    this.tablesResource = new HttpResource(this.http, {
      url: '/web_api/workato_db/tables/{{id}}.json',
    });
    this.recordsResource = new HttpResource(this.http, {
      url: '/web_api/workato_db/tables/{{id}}/records/{{recordId}}/{{action}}.json',
    });
    this.queriesResource = new HttpResource(this.http, {
      url: '/web_api/workato_db/tables/{{id}}/queries.json',
    });
  }

  override getAll(params?: DataTableListRequestParams): Request<PagedResponse<DataTable>> {
    return this.tablesResource.getAll({query: params});
  }

  override getAllMeta(params?: DataTableMetaListRequestParams): Request<PagedResponse<DataTableMeta>> {
    return this.tablesResource.getAll({query: params});
  }

  override get(id: DataTableUnifiedId): Request<DataTable> {
    const loadingRequest = this.tableLoadRequests.get(id);

    if (loadingRequest && !loadingRequest.isAborted) {
      return loadingRequest;
    }

    const request = this.tablesResource.get<DataTable>({id});

    this.tableLoadRequests.set(id, request);

    request.finally(() => this.tableLoadRequests.delete(id));

    return request;
  }

  async create(name: string, projectFolderId: number): Promise<DataTable> {
    const table = await this.tablesResource.create<DataTable>({name, folder_id: projectFolderId});

    this.notifyTableChanges({
      tableId: table.table_id,
      type: 'created',
      data: {table},
    });

    return table;
  }

  async delete(id: DataTableUnifiedId): Promise<void> {
    await this.tablesResource.delete({id});

    this.clearStorageData(id);
    this.notifyTableChanges({
      tableId: id,
      type: 'deleted',
    });
  }

  async rename(id: DataTableUnifiedId, newName: string): Promise<void> {
    await this.tablesResource.update({id}, {name: newName});
    this.notifyTableChanges({
      tableId: id,
      type: 'renamed',
      data: {title: newName},
    });
  }

  async update(id: DataTableUnifiedId, newSchema: DataTableColumn[]): Promise<DataTableColumn[]> {
    const schema: DataTableColumnPayload[] = newSchema.map(column =>
      this.dataTableHelper.isPendingCreationColumn(column) ? omit(column, 'id') : column,
    );

    const table = await this.tablesResource.update<DataTable>({id}, {schema});

    this.notifyTableChanges({
      tableId: id,
      type: 'schema-updated',
      data: {schema: table.schema},
    });

    return table.schema;
  }

  override getRows(id: DataTableUnifiedId, params?: Partial<DataTableRowsRequestParams>): Request<DataTableRowsResult> {
    return this.recordsResource.post(
      {id, action: 'query'},
      omitBy(
        {
          ...params?.filter,
          order_by: params?.sorting?.columnId,
          direction: params?.sorting?.order,
          continuation_token: params?.continuationToken,
          limit: params?.limit ?? ROWS_PER_PAGE,
        },
        isNil,
      ),
    );
  }

  override getRelatedRows(
    id: DataTableUnifiedId,
    params?: Partial<DataTableRowsRequestParams>,
  ): Request<DataTableRowsResult> {
    return this.getRows(id, params);
  }

  async addOrUpdateRow(id: DataTableUnifiedId, row: DataTableRow, columns: DataTableColumn[]): Promise<DataTableRow> {
    const mappedRowData = this.mapRowDataForSave(row, columns);

    if (this.dataTableHelper.isPendingCreationRow(row)) {
      return this.recordsResource.post({id}, mappedRowData);
    } else {
      return this.recordsResource.put({id, recordId: row[ROW_ID_KEY]}, mappedRowData);
    }
  }

  async deleteRows(tableId: DataTableUnifiedId, rowIds: DataTableRowId[]): Promise<void> {
    return this.recordsResource.post({id: tableId, action: 'delete_batch'}, {record_ids: rowIds});
  }

  async previewImportedTable(tableId: DataTableUnifiedId, file: File): Promise<DataTablePreview> {
    // Import CSV functionality will be implemented in v2
    throw new Error('Not implemented');

    const formData = new FormData();

    formData.append('data_table_csv', file);

    return this.tablesResource.put({id: tableId, action: 'upload'}, formData);
  }

  async importTable(tableId: DataTableUnifiedId, file: File): Promise<DataTable> {
    // Import CSV functionality will be implemented in v2
    throw new Error('Not implemented');

    const formData = new FormData();

    formData.append('data_table_csv', file);

    const table = await this.tablesResource.put({id: tableId, action: 'upload'}, formData);

    this.notifyTableChanges({
      tableId,
      type: 'created',
      data: {table},
    });

    return table;
  }

  async downloadCSV(
    tableId: DataTableUnifiedId,
    fileName: string,
    filter: DataTableQueryFilter | null = null,
    sorting: DataTableSorting | null = null,
    columns: DataTableColumnForCSV[],
  ): Promise<void> {
    const queryId = (
      await this.queriesResource.post<{query_id: string}>(
        {id: tableId},
        omitBy(
          {
            ...filter,
            order_by: sorting?.columnId,
            direction: sorting?.order,
            columns,
          },
          isNil,
        ),
      )
    ).query_id;

    download(
      `/web_api/workato_db/tables/${tableId}/queries/${queryId}/download.json?download_file_name=${fileName}.csv`,
    );
  }

  private clearStorageData(id: DataTableUnifiedId) {
    const keys = this.localStore.keys();

    keys
      .filter(key => key.startsWith(this.dataTableHelper.getStoragePrefix(id)))
      .forEach(key => this.localStore.removeItem(key));
  }

  private mapRowDataForSave(row: DataTableRow, columns: DataTableColumn[]): Omit<DataTableRow, typeof ROW_ID_KEY> {
    const readonlyColumnIds = columns.filter(column => column.read_only).map(column => column.id);
    const customRowData: Omit<DataTableRow, typeof ROW_ID_KEY> = omitBy(row, (_value, key) =>
      readonlyColumnIds.includes(key),
    );

    return mapValues(customRowData, (value, key) => {
      switch (columns.find(col => col.id === key)?.type) {
        case 'relation':
          // Backend fails if we pass relation.value alongside relation.record_id
          return value ? (omit(value as DataTableValidRelation, 'value') as DataTableValidRelation) : null;
        case 'relation-set':
          // Clean up relation.value for set
          return value
            ? (value as DataTableValidRelation[]).map(valueItem => omit(valueItem, 'value') as DataTableValidRelation)
            : null;
        default:
          return value;
      }
    });
  }

  private notifyTableChanges(event: DataTableChangeEvent) {
    this.dataTablesTrackingService.notify(event);
  }
}
