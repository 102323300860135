import {escape} from 'lodash';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {WSimpleChanges} from '../../types/angular';
import {TooltipDirective} from '../../modules/tooltip/tooltip.directive';
import {TextHighlightPipe} from '../../pipes/text-highlight.pipe';

@Component({
  selector: 'w-trimmed-text',
  templateUrl: './trimmed-text.component.html',
  styleUrls: ['./trimmed-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TooltipDirective, TextHighlightPipe],
})
export class TrimmedTextComponent implements OnChanges {
  @Input({required: true}) text: string;
  @Input() highlightTerm?: string | RegExp | null;
  @Input() highlightClass?: string;
  @Input() lineCount = 1;
  @Input() tooltipPlacement?: TooltipDirective['placement'];
  @Input() tooltipOriginType: TooltipDirective['originType'] = 'pointer';
  @Input() tooltipClass?: string;

  @ViewChild('containerElem', {static: true}) containerElem: ElementRef<HTMLElement>;
  @ViewChild('textElem', {static: true}) textElem: ElementRef<HTMLElement>;

  @HostBinding('class.trimmed-text')
  hostClass = true;

  trimmed = false;
  safeText: string;

  ngOnChanges(changes: WSimpleChanges<TrimmedTextComponent>) {
    if (changes.text) {
      this.safeText = escape(this.text);
    }
  }

  handleMouseenter() {
    this.trimmed = this.isOverflowingY() || this.isOverflowingX();
  }

  private isOverflowingX(): boolean {
    return this.textElem.nativeElement.offsetWidth > this.containerElem.nativeElement.offsetWidth;
  }

  private isOverflowingY(): boolean {
    return this.textElem.nativeElement.offsetHeight > this.containerElem.nativeElement.offsetHeight;
  }
}
