import {Pipe, PipeTransform} from '@angular/core';

import {appSettings} from '../app-settings';

@Pipe({
  name: 'websiteUrl',
})
export class WebsiteUrlPipe implements PipeTransform {
  transform(path: string): string {
    return `${appSettings.websiteUrl}${path}`;
  }
}
