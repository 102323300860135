import {NgModule} from '@angular/core';

import {KeyboardNavigationDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation.directive';
import {KeyboardNavigationItemDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item.directive';
import {KeyboardNavigationItemConfigDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item-config.directive';

import {SharedModule} from '../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    KeyboardNavigationDirective,
    KeyboardNavigationItemDirective,
    KeyboardNavigationItemConfigDirective,
  ],
  exports: [KeyboardNavigationDirective, KeyboardNavigationItemDirective, KeyboardNavigationItemConfigDirective],
})
export class KeyboardNavigationModule {}
