import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'w-form-group',
  templateUrl: './form-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent {
  @Input() error?: string;
  @Input() hasError?: boolean;
  @Input() label?: string;
  @Input() hint?: string;
  @Input() verticalSpacing?: 'normal' | 'small';

  get classes(): NgClass['ngClass'] {
    return {
      [`w-form-group_spacing_${this.verticalSpacing}`]: this.verticalSpacing,
    };
  }
}
