import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource} from '@shared/services/http-resource';

import {AuthUser} from './auth-user';

export type NotificationStatus = 'ok' | 'warning' | 'danger';

export interface AccountPeriodData {
  credit: number;
  debit: number;
  action_count: number | null;
  effective_action_limit: number | null;
  action_limit_adjustment: number | null;
  starts_at: string;
  ends_at: string;
  membership_id?: number;
}

const floorTo1Decimal = (n: number): number => Math.floor((n + Number.EPSILON) * 10) / 10;

@Injectable({
  providedIn: 'root',
})
export class AccountPeriod {
  transactionsLimit: number | null = null;
  transactionsUsed: number | null = null;
  periodEndsAt: string;
  periodStartsAt: string;
  jobsUsed: number | null = null;
  jobsLimit: number | null = null;
  planUsageAmount = 0;
  actionLimitAdjustment: number | null = null;
  actionLimitAdjustmentAction?: 'added' | 'subtracted';

  private resource: HttpResource;
  private preciseDataApi: HttpResource;

  constructor(
    private authUser: AuthUser,
    private http: HttpClient,
  ) {
    this.resource = new HttpResource(this.http, {
      url: '/users/accounting_period',
    });
    this.preciseDataApi = new HttpResource(this.http, {
      url: '/web_api/accounting_periods/current',
    });
  }

  get progress(): number {
    if (this.transactionsLimit && this.transactionsUsed) {
      return Math.round((this.transactionsUsed / this.transactionsLimit) * 100);
    } else {
      return 0;
    }
  }

  get status(): NotificationStatus {
    if (this.transactionsLimit) {
      if (this.transactionsUsed && this.transactionsUsed >= this.transactionsLimit) {
        return 'danger';
      } else if (this.progress >= 80) {
        return 'warning';
      }
    }

    return 'ok';
  }

  get enforceTransactionQuota(): boolean {
    return this.authUser.enforce_transaction_quota;
  }

  init() {
    if (this.authUser.accounting_period) {
      this.update(this.authUser.accounting_period);
    }
  }

  async refresh(precise = false): Promise<void> {
    const period: AccountPeriodData = precise ? await this.preciseDataApi.get() : await this.resource.get();

    this.update(period);
  }

  private update(data: AccountPeriodData) {
    this.transactionsLimit = this.enforceTransactionQuota ? data.credit : null;
    this.transactionsUsed = this.enforceTransactionQuota ? data.debit : null;
    this.jobsUsed = data.action_count;
    this.jobsLimit = data.effective_action_limit;
    this.planUsageAmount = this.getPlanUsageAmount();
    this.actionLimitAdjustment = data.action_limit_adjustment ? Math.abs(data.action_limit_adjustment) : null;
    this.periodEndsAt = data.ends_at;
    this.periodStartsAt = data.starts_at;

    if (typeof data.action_limit_adjustment === 'number') {
      this.actionLimitAdjustmentAction =
        data?.action_limit_adjustment && data.action_limit_adjustment > 0 ? 'added' : 'subtracted';
    }
  }

  private getPlanUsageAmount(): number {
    if (this.jobsUsed === null || this.jobsLimit === null) {
      return 0;
    }

    return Math.min(floorTo1Decimal((this.jobsUsed / this.jobsLimit) * 100), 100);
  }
}
