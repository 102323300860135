<div
  class="w-page-main"
  [class.w-page-main_top-header]="!isAuthenticated"
  [class.w-page-main_with-copyright]="hasCopyright"
>
  <div class="w-main-header">
    <w-sidebar *ngIf="isAuthenticated; else guestHeaderTmpl" />
    <ng-template #guestHeaderTmpl>
      <w-guest-header />
    </ng-template>
  </div>
  <w-page-top-sticky-area>
    <w-environment-type-bar *ngIf="!isFullyEmbedded" />
    <w-page-banners />
  </w-page-top-sticky-area>
  <div class="w-page-wrapper">
    <div class="w-page-container">
      <div class="w-page">
        <w-ia-header *ngIf="integrationAppId" name="app" [url]="{path: integrationAppUrl}" />
        <router-outlet />
      </div>
    </div>
    <w-footer *ngIf="hasFooter && (loaded$ | async)" />
    <w-widget-copyright *ngIf="hasCopyright" class="w-page-main__widget-copyright" />
  </div>
</div>
<ng-container *ngComponentOutlet="oemCustomizationComponent$ | async" />
