import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {SharedModule} from '../../shared.module';

import {AppVersionUpdateInterceptor} from './app-version-update-interceptor.service';

@NgModule({
  imports: [SharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AppVersionUpdateInterceptor,
    },
  ],
})
export class AppVersionModule {}
