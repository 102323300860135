export type SuggestionType = 'method' | 'function' | 'operator' | 'pill';

export type SuggestionCategory = 'string' | 'array' | 'date' | 'number' | 'conditional' | 'conversion';

export interface SimpleParameter {
  name: string;
  description?: string;
}

export interface ComplexParameter {
  left: SimpleParameter;
  right: SimpleParameter;
}

export type FunctionParameter = SimpleParameter | ComplexParameter;

export interface PrimaryCodeExample {
  input?: {
    type: RubyType;
    value: string;
  };
  code: string;
  output: {
    type: RubyType;
    value: string;
  };
}

export interface FormulaSuggestion {
  name: string;
  value?: string;
  alias?: string;
  type: Exclude<SuggestionType, 'pill'>;
  options: {
    description: string;
    categories?: SuggestionCategory[];
    global?: boolean;
    operator?: boolean;
    result_type?: RubyType;
    types?: RubyType[];
    params?: FunctionParameter[];
    usage?: number;
    primaryExample?: PrimaryCodeExample;
    examples?: string[];
    search_tags?: string[];
    quick_tips?: string[];
    more_link?: string;
  };
}

export enum RubyType {
  STRING = 'string',
  DATE_TIME = 'date_time',
  DATE = 'date',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  INTEGER = 'integer',
  FLOAT = 'float',
  ARRAY = 'array',
  HASH = 'hash',
  BINARY = 'binary',
  UNIT_OF_TIME = 'unit_of_time',
}
