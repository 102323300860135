import {ChangeDetectionStrategy, Component, ContentChild, TemplateRef, ViewEncapsulation} from '@angular/core';

import {TextPreviewAsideDirective} from './text-preview-aside.directive';

@Component({
  selector: 'w-text-preview',
  templateUrl: './text-preview.component.html',
  styleUrls: ['./text-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextPreviewComponent {
  @ContentChild(TextPreviewAsideDirective, {read: TemplateRef}) asideChild?: TemplateRef<void>;
}
