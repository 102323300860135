import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserInitialsAvatarComponent} from './user-initials-avatar/user-initials-avatar.component';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UserInitialsAvatarComponent, UserAvatarComponent],
  exports: [UserInitialsAvatarComponent, UserAvatarComponent],
})
export class UserAvatarModule {}
