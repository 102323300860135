import _ from 'lodash';
import URL from 'url-parse';
import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Params,
  QueryParamsHandling,
  Router,
  RouterEvent,
} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {NullableProps} from '@shared/types/lib';
import {RouterUrl} from '@shared/types/angular';
import {stringifyUrlQuery} from '@shared/utils/stringify-url-query';

import {Hash, RouteConfigData} from '../types';

interface NavigationParams<TNavigationState extends NavigationExtras['state'] = NavigationExtras['state']> {
  state?: TNavigationState;
  replaceUrl?: NavigationExtras['replaceUrl'];
  queryParamsHandling?: QueryParamsHandling | null;
}

interface UpdateQueryParams<TNavigationState extends NavigationExtras['state'] = NavigationExtras['state']>
  extends NavigationParams<TNavigationState> {
  overwrite?: boolean;
}

type QueryParams<T> = {
  [K in keyof T]: T[K] extends string ? T[K] : string;
};

interface RouteChangeEvent {
  readonly url: string;
  readonly pathname: string;
}

@Injectable({
  providedIn: 'root',
})
export class RouterHelpers<
  TQueryParams extends object = Params,
  TNavigationState extends NavigationExtras['state'] = NavigationExtras['state'],
> {
  previousUrl: string | undefined;
  navigationState = {} as TNavigationState;
  routeChange$: BehaviorSubject<RouteChangeEvent>;

  private navigationStartEvent: NavigationStart | null = null;
  private routerEvents$: Observable<RouterEvent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.routerEvents$ = this.router.events.pipe(
      filter(e => e instanceof RouterEvent),
      // workaround for wrong Angular typing https://github.com/angular/angular/issues/43124
      map(e => e as RouterEvent),
    );

    this.routeChange$ = new BehaviorSubject(this.createRouteChangeEvent(this.router.url));
    this.routerEvents$.subscribe(this.handleRouterEvents);
  }

  get canGoBack(): boolean {
    return Boolean(this.previousUrl);
  }

  get query(): Partial<QueryParams<TQueryParams>> {
    return this.route.snapshot.queryParams as Partial<QueryParams<TQueryParams>>;
  }

  get fragment(): string | null {
    return this.route.snapshot.fragment;
  }

  get currentPathname(): RouteChangeEvent['pathname'] {
    return this.routeChange$.value.pathname;
  }

  get currentUrl(): RouteChangeEvent['url'] {
    return this.routeChange$.value.url;
  }

  navigateByUrl(url: string | RouterUrl, params?: NavigationParams<TNavigationState>): Promise<boolean> {
    if (typeof url === 'string') {
      return this.router.navigateByUrl(url, params);
    } else {
      return this.router.navigate(_.castArray(url.path), {
        ...params,
        queryParams: url.query,
        fragment: url.fragment,
      });
    }
  }

  updateQuery(
    queryParams: Partial<NullableProps<TQueryParams>>,
    params: UpdateQueryParams<TNavigationState> = {},
  ): Promise<boolean> {
    return this.router.navigate([], {
      queryParams,
      queryParamsHandling: params.overwrite ? null : 'merge',
      preserveFragment: true,
      replaceUrl: params.replaceUrl,
      state: params.state,
    });
  }

  replaceCurrentNavigationState(state: TNavigationState): Promise<boolean> {
    return this.router.navigate([], {state, replaceUrl: true});
  }

  updateFragment(fragment: string | null, params?: NavigationParams<TNavigationState>): Promise<boolean> {
    return this.router.navigate([], {
      // @ts-ignore: navigate() can handle null for fragment, which is used to clear it
      fragment,
      queryParamsHandling: 'preserve',
      ...params,
    });
  }

  goBack(fallbackUrl = '/'): Promise<boolean> {
    return this.navigateByUrl(this.previousUrl || fallbackUrl);
  }

  findRouteByName(
    routeName: RouteConfigData['routeName'],
    snapshot: ActivatedRouteSnapshot | undefined | null,
  ): ActivatedRouteSnapshot | null {
    if (!snapshot) {
      return null;
    }

    do {
      if ((snapshot.data as RouteConfigData).routeName === routeName) {
        return snapshot;
      }

      snapshot = snapshot.firstChild;
    } while (snapshot);

    return null;
  }

  isCurrentlyOnPage(routeName: string): boolean {
    return Boolean(this.findRouteByName(routeName, this.route.snapshot.root));
  }

  toUrlString(url: RouterUrl): string {
    const pathString = Array.isArray(url.path) ? url.path.join('/') : url.path;
    const queryString = url.query ? this.toQueryString(url.query) : '';
    const fragmentString = url.fragment ? `#${url.fragment}` : '';

    return `${location.origin}${pathString}${queryString}${fragmentString}`;
  }

  toQueryString(query: Hash<any>): string {
    return stringifyUrlQuery(query);
  }

  private createRouteChangeEvent(urlString: string): RouteChangeEvent {
    const url = new URL(urlString);

    return {
      url: url.pathname + url.query + url.hash,
      pathname: url.pathname,
    };
  }

  private handleRouterEvents = (event: RouterEvent) => {
    if (event instanceof NavigationStart) {
      const {extras: navigationExtras} = this.router.getCurrentNavigation()!;

      this.navigationState = (navigationExtras.state || {}) as TNavigationState;
      this.navigationStartEvent = event;
    } else if (event instanceof NavigationEnd) {
      const {extras: navigationExtras} = this.router.getCurrentNavigation()!;

      if (
        !navigationExtras?.replaceUrl ||
        // `popstate` trigger always has `replaceUrl: true` flag set
        this.navigationStartEvent?.navigationTrigger === 'popstate'
      ) {
        this.previousUrl = this.currentUrl;
      }

      this.navigationStartEvent = null;
      this.routeChange$.next(this.createRouteChangeEvent(event.urlAfterRedirects));
    }
  };
}
