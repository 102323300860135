import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {AppVersionService} from './app-version.service';

const VERSION_HEADER = 'x-workato-version';

@Injectable({
  providedIn: 'root',
})
export class AppVersionUpdateInterceptor implements HttpInterceptor {
  constructor(private appVersion: AppVersionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.extractServerVersion(errorResponse.headers);

        return throwError(errorResponse);
      }),
      tap(event => {
        if (event instanceof HttpResponse) {
          this.extractServerVersion(event.headers);
        }
      }),
    );
  }

  private extractServerVersion(headers: HttpHeaders) {
    const version = headers.get(VERSION_HEADER);

    if (version) {
      this.appVersion.updateServerVersion(version);
    }
  }
}
