import _ from 'lodash';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, lastValueFrom} from 'rxjs';

import {Hash} from '@shared/types';
import {RequestError, getHttpErrorMessage} from '@shared/services/http-resource';

import {RedirectedResponse} from '../types';

import {toSentence} from './to-sentence';

export async function makeRedirectedRequest<T>(request: Observable<HttpResponse<T>>): Promise<RedirectedResponse<T>> {
  try {
    const response = await lastValueFrom(request);

    return transformResponse(response);
  } catch (err) {
    throw transformError(err);
  }
}

function transformResponse<T>(response: HttpResponse<T>): RedirectedResponse<T> {
  return {redirectTo: response.headers.get('Location')!, body: response.body};
}

function transformError(response: HttpErrorResponse): RequestError {
  if (response.status !== 0 && (response.error?.error || response.error?.errors)) {
    return new RequestError(
      response.error.error || toErrorSentence(response.error.errors) || 'Validation error',
      false,
      response.error.errors,
      response.status,
    );
  }

  return new RequestError(getHttpErrorMessage(response), true, response, response.status);
}

function toErrorSentence(errors: Hash<string[]>): string {
  return toSentence(
    _.flatMap(errors, (values: string[], key: string) => {
      const fieldName = _.words(key).map(_.capitalize).join(' ');

      return values.map(value => `${fieldName} ${value}`);
    }),
  );
}
