import {BroadcastChannelAdapter} from './broadcast-channel-adapter';

export class BroadcastChannelNativeAdapter<TMessage> extends BroadcastChannelAdapter<TMessage> {
  static override isAvailable(): boolean {
    return typeof window.BroadcastChannel === 'function';
  }

  private channel: BroadcastChannel;

  constructor(channelName: string) {
    super(channelName);

    this.channel = new BroadcastChannel(`workato.${channelName}`);
  }

  postMessage(message: TMessage) {
    this.channel.postMessage(JSON.stringify(message));
  }

  setMessageHandler(handler: (message: TMessage) => void) {
    this.channel.addEventListener('message', (event: MessageEvent<string>) => handler(JSON.parse(event.data)));
  }

  close() {
    this.channel.close();
  }
}
