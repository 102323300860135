import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[wLazyShow]',
})
export class LazyShowDirective {
  @Input({alias: 'wLazyShow', required: true})
  get visible(): any {
    return this._visible;
  }

  set visible(condition: any) {
    const visible = Boolean(condition);

    if (visible === this._visible) {
      return;
    }

    this._visible = visible;

    // Emitting `visibilityChange` event only on showing/hiding (don't emit on initial view rendering)
    const emittingEvent = Boolean(this.viewRef);

    if (visible) {
      if (this.viewRef) {
        this.toggleViewVisibility(true);
        this.viewRef.reattach();
      } else {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else if (this.viewRef) {
      this.viewRef.detach();
      this.toggleViewVisibility(false);
    }

    if (emittingEvent) {
      this.visibilityChange.emit(visible);
    }
  }

  @Output('wLazyShowVisibilityChange') visibilityChange = new EventEmitter<boolean>(true);

  private _visible: boolean;
  private viewRef?: EmbeddedViewRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    // `ElementRef` for structural directives refers to HTML comment before which its view will be rendered
    private elemRef: ElementRef<Comment>,
  ) {}

  private toggleViewVisibility(visible: boolean) {
    if (visible) {
      const fragment = document.createDocumentFragment();

      for (const node of this.viewRef!.rootNodes as Node[]) {
        fragment.appendChild(node);
      }

      this.elemRef.nativeElement.parentNode?.insertBefore(fragment, this.elemRef.nativeElement);
    } else {
      /*
       * `viewRef.rootNodes` may contain not only DOM elements, but also text nodes, comment nodes etc.
       * so we can't just apply `display: none` to them
       */
      for (const node of this.viewRef!.rootNodes as Node[]) {
        node.parentNode?.removeChild(node);
      }
    }
  }
}
