import _ from 'lodash';

import {LRUCache} from '@shared/services/lru-cache';

import {ExpressionParser} from '../expression-parser';
import {Expression, ExpressionParserOptions} from '../expression-parser/types';

import {compile} from './compiler';
import {CompilationError, CompilationResult, CompiledTemplate, Template, TemplateData} from './types';

function isCompilationError(result: CompilationResult): result is CompilationError {
  return Boolean((result as CompilationError).error);
}

const TEMPLATE_REGEXP = /{{[\s\S]+?}}/;

export class TemplateCompiler {
  static isTemplate(str: string): boolean {
    return TEMPLATE_REGEXP.test(str);
  }

  cache = new LRUCache<Template, CompilationResult>(100);
  expressionParser: ExpressionParser;

  constructor(private opts: ExpressionParserOptions = {}) {
    this.expressionParser = new ExpressionParser({
      ...opts,
      internalHelpers: {
        escape: _.escape,
        toString: _.toString,
        ...opts.internalHelpers,
      },
    });
  }

  compile(template: Template): CompiledTemplate {
    let result = this.cache.get(template);

    if (!result) {
      try {
        result = {fn: this.makeFunctionFromTemplate(template)};
      } catch (error) {
        result = {error};
      }

      this.cache.put(template, result);
    }

    if (isCompilationError(result)) {
      throw result.error;
    } else {
      return result.fn;
    }
  }

  render(template: Template, data?: TemplateData | null): string {
    return this.compile(template)(data);
  }

  makeFunctionFromTemplate(template: Template): CompiledTemplate {
    const templateCode = compile(template, {
      parseJs: this.parseJs,
    });
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const fn = new Function('data', 'helpers', '_', templateCode);

    return data => fn(data, this.opts.helpers, this.expressionParser.internalHelpers);
  }

  parseJs = (expression: string): Expression => this.expressionParser.parse(expression);
}
