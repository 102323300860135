import {Pipe, PipeTransform} from '@angular/core';

import {RouterUrl} from '@shared/types/angular';

import {stringifyRouterUrl} from '../utils/stringify-router-url';

@Pipe({
  name: 'routerUrlToHref',
})
export class RouterUrlToHrefPipe implements PipeTransform {
  transform(value: RouterUrl | null | undefined): string {
    return stringifyRouterUrl(value);
  }
}
