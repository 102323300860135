import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';

import {AbstractDialogComponent} from '@shared/modules/dialog/abstract-dialog.component';
import {DIALOG_ARIA_LABEL_PROVIDER} from '@shared/modules/dialog/dialog.tokens';
import {ButtonComponent} from '@shared/components/button/button.component';
import {TrapScrollDirective} from '@shared/directives/trap-scroll.directive';
import {IconButtonComponent} from '@shared/components/icon-button/icon-button.component';
import {OverlayModule} from '@shared/modules/overlay/overlay.module';

import {BubbleModule} from '../bubble/bubble.module';

export {DialogContentComponent} from '@shared/modules/dialog/dialog.types';

@Component({
  selector: 'w-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TrapScrollDirective,
    A11yModule,
    IconButtonComponent,
    OverlayModule,
    BubbleModule,
  ],
  styleUrl: './dialog.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DIALOG_ARIA_LABEL_PROVIDER],
})
export class DialogComponent extends AbstractDialogComponent {
  constructor(public injector: Injector) {
    super();
  }
}
