import {Directive, ElementRef, Input, NgZone, OnChanges} from '@angular/core';

import {WSimpleChanges} from '../types/angular';

/**
 * Focuses on current element when provided expression becomes `true`.
 *
 * Example:
 *
 *     // Focuses input after showing
 *     <input *ngIf="inputShown" [wFocusWhen]="inputShown"/>
 */
@Directive({
  selector: 'input[wFocusWhen], textarea[wFocusWhen]',
  standalone: true,
})
export class FocusWhenDirective implements OnChanges {
  // Input will be focused each time `triggerValue` changes to some truthy value
  @Input({alias: 'wFocusWhen', required: true}) triggerValue: any;
  @Input('wFocusOnce') once?: boolean;
  @Input('wFocusPreventScroll') preventScroll?: boolean;
  @Input('wSelectOnFocus') selectOnFocus = false;

  constructor(
    private elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(changes: WSimpleChanges<FocusWhenDirective>) {
    if (changes.triggerValue && this.triggerValue && (!this.once || changes.triggerValue.isFirstChange())) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          // Trigger value might have changed during setTimeout
          if (!this.triggerValue) {
            return;
          }

          // Container animations might be broken because of scrolling initiated by autofocus
          const preventScroll = this.preventScroll || this.getAncestorRunningAnimations().length > 0;

          this.elementRef.nativeElement.focus({preventScroll});

          if (this.selectOnFocus) {
            this.elementRef.nativeElement.select();
          }
        });
      });
    }
  }

  private getAncestorRunningAnimations(): Animation[] {
    return document
      .getAnimations()
      .filter(({effect}) => effect instanceof KeyframeEffect && effect.target?.contains(this.elementRef.nativeElement));
  }
}
