<div>
  You’ve used <b class="plan-usage-alert-amount">{{ planUsageAmount }}%</b> of tasks
  {{ isFedChild ? 'available' : 'in your plan' }}
  {{ isEnvEnabled ? 'across all environments' : '' }}
  {{ isFedAdmin ? 'across all workspaces' : '' }}
  (renews in {{ daysLeftToPlanRenewal }} days).
  {{ isFedChild ? 'Please contact your workspace administrator to add more tasks.' : 'Reach out to add more tasks.' }}

  <w-external-link *ngIf="showViewUsageLink" href="/members/settings/subscription" color="unset"
    >View usage</w-external-link
  >
</div>
