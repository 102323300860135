import {Injectable} from '@angular/core';

import {Overlay} from '@shared/modules/overlay/overlay.service';

import {TeamSwitchSplashScreenComponent} from '../modules/sidebar/team-switch-splash-screen/team-switch-splash-screen.component';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  constructor(private overlay: Overlay) {}

  showChangeTeamOverlay(teamName: string) {
    this.overlay.show(TeamSwitchSplashScreenComponent, {
      props: {teamName},
    });
  }

  switchToTeam(teamId: number, teamName: string) {
    const id = encodeURIComponent(teamId);
    const name = encodeURIComponent(teamName);

    window.location.href = `/users/switch_team?team_id=${id}&team_name=${name}`;
  }
}
