import {hexToRgba} from '@shared/utils/colors';

import {DERIVED_VARIABLES, EMBEDDED_VARIABLES, FONT_VARIABLES, THEME_VARIABLES} from './theme-variables';

export type CssThemeVariableName = keyof typeof THEME_VARIABLES;

export type CssDerivedVariableName = keyof typeof DERIVED_VARIABLES;

export type CssFontVariableName = keyof typeof FONT_VARIABLES;

export type CssEmbeddedVariableName = keyof typeof EMBEDDED_VARIABLES;

export type CssColorVariableName = CssThemeVariableName | CssDerivedVariableName;

export type CssOverridableVariableName = CssThemeVariableName | CssFontVariableName | CssEmbeddedVariableName;

export type CssVariableName =
  | CssThemeVariableName
  | CssDerivedVariableName
  | CssFontVariableName
  | CssEmbeddedVariableName;

export type CssVariableType =
  | 'background-color'
  | 'foreground-color'
  | 'border-color'
  | 'font-size'
  | 'font-family'
  | 'margin';

export class CssVariable {
  constructor(
    public type: CssVariableType,
    public displayName?: string,
    public hint?: string,
    public defaultValue?: string,
  ) {}
}

export class CssVariableRef {
  constructor(public refName: CssThemeVariableName) {}
}

export class CssDerivedColor {
  constructor(
    public baseName: CssThemeVariableName,
    public opacity: number,
  ) {}

  getValue(themeVariableValues: {[key in CssThemeVariableName]: string}): string | null {
    const baseVariableValue = themeVariableValues[this.baseName];

    return baseVariableValue ? hexToRgba(baseVariableValue, this.opacity) : null;
  }
}

export type CssTheme = {
  [key in CssThemeVariableName]: string | CssVariableRef;
};

export type ResolvedTheme = {
  [key in CssVariableName]: string;
};

export type ThemeOverrides = {
  [key in CssOverridableVariableName]?: string;
};
