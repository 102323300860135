export enum KMSUpdateStatus {
  FINALIZE = 'finalizing',
  INITIAL = 'initial',
  FAILED = 'failed',
  ROTATING = 'rotating',
}

export enum KMSType {
  WORKATO_KMS = 'hashicorp_transit',
  AWS_KMS = 'aws_kms',
  CUSTOM_KMS = 'hashicorp_transit_with_context',
}

export interface Kms {
  cmk_type: KMSType;
  key_id: string;
  updated_at: string;
  status: KMSUpdateStatus;
}

export type KmsUpdatePayload =
  | {
      cmk_type: KMSType.WORKATO_KMS;
    }
  | {
      cmk_type: KMSType.AWS_KMS;
      key_id: Kms['key_id'];
    }
  | {
      cmk_type: KMSType.CUSTOM_KMS;
      key_material: string;
    };

export interface KmsResponse {
  kms: Kms;
}
