<header
  class="header"
  [class.header_theme_light]="theme === 'light'"
  [class.header_type_desktop]="isDesktopLayout$ | async"
  role="banner"
>
  <ng-content select="w-support-session-banner" />
  <div class="header__content">
    <div class="header__top">
      <ng-content select=".header-logo" />
      <div class="header__bars-menu" [class.header__bars-menu_right]="isAuthenticated">
        <div class="burger-menu" [class.burger-menu_open]="menuOpened" (click)="toggleMenu()">
          <span class="burger-menu__item"></span>
          <span class="burger-menu__item"></span>
          <span class="burger-menu__item"></span>
          <span class="burger-menu__item"></span>
        </div>
      </div>
    </div>
    <nav class="header__nav" [class.header__nav_open]="menuOpened">
      <ng-content select=".header-items" />
    </nav>
  </div>
</header>
