import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';

import {CssColorVariableName} from '../theming/theme-types';
import {ThemeService} from '../theming/theme.service';

@Pipe({name: 'wThemeColor', pure: false})
export class ThemeColorPipe implements PipeTransform, OnDestroy {
  private sub: Subscription;

  constructor(
    private themeService: ThemeService,
    private cd: ChangeDetectorRef,
    private vc: ViewContainerRef,
  ) {
    this.sub = this.themeService.getThemeKeyAsObservableFor(this.vc.element).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  transform(variableName: CssColorVariableName): string;
  transform(variableName: CssColorVariableName | undefined): string | undefined;

  transform(variableName: CssColorVariableName | undefined): string | undefined {
    if (!variableName) {
      return variableName;
    }

    return this.themeService.getColor(variableName, this.vc.element);
  }
}
