import {Directive, ElementRef, HostBinding, Input, NgZone, OnChanges, OnDestroy} from '@angular/core';
import {delay, fromEvent, take} from 'rxjs';
import {v4 as uuid} from 'uuid';

import {subscriptions} from '../services/subscriptions';

/* Directive fixes unloading page context in FF bug when downloading file
 * @see https://bugzilla.mozilla.org/show_bug.cgi?id=564744 (old, but seems still actual)
 * @see https://bugzilla.mozilla.org/show_bug.cgi?id=1084399 - similar + proposal https://github.com/whatwg/html/issues/4489
 */
@Directive({
  selector: 'a[wDownloadLink]',
  standalone: true,
})
export class DownloadLinkDirective implements OnDestroy, OnChanges {
  @Input('wDownloadLink') enabled = true;

  @HostBinding('attr.download') get downloadAttr(): string | null {
    return this.enabled ? '' : null;
  }

  private iframes: Record<string, HTMLIFrameElement> = {};
  private sub = subscriptions();

  constructor(
    private elemRef: ElementRef<HTMLLinkElement>,
    private ngZone: NgZone,
  ) {}

  ngOnChanges() {
    if (this.enabled) {
      this.install();
    } else {
      this.uninstall();
    }
  }

  ngOnDestroy() {
    Object.values(this.iframes).forEach(el => el.parentNode?.removeChild(el));
    this.uninstall();
  }

  private install() {
    this.ngZone.runOutsideAngular(() => {
      this.elemRef.nativeElement.addEventListener('pointerdown', this.handleClick);
    });
  }

  private uninstall() {
    this.elemRef.nativeElement.removeEventListener('pointerdown', this.handleClick);
  }

  private handleClick = () => {
    const iframeEl = document.createElement('iframe');
    const linkEl = this.elemRef.nativeElement;
    const href = linkEl.href;
    const originalTarget = linkEl.getAttribute('target');
    const name = `iframe-${uuid()}`;

    if (!href) {
      return;
    }

    iframeEl.style.position = 'absolute';
    iframeEl.style.top = '-9999px';
    iframeEl.style.left = '-9999px';
    iframeEl.name = name;

    this.sub.add(
      fromEvent(linkEl, 'click')
        .pipe(take(1), delay(1000))
        .subscribe(() => {
          if (linkEl.getAttribute('target') === name) {
            if (originalTarget) {
              linkEl.setAttribute('target', originalTarget);
            } else {
              linkEl.removeAttribute('target');
            }
          }

          document.body.removeChild(iframeEl);
          delete this.iframes[name];
        }),
    );

    this.iframes[name] = iframeEl;
    document.body.appendChild(iframeEl);
    linkEl.setAttribute('target', name);
  };
}
