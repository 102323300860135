import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';

import {OemThemeCustomizationService} from '../../../components/oem-theme-customization/oem-theme-customization.service';
import {SidebarService} from '../sidebar.service';

@Component({
  selector: 'w-sidebar-logout-item',
  templateUrl: './sidebar-logout-item.component.html',
  styleUrls: ['../styles/sidebar-item.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLogoutItemComponent {
  @Input() logoutUrl = '/users/sign_out';

  readonly isSidebarOpened$: Observable<boolean>;
  readonly themeCustomizationActive$: Observable<boolean>;

  constructor(
    private sidebarService: SidebarService,
    private themeCustomizationService: OemThemeCustomizationService,
  ) {
    this.isSidebarOpened$ = this.sidebarService.isSidebarOpened$;
    this.themeCustomizationActive$ = this.themeCustomizationService.isCustomizationMode$;
  }
}
