<span
  #containerElem
  class="trimmed-text__container"
  [class.trimmed-text_vertical]="lineCount > 1"
  [style.-webkit-line-clamp]="lineCount"
  (mouseenter)="handleMouseenter()"
  [wTooltip]="safeText"
  [wTooltipDisabled]="!trimmed"
  [wTooltipPlacement]="tooltipPlacement"
  [wTooltipOriginType]="tooltipOriginType"
  [wTooltipClass]="tooltipClass"
>
  <span
    #textElem
    [innerHTML]="highlightTerm ? (text | textHighlight: highlightTerm : highlightClass) : safeText"
  ></span>
</span>
