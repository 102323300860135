import {Adapter} from '../local-store';

export class LocalStorageAdapter implements Adapter {
  private storage = window.localStorage;

  setItem(key: string, val: any) {
    this.storage.setItem(key, JSON.stringify(val));
  }

  getItem(key: string): any {
    const val = this.storage.getItem(key);

    if (typeof val !== 'string') {
      return undefined;
    }

    try {
      return JSON.parse(val);
    } catch {
      return undefined;
    }
  }

  keys(): string[] {
    return Object.keys(this.storage);
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }
}
