import {Injectable} from '@angular/core';

import {Constructor} from '@shared/types/lib';
import {Log} from '@shared/services/log';

import {BroadcastChannelNativeAdapter} from './broadcast-channel-adapters/native-adapter';
import {BroadcastChannelLocalStorageAdapter} from './broadcast-channel-adapters/local-storage-adapter';
import {BroadcastChannelAdapter} from './broadcast-channel-adapters/broadcast-channel-adapter';
import {NoopAdapter} from './broadcast-channel-adapters/noop-adapter';

@Injectable({providedIn: 'root'})
export class TabsCommunicationService {
  private readonly adapters = [BroadcastChannelNativeAdapter, BroadcastChannelLocalStorageAdapter];
  private readonly adapterConstructor: Constructor<BroadcastChannelAdapter>;

  constructor(private log: Log) {
    const adapterConstructor = this.findWorkingAdapter();

    if (adapterConstructor) {
      this.adapterConstructor = adapterConstructor;
    } else {
      this.adapterConstructor = NoopAdapter;
      this.log.warn("TabsCommunication service couldn't find any working adapter. All method calls will be noop");
    }
  }

  createChannel<TMessage>(name: string): BroadcastChannelAdapter<TMessage> {
    return new this.adapterConstructor(name);
  }

  private findWorkingAdapter(): Constructor<BroadcastChannelAdapter> | undefined {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    for (const AdapterConstructor of this.adapters) {
      if (AdapterConstructor.isAvailable()) {
        return AdapterConstructor;
      }
    }
  }
}
