import {v4 as uuid} from 'uuid';

import {BroadcastChannelAdapter} from './broadcast-channel-adapter';

const TEST_KEY = '__test__';

export class BroadcastChannelLocalStorageAdapter<TMessage> extends BroadcastChannelAdapter<TMessage> {
  static override isAvailable(): boolean {
    const storage = window.localStorage;

    try {
      storage.setItem(TEST_KEY, '0');

      if (storage.getItem(TEST_KEY) !== '0') {
        return false;
      }

      storage.removeItem(TEST_KEY);

      if (storage.getItem(TEST_KEY) !== null) {
        return false;
      }
    } catch {
      return false;
    }

    return true;
  }

  private storage: Storage;
  private messageHandler?: (event: StorageEvent) => void;

  private readonly storeKeyPrefix: string;

  constructor(channelName: string) {
    super(channelName);

    this.storeKeyPrefix = `workato.broadcastChannel.${channelName}.`;
    this.storage = window.localStorage;
    this.removeStaleItems();
  }

  postMessage(message: TMessage) {
    const storageKey = `${this.storeKeyPrefix}${uuid()}`;

    this.storage.setItem(storageKey, JSON.stringify(message));
    this.storage.removeItem(storageKey);
  }

  setMessageHandler(handler: (message: TMessage) => void) {
    this.messageHandler = this.getMessageHandler(handler);
    window.addEventListener('storage', this.messageHandler);
  }

  close() {
    if (this.messageHandler) {
      window.removeEventListener('storage', this.messageHandler);
    }

    this.removeStaleItems();
  }

  private getMessageHandler(handler: (message: TMessage) => void): (event: StorageEvent) => void {
    return (event: StorageEvent) => {
      if (event.newValue !== null && event.key !== null && event.key.startsWith(this.storeKeyPrefix)) {
        let parsedValue: TMessage;

        try {
          parsedValue = JSON.parse(event.newValue);
        } catch {
          return;
        }

        handler(parsedValue);
      }
    };
  }

  private removeStaleItems() {
    Object.keys(this.storage)
      .filter(key => key.startsWith(this.storeKeyPrefix))
      .forEach(key => this.storage.removeItem(key));
  }
}
