import {Pipe, PipeTransform} from '@angular/core';

import {AuthUser, AuthUserPrivileges} from '../services/auth-user';
import {AuthUserPrivilegeExpression} from '../services/auth-user.types';

@Pipe({
  name: 'wHasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private authUser: AuthUser) {}

  transform(value: AuthUserPrivileges, hasAccess = true): boolean {
    if (hasAccess) {
      return this.authUser.hasPrivilege(value);
    } else {
      const expression = this.invertExpression(this.authUser.convertToPrivilegeExpression(value));

      return !this.authUser.hasPrivilege(expression);
    }
  }

  private invertExpression({operator, operands}: AuthUserPrivilegeExpression): AuthUserPrivilegeExpression {
    return {
      operator: operator === 'and' ? 'or' : 'and',
      operands: operands.map(operand => (typeof operand === 'string' ? operand : this.invertExpression(operand))),
    };
  }
}
