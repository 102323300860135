import {ErrorHandler, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {DataTableUnifiedId} from '@shared/modules/data-table/abstract-data-table.service';
import {DataTable} from '@shared/modules/data-table/data-table.types';

import {TabsCommunicationService} from './tabs-communication/tabs-communication.service';
import {BroadcastChannelAdapter} from './tabs-communication/broadcast-channel-adapters/broadcast-channel-adapter';
import {AuthUser} from './auth-user';

type DataTableChangeType = 'renamed' | 'created' | 'schema-updated' | 'deleted';

type DataTableChangeEventOf<
  TType extends DataTableChangeType = DataTableChangeType,
  TData extends object | void = void,
> = {
  tableId: DataTableUnifiedId;
  type: TType;
} & (TData extends object ? {data: TData} : {});

type DataTableRenamedEvent = DataTableChangeEventOf<'renamed', {title: string}>;
type DataTableCreatedEvent = DataTableChangeEventOf<'created', {table: DataTable}>;
type DataTableSchemaUpdatedEvent = DataTableChangeEventOf<'schema-updated', {schema: DataTable['schema']}>;
type DataTableDeletedEvent = DataTableChangeEventOf<'deleted'>;

type DataTableEvents =
  | DataTableRenamedEvent
  | DataTableCreatedEvent
  | DataTableSchemaUpdatedEvent
  | DataTableDeletedEvent;

export type DataTableChangeEvent<TType extends DataTableChangeType = DataTableChangeType> = Extract<
  DataTableEvents,
  {type: TType}
>;

const CHANNEL_ID = 'dataTablesTracking';

@Injectable({providedIn: 'root'})
export class DataTablesTrackingService implements OnDestroy {
  change$: Observable<DataTableChangeEvent>;

  private changeSubject = new Subject<DataTableChangeEvent>();

  private readonly channelAdapter: BroadcastChannelAdapter<DataTableChangeEvent>;

  constructor(
    authUser: AuthUser,
    private tabsCommunicationService: TabsCommunicationService,
    private errorHandler: ErrorHandler,
  ) {
    this.change$ = this.changeSubject.asObservable();
    this.channelAdapter = this.tabsCommunicationService.createChannel(`${CHANNEL_ID}.${authUser.id}`);
    this.channelAdapter.setMessageHandler(this.messageHandler);
  }

  ngOnDestroy() {
    this.channelAdapter.close();
  }

  notify(event: DataTableChangeEvent) {
    try {
      this.channelAdapter.postMessage(event);
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  private messageHandler = (event: DataTableChangeEvent): void => {
    this.changeSubject.next(event);
  };
}
