import _ from 'lodash';
import {Injectable} from '@angular/core';
import URL from 'url-parse';

import {appSettings} from '../../app-settings';

import {PerformanceMarkName} from './performance.types';

const SUPPORT_PERFORMANCE_API = 'performance' in window && 'PerformanceObserver' in window;
const OBSERVED_TYPES = ['mark', 'resource'];

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  observer: PerformanceObserver | null = null;

  init() {
    if (SUPPORT_PERFORMANCE_API) {
      this.observer = new PerformanceObserver(list => {
        list.getEntries().forEach((entry: PerformanceEntry) => {
          if (entry.name === 'app_init') {
            this.measure(undefined, 'app_init');
          }

          if (entry.name === 'page_ready') {
            this.measure('page_init', 'page_ready');
          }

          if (entry instanceof PerformanceResourceTiming && entry.initiatorType === 'xmlhttprequest') {
            const url = new URL(entry.name);

            if (url.origin === window.location.origin) {
              this.report(entry.name, entry.duration, 'XHR requests');
            }
          }
        });
      });

      this.observer.observe({entryTypes: OBSERVED_TYPES});
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  mark(name: PerformanceMarkName) {
    if (SUPPORT_PERFORMANCE_API) {
      window.performance.mark(name);
    }
  }

  reportPageView(pathname: string) {
    if (window.gtag) {
      window.gtag('config', appSettings.googleAnalyticsAppId, {
        'page_location': `${window.location.origin}${pathname}`,
      });
    } else {
      /*
       * Uncomment for debugging purpose
       */
      // console.log(`pageview: ${window.location.origin}${pathname}`);
    }
  }

  private measure(from: PerformanceMarkName | undefined, to: PerformanceMarkName) {
    const name = `from ${from || 'start'} to ${to}`;

    window.performance.measure(name, from, to);

    const measure = _(window.performance.getEntriesByName(name))
      .filter(entry => entry.entryType === 'measure')
      .last();

    if (measure) {
      this.report(name, measure.duration, 'Measures');
    }
  }

  private report(name: string, value: number, category: string) {
    if (window.gtag) {
      window.gtag('event', 'timing_complete', {
        name,
        value,
        event_category: category,
      });
    } else {
      /*
       * Uncomment for debugging purpose
       */
      // console.log(`${category}(${name}): ${value}`);
    }
  }
}
