import {GuestHeaderLink} from '../header.types';

export const headerLinks: GuestHeaderLink[] = [
  {
    title: 'Platform',
    columns: [
      [
        {
          title: 'Platform',
          links: [
            {
              title: 'How it works',
              href: 'https://www.workato.com/how_it_works',
            },
            {
              title: 'Why Workato',
              href: 'https://www.workato.com/platform',
            },
            {
              title: 'Pricing',
              href: 'https://www.workato.com/pricing',
            },
            {
              title: 'Product tour',
              href: 'https://www.workato.com/product_tour',
            },
            {
              title: "What's new",
              href: 'https://www.workato.com/product-hub/whats-new/',
            },
          ],
        },
      ],
      [
        {
          title: 'Features',
          links: [
            {
              title: 'Enterprise iPaaS',
              href: 'https://www.workato.com/platform#next_gen_ipaas',
            },
            {
              title: 'Workflow automation',
              href: 'https://www.workato.com/platform#enterprise_workflow_automation',
            },
            {
              title: 'Embedded integrations',
              href: 'https://www.workato.com/embed-saas-integrations',
            },
            {
              title: 'Workato for Slack',
              href: 'https://www.workato.com/workbot-slack',
            },
          ],
        },
      ],
    ],
  },
  {
    title: 'Solutions',
    columns: [
      [
        {
          title: 'By Function',
          links: [
            {
              title: 'HR',
              href: 'https://www.workato.com/editions/hr',
            },
            {
              title: 'Sales',
              href: 'https://www.workato.com/editions/sales',
            },
            {
              title: 'Marketing',
              href: 'https://www.workato.com/editions/marketing',
            },
            {
              title: 'Finance',
              href: 'https://www.workato.com/editions/finance',
            },
            {
              title: 'Support',
              href: 'https://www.workato.com/editions/support',
            },
            {
              title: 'IT',
              href: 'https://www.workato.com/editions/it',
            },
            {
              title: 'Product (Embed)',
              href: 'https://www.workato.com/embed-saas-integrations',
            },
            {
              title: 'Revenue Operations',
              href: 'https://discover.workato.com/revenueoperations/p/1',
            },
          ],
        },
      ],
      [
        {
          title: 'By App',
          links: [
            {
              title: 'Salesforce integrations',
              href: 'https://www.workato.com/integrations/salesforce',
            },
            {
              title: 'Slack integrations',
              href: 'https://www.workato.com/integrations/slack',
            },
            {
              title: 'Marketo integrations',
              href: 'https://www.workato.com/integrations/marketo',
            },
            {
              title: 'NetSuite integrations',
              href: 'https://www.workato.com/integrations/netsuite',
            },
            {
              title: 'ServiceNow integrations',
              href: 'https://www.workato.com/integrations/service_now',
            },
            {
              title: 'Workday integrations',
              href: 'https://www.workato.com/integrations/workday',
            },
          ],
          view_all: {
            title: 'View all',
            href: 'https://www.workato.com/integrations',
          },
        },
      ],
      [
        {
          title: 'by use case',
          links: [
            {
              title: 'Product-led sales',
              href: 'https://www.workato.com/use_cases/product_led_sales',
            },
            {
              title: 'Order-to-cash',
              href: 'https://www.workato.com/use_cases/order_to_cash',
            },
            {
              title: 'Employee onboarding',
              href: 'https://www.workato.com/use_cases/employee_onboarding',
            },
            {
              title: 'Embedded Integrations',
              href: 'https://www.workato.com/use_cases/productized_integrations',
            },
            {
              title: 'Enterprise iPaaS',
              href: 'https://www.workato.com/use_cases/ipaas',
            },
          ],
          view_all: {
            title: 'View all',
            href: 'https://www.workato.com/use_cases',
          },
        },
      ],
    ],
  },
  {
    title: 'Resources',
    columns: [
      [
        {
          title: 'Resources',
          links: [
            {
              title: 'Ebooks',
              href: 'https://www.workato.com/resources?content_type=Ebooks',
            },
            {
              title: 'Whitepapers',
              href: 'https://www.workato.com/resources?content_type=Whitepapers',
            },
          ],
          view_all: {
            title: 'View all',
            href: 'https://www.workato.com/resources',
          },
        },
        {
          title: 'Events',
          links: [
            {
              title: 'Live demos',
              href: 'https://discover.workato.com/workato-kitchen/p/1',
            },
            {
              title: 'Webinars',
              href: 'https://discover.workato.com/events-webinar',
            },
          ],
          view_all: {
            title: 'View all',
            href: 'https://discover.workato.com/events/p/1',
          },
        },
        {
          title: 'blog',
          links: [
            {
              title: 'The Connector',
              href: '/the-connector/',
            },
            {
              title: 'Product updates',
              href: '/product-hub/',
            },
          ],
        },
      ],
      [
        {
          title: 'User Success',
          links: [
            {
              title: 'Instructor led certification',
              href: 'https://discover.workato.com/automation-pro-training/p/1',
            },
            {
              title: 'Self service certification',
              href: 'https://academy.workato.com/',
            },
            {
              title: 'Documentation',
              href: 'https://docs.workato.com/',
            },
            {
              title: 'Support',
              href: 'https://support.workato.com/support/home',
            },
          ],
        },
        {
          title: 'Community',
          links: [
            {
              title: 'IT Community',
              href: 'https://systematic.workato.com/',
            },
            {
              title: 'RevOps Community',
              href: 'https://systematic.workato.com/category/revops/',
            },
          ],
        },
      ],
    ],
  },
  {
    title: 'Partners',
    columns: [
      [
        {
          title: 'Our Network',
          links: [
            {
              title: 'Find a Partner',
              href: 'https://directory.workato.com/consultants',
            },
            {
              title: 'Become a Partner',
              href: 'https://www.workato.com/partners',
            },
            {
              title: 'Technology Partners',
              href: 'https://www.workato.com/partners/technology_partners',
            },
            {
              title: 'System Integrators',
              href: 'https://www.workato.com/partners/system_integrators',
            },
          ],
        },
      ],
    ],
  },
];
