<div @fadeInOut [@.disabled]="isCompact" class="sidebar-submenu" role="menubar">
  <ng-container *ngFor="let item of subMenu">
    <w-sidebar-item
      *ngIf="item.type === 'link'"
      [href]="item.href"
      [iconId]="item.iconId"
      [routerUrl]="item.routerUrl"
      [mixpanel]="item.name"
      [text]="item.name"
    />

    <hr *ngIf="item.type === 'separator'" class="sidebar-submenu__separator" />
  </ng-container>
</div>
