import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

import {SharedModule} from '../../shared.module';

@Component({
  selector: 'w-widget-copyright',
  standalone: true,
  templateUrl: './widget-copyright.component.html',
  styleUrl: './widget-copyright.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule],
})
export class WidgetCopyrightComponent {}
