<button
  #button
  class="sidebar-item"
  type="button"
  [disabled]="themeCustomizationActive$ | async"
  wTooltip="You can't logout while customizing your theme"
  wLogoutButton
  wMixpanel="Header navigation: Logout"
  [wTooltipArrow]="false"
  [wTooltipDisabled]="!button.disabled"
  [wLogoutButtonUrl]="logoutUrl"
>
  <span class="sidebar-item__icon">
    <w-sidebar-icon iconId="logout" />
  </span>

  <span class="sidebar-item__name" [class.sidebar-item__name_visible]="isSidebarOpened$ | async">Logout</span>
</button>
