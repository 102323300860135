import {loadCSS} from 'fg-loadcss';
import {ApplicationRef, DoBootstrap, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY} from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {APP_BASE_HREF, DecimalPipe} from '@angular/common';
import {provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration} from '@angular/common/http';
import {CDK_DRAG_CONFIG, DragDropConfig} from '@angular/cdk/drag-drop';
import {CookieService} from 'ngx-cookie-service';
import {HotkeyModule} from 'angular2-hotkeys';

import {AbstractAuthService} from '@shared/services/abstract-auth.service';
import {GLOBAL_CONFIG_TOKEN} from '@shared/tokens/global-config.token';
import {PageSpinnerComponent} from '@shared/components/page-spinner/page-spinner.component';
import {PageSpinnerService} from '@shared/services/page-spinner.service';
import {AbstractUserSessionService} from '@shared/services/abstract-user-session.service';
import {AbstractDataTableService} from '@shared/modules/data-table/abstract-data-table.service';
import {AbstractDateFormatService} from '@shared/services/abstract-date-format.service';
import {configureMobx} from '@shared/lib/configure-mobx';

import {SidebarModule} from './modules/sidebar/sidebar.module';
import {SharedModule} from './shared.module';
import {AppRoutingModule} from './app-routing.module';
import {HeaderModule} from './modules/header/header.module';
import {DialogComponent} from './components/dialog/dialog.component';
import {FullscreenDialogComponent} from './components/dialog/fullscreen-dialog/fullscreen-dialog.component';
import {Environment} from './services/environment';
import {DevelopmentErrorHandler, ProductionErrorHandler} from './error-handlers';
import {appSettings} from './app-settings';
import {HttpInterceptorsModule} from './modules/http-interceptors/http-interceptors.module';
import {ServicePagesModule} from './pages/service-pages/service-pages.module';
import {AppVersionModule} from './modules/app-version/app-version.module';
import {PerformanceService} from './modules/performance/performance.service';
import {AppComponent} from './components/app/app.component';
import {AppVersionService} from './modules/app-version/app-version.service';
import {AppLayoutComponent} from './components/app-layout/app-layout.component';
import {FooterComponent} from './components/footer/footer.component';
import {IaHeaderModule} from './components/ia-header/ia-header.component';
import {RouterOutletComponent} from './components/router-outlet/router-outlet.component';
import {PageBannersModule} from './modules/page-banners/page-banners.module';
import {PageTopStickyAreaComponent} from './components/page-top-sticky-area/page-top-sticky-area.component';
import {PluralModule} from './pipes/plural.pipe';
import {AuthUser} from './services/auth-user';
import {ToastsModule} from './modules/toasts/toasts.component';
import {UserSessionService} from './services/user-session.service';
import {DataTableService} from './services/data-table.service';
import {DateFormatService} from './services/date-format.service';
import {WidgetCopyrightComponent} from './components/widget-copyright/widget-copyright.component';

const standaloneComponents = [
  PageSpinnerComponent,
  WidgetCopyrightComponent,
  DialogComponent,
  FullscreenDialogComponent,
];

const XSRF_HEADER_NAME = 'X-CSRF-TOKEN';
const CDK_DND_CONFIG: DragDropConfig = {
  zIndex: 9999,
};

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    TEST ? NoopAnimationsModule : BrowserAnimationsModule,
    HttpInterceptorsModule.withOptions({
      xsrfHeaderName: XSRF_HEADER_NAME,
      pingApiSettings: {url: '/ping', responseType: 'text'},
    }),
    HotkeyModule.forRoot(),
    AppRoutingModule,

    // Module with service pages e.g. page load error
    ServicePagesModule,
    // Stores and syncs Workato client/server versions
    AppVersionModule,
    HeaderModule,
    IaHeaderModule,
    SidebarModule,
    PageBannersModule,
    PluralModule,
    ToastsModule,
    ...standaloneComponents,
  ],
  declarations: [AppComponent, AppLayoutComponent, PageTopStickyAreaComponent, RouterOutletComponent, FooterComponent],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        cookieName: 'XSRF-TOKEN-V2',
        headerName: XSRF_HEADER_NAME,
      }),
    ),
    Environment,
    DecimalPipe,
    CookieService,
    {
      provide: ErrorHandler,
      useClass: PRODUCTION ? ProductionErrorHandler : DevelopmentErrorHandler,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: CDK_DRAG_CONFIG,
      useValue: CDK_DND_CONFIG,
    },
    {
      provide: AbstractAuthService,
      useExisting: AuthUser,
    },
    {
      provide: GLOBAL_CONFIG_TOKEN,
      useValue: window.Workato,
    },
    {
      provide: AbstractUserSessionService,
      useExisting: UserSessionService,
    },
    {
      provide: AbstractDataTableService,
      useExisting: DataTableService,
    },
    {
      provide: AbstractDateFormatService,
      useExisting: DateFormatService,
    },
    {
      provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
      useValue: false,
    },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private errorHandler: ErrorHandler,
    private env: Environment,
    private appVersion: AppVersionService,
    private pageSpinner: PageSpinnerService,
    private performanceService: PerformanceService,
  ) {
    this.performanceService.init();
  }

  ngDoBootstrap(appRef: ApplicationRef) {
    configureMobx(this.errorHandler);

    this.appVersion.set(appSettings.version);
    this.env.initialize();
    this.pageSpinner.removeInitialSpinner();
    this.performanceService.mark('app_init');

    appRef.bootstrap(AppComponent);

    loadCSS(window.Workato.assets.adapterIconsCss, document.getElementsByTagName('link')[0]);
  }
}
