import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'w-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDropdownComponent {
  @Input() position: 'left' | 'right' | 'center' = 'left';
  @Input() orientation: 'rows' | 'columns' = 'rows';

  @HostBinding('class') get hostClasses(): string {
    return `header-dropdown header-dropdown_${this.orientation} header-dropdown_${this.position}`;
  }
}
