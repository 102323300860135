import {Pipe, PipeTransform} from '@angular/core';
import {upperFirst} from 'lodash';

@Pipe({
  name: 'upperFirst',
})
export class UpperFirstPipe implements PipeTransform {
  transform(str: string, apply = true): string {
    return apply ? upperFirst(str) : str;
  }
}
