import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'wReverse',
})
export class ReversePipe implements PipeTransform {
  transform<TItem>(value: TItem[]): TItem[] {
    return [...value].reverse();
  }
}
