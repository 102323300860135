import numeral from 'numeral';
import {Pipe, PipeTransform} from '@angular/core';
import {isString} from 'lodash';

// setting custom abbreviations to numeral.js for current locale
Object.assign(numeral.localeData(numeral.options.currentLocale).abbreviations, {
  thousand: 'K',
  billion: 'B',
  million: 'M',
  trillion: 'T',
});

const customFormats = {
  total: (value: number) => numeral(value).format(value < 10000 ? '0,0' : '0[.]0a'),
  abbr: '0.[0]a',
  abbr2: '0.[00]a',
};

const MIN_SAFE_FRACTION = 1 / 1000000;

export function formatNumber(value: number | string | null | undefined, format = '0,0'): string {
  const customFormat = customFormats[format];

  const parsedValue = isString(value) ? parseFloat(value) : value;

  // fixes a bug https://github.com/adamwdraper/Numeral-js/issues/784
  if (parsedValue && parsedValue >= -MIN_SAFE_FRACTION && parsedValue <= MIN_SAFE_FRACTION) {
    value = 0;
  }

  if (typeof customFormat === 'function') {
    return customFormat(value);
  }

  return numeral(value).format(customFormat || format);
}

export function getAbbreviation(value: string): string {
  const abbr = value.slice(-1).toLowerCase();

  return Number.isNaN(Number(abbr)) ? abbr : '';
}

@Pipe({
  name: 'wNumeral',
  standalone: true,
})
export class NumeralPipe implements PipeTransform {
  // For formats see http://numeraljs.com/
  transform(value: number | string | null | undefined, format = '0,0'): string {
    return formatNumber(value, format);
  }
}
