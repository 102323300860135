import _ from 'lodash';
import {Injectable} from '@angular/core';

import {SchemaField, StepKeyword} from '../../types';

import {IF_CONDITIONS, KEYWORDS_CONFIG} from './data';

export interface IfCondition {
  name: string;
  title: string;
  description: string;
  hide_rhs?: boolean;
}

export interface Keyword {
  block?: boolean;
  config?: SchemaField[] | boolean;
}

export type Keywords = {
  [name in StepKeyword]: Keyword;
};

@Injectable({
  providedIn: 'root',
})
export class AppDictionary {
  private ifConditionsMap = new Map<IfCondition['name'], IfCondition>();

  constructor() {
    _.forEach(IF_CONDITIONS, condition => this.ifConditionsMap.set(condition.name, condition));
  }

  getIfCondition(name: IfCondition['name']): IfCondition | undefined {
    return this.ifConditionsMap.get(name);
  }

  getFieldNamesByKeyword(keyword: StepKeyword): string[] {
    const schema = KEYWORDS_CONFIG[keyword].config;

    return Array.isArray(schema) ? schema.map(field => field.name) : [];
  }
}
