import {ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';
import {NgStyle} from '@angular/common';

import {getAvatarBorderSize} from '../avatar-helpers';

@Component({
  selector: 'w-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input({required: true})
  @HostBinding('attr.data-user-name')
  userName: string;

  @Input({required: true}) size: number;
  @Input() imgUrl?: string;
  @Input() imgCover = false;
  @Input() squared = false;
  @Input() border = false;

  @HostBinding('class') hostClass = 'user-avatar';

  get imageStyle(): NgStyle['ngStyle'] {
    return {
      'height.px': this.size,
      'width.px': this.size,
      'border-width.px': this.border ? getAvatarBorderSize(this.size) : null,
    };
  }
}
